import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () => import('@/pages/Applications/Swish/Configurations/Container.vue')
const CreateConfiguration = () => import('@/pages/Applications/Swish/Configurations/Create.vue')
const ConfigurationList = () => import('@/pages/Applications/Swish/Configurations/List.vue')
const ShowConfiguration = () => import('@/pages/Applications/Swish/Configurations/Show.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  SWISH_CONFIGURATIONS_LIST: 'SWISH_CONFIGURATIONS_LIST',
  SWISH_CONFIGURATION_CREATE: 'SWISH_CONFIGURATION_CREATE',
  SWISH_CONFIGURATION_SHOW: 'SWISH_CONFIGURATION_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'swish/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.SWISH_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.SWISH_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.SWISH_CONFIGURATION_SHOW,
            component: ShowConfiguration,
          },
        ],
      },
    ],
  },
]

export default routes
