<template>
  <component :is="tag">
    {{ formattedValue }}
  </component>
</template>
<script lang="ts" setup>
/**
 * A wrapper for the vue-i18n component.
 * See docs: https://kazupon.github.io/vue-i18n/api/#i18n-n-functional-component
 */
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

import { useAuthStore } from '@/stores/auth'

const props = withDefaults(
  defineProps<{
    tag?: string
    value?: number | null
    locale?: string
    currency?: string
    minimumFractionDigits?: number
    maximumFractionDigits?: number
    currencyDisplay?: 'symbol' | 'code' | 'name'
  }>(),
  {
    tag: 'span',
    value: 0,
    locale: undefined,
    currency: '',
    minimumFractionDigits: undefined,
    maximumFractionDigits: undefined,
    currencyDisplay: 'symbol',
  },
)

const format = computed(() => {
  return {
    style: props.currency ? 'currency' : 'decimal',
    minimumFractionDigits: props.minimumFractionDigits,
    maximumFractionDigits: props.maximumFractionDigits,
    ...(props.currency
      ? {
          currency: props.currency,
          currencyDisplay: props.currencyDisplay,
        }
      : {}),
  }
})

const { currentNumberLocale } = storeToRefs(useAuthStore())

const formattedValue = computed(() => {
  if (props.value === null) {
    return ''
  }
  let val = props.value
  if (typeof props.value !== 'number') {
    val = parseFloat(props.value)
    if (isNaN(val)) {
      console.error('CurrencyFormat: value is not a number', props.value)
      return ''
    }
  }
  return new Intl.NumberFormat(props.locale || currentNumberLocale.value, format.value).format(val)
})
</script>
