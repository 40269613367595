import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/ISSMenuPlan/Configurations/ISSMenuPlanConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/ISSMenuPlan/Configurations/ISSMenuPlanConfigurationCreate.vue')
const EditConfiguration = () =>
  import('@/pages/Applications/ISSMenuPlan/Configurations/ISSMenuPlanConfigurationEdit.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/ISSMenuPlan/Configurations/ISSMenuPlanConfigurationsList.vue')
const EditStore = () => import('@/pages/Applications/ISSMenuPlan/Stores/ISSMenuPlanStoreEdit.vue')
const StoreList = () => import('@/pages/Applications/ISSMenuPlan/Stores/ISSMenuPlanStoreList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ISS_MENU_PLAN_CONFIGURATIONS_LIST: 'ISS_MENU_PLAN_CONFIGURATIONS_LIST',
  ISS_MENU_PLAN_CONFIGURATIONS_CREATE: 'ISS_MENU_PLAN_CONFIGURATIONS_CREATE',
  ISS_MENU_PLAN_CONFIGURATIONS_SHOW: 'ISS_MENU_PLAN_CONFIGURATIONS_SHOW',
  ISS_MENU_PLAN_CONFIGURATIONS_EDIT: 'ISS_MENU_PLAN_CONFIGURATIONS_EDIT',
  ISS_MENU_PLAN_CONFIGURATIONS_STORES: 'ISS_MENU_PLAN_CONFIGURATIONS_STORES',
  ISS_MENU_PLAN_CONFIGURATION_STORE_EDIT: 'ISS_MENU_PLAN_CONFIGURATION_STORE_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'iss-menu-plan/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_STORES }),
          },
          {
            path: 'stores',
            name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_STORES,
            component: StoreList,
            meta: { subsection: 'stores' },
          },
          {
            path: 'stores/:storeId',
            name: routeNames.ISS_MENU_PLAN_CONFIGURATION_STORE_EDIT,
            component: EditStore,
            meta: { subsection: 'stores' },
            props: true,
          },
          {
            path: 'edit',
            name: routeNames.ISS_MENU_PLAN_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            meta: { subsection: 'edit' },
          },
        ],
      },
    ],
  },
]

export default routes
