import { defineStore } from 'pinia'

import type { BaseApiResponse } from '@/interfaces/BaseApi'
import type PortalPaymentMethod from '@/interfaces/PortalPaymentMethod'

import http from '@/http'
import { i18n } from '@/i18n'

const optionForPaymentMethod = (paymentMethod: PortalPaymentMethod) => {
  const localLabel = i18n.global.t(`global.paymentMethods.${paymentMethod.id}`)
  // Hack for spotting missing translations since te doesn't work with global scope
  // https://github.com/kazupon/vue-i18n/issues/547
  const label = localLabel.includes('global.paymentMethods.') ? paymentMethod.attributes.name : localLabel
  return {
    label,
    value: paymentMethod.id,
  }
}

export const usePaymentMethodsStore = defineStore('paymentMethods', {
  state: () => ({
    paymentMethodsById: {} as Record<string, PortalPaymentMethod>,
  }),
  actions: {
    addToPaymentMethodsById(paymentMethods: PortalPaymentMethod[] | PortalPaymentMethod) {
      const newpaymentMethods = Array.isArray(paymentMethods) ? paymentMethods : [paymentMethods]
      this.paymentMethodsById = {
        ...this.paymentMethodsById,
        ...newpaymentMethods.reduce((acc: Record<string, PortalPaymentMethod>, item: PortalPaymentMethod) => {
          acc[String(item.id)] = item
          return acc
        }, {}),
      }
    },
    async getPaymentMethods() {
      const { data } = await http.get<BaseApiResponse<PortalPaymentMethod[]>>('/api/v2/payment-methods')
      const paymentMethods = data.data
      this.addToPaymentMethodsById(paymentMethods)
      return paymentMethods
    },
    nameForPaymentMethodId(paymentMethodId: string) {
      const paymentMethod = this.paymentMethodsById[paymentMethodId]
      return paymentMethod?.attributes.name
    },
  },
  getters: {
    paymentMethods: (state) => Object.values(state.paymentMethodsById),
    paymentMethodsAvailableOnTerminals: (state) =>
      Object.values(state.paymentMethodsById).filter((pm) => pm.attributes.is_available_on_terminals),
    terminalPaymentSelectOptions: (state) =>
      Object.values(state.paymentMethodsById)
        .filter((pm) => pm.attributes.is_available_on_terminals)
        .map(optionForPaymentMethod),
    allPaymentSelectOptions: (state) => Object.values(state.paymentMethodsById).map(optionForPaymentMethod),
    paymentSelectOptionsAvailableOnTerminals: (state) =>
      Object.values(state.paymentMethodsById)
        .filter((pm) => pm.attributes.is_available_on_terminals)
        .map(optionForPaymentMethod),
  },
})
