<template>
  <div
    id="layout-root"
    class="tw-pb-28"
  >
    <Navigation />
    <!-- Set left padding according to the navigation state -->
    <main
      class="lg:tw-pl-[80px]"
      :class="{ 'xl:tw-pl-[272px]': expanded }"
    >
      <UIMessageContainer />
      <RouterView v-slot="{ Component }">
        <template v-if="Component">
          <Suspense>
            <!-- main content -->
            <component :is="Component" />
            <!-- loading state -->
            <template #fallback>
              <LoadingSpinner class="tw-py-12" />
            </template>
          </Suspense>
        </template>
      </RouterView>
    </main>
    <ExtendSessionModal v-if="showExtendSessionModal" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'
import Navigation from '@/components/Layout/Navigation/index.vue'
import ExtendSessionModal from '@/components/Modals/Authentication/ExtendSessionModal.vue'
import UIMessageContainer from '@/components/UI/UIMessageContainer.vue'
import { initiateOrUpdateMavenoid } from '@/shared/mavenoidService'
import { useAuthStore } from '@/stores/auth'
import { useNavigationStore } from '@/stores/navigation'
import { usePaymentMethodsStore } from '@/stores/payment-methods'

const route = useRoute()

const { expanded } = storeToRefs(useNavigationStore())

const authStore = useAuthStore()
const { isTokenExpired, isTokenExpiringSoon, currentTime } = storeToRefs(authStore)
const { logOut, updateRedirect, initializeEcho } = authStore

watch(
  isTokenExpired,
  async (value) => {
    if (value) {
      // FIXME: Check for open modals and close them, otherwise the modal overlay will persist
      updateRedirect(route.fullPath)
      logOut(true)
    }
  },
  { immediate: true },
)

let sessionInterval: number | null = null
const showExtendSessionModal = ref<null | boolean>(false)

onMounted(async () => {
  await initiateOrUpdateMavenoid()

  // We start the currentTime interval for the session auto-logout on `onMounted` because POSLayout is only mounted after the user has logged in
  sessionInterval = window.setInterval(() => {
    currentTime.value = Date.now()
  }, 1000)

  initializeEcho()
})

onBeforeUnmount(() => {
  window.clearInterval(sessionInterval!)
})

// We use `watchEffect` here because of performance reasons. Setting the v-if on ExtendSessionModal directly to `isTokenExpiringSoon` will cause the modal to be re-rendered every second.
watchEffect(() => {
  showExtendSessionModal.value = isTokenExpiringSoon.value
})

await usePaymentMethodsStore().getPaymentMethods()
</script>
