<template>
  <div>
    <SelectWidget
      v-if="loading"
      :class="inputClass"
      :placeholder="placeholder"
      disabled
      :options="[]"
    />

    <div v-else>
      <SelectWidget
        v-if="totalResults <= 100"
        :class="inputClass"
        :has-error="hasError"
        :name="name"
        :value="value || null"
        :options="options"
        :placeholder="placeholder || $t('placeholder.select')"
        :nullable="nullable"
        :disabled="disabled"
        @update:value="onSelectUpdate"
      />

      <TypeaheadWidget
        v-else
        :input-class="inputClass"
        :placeholder="placeholder || $t('placeholder.search')"
        :endpoint="endpoint"
        :params="params"
        :path="path"
        :has-error="hasError"
        :value="value"
        :result-label-property="resultLabelProperty"
        :result-id-property="resultIdProperty"
        :disabled="disabled"
        :clearable="nullable"
        :initial-results="results"
        :excluded-result-ids="excludedResultIds"
        @update:value="$emit('update:value', $event)"
        @select="$emit('select', $event)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LuiSelectOption } from '@loomispay/loomis-ui'
import get from 'lodash/get'
import { computed, nextTick, onMounted, ref, watch } from 'vue'

import type { PortalId } from '@/types/portal'

import SelectWidget from '@/components/FormGroups/Widgets/SelectWidget.vue'
import TypeaheadWidget from '@/components/FormGroups/Widgets/TypeaheadWidget.vue'
import http from '@/http'
import { handleError } from '@/shared/errorService'

const props = withDefaults(
  defineProps<{
    name?: string
    placeholder?: string
    endpoint: string
    params?: Record<string, unknown>
    path?: string
    resultLabelProperty?: string | Function
    resultIdProperty?: string | Function
    value?: number | string | null
    inputClass?: string
    disabled?: boolean
    nullable?: boolean
    hasError?: boolean
    autoSelect?: boolean
    excludedResultIds?: PortalId[]
  }>(),
  {
    name: undefined,
    placeholder: undefined,
    params: () => ({}),
    inputClass: '',
    disabled: false,
    nullable: false,
    hasError: false,
    resultIdProperty: 'id',
    resultLabelProperty: 'name',
    autoSelect: false,
    value: '',
    path: 'data.data',
    excludedResultIds: () => [],
  },
)

const emit = defineEmits<{
  'update:value': [value: number | string | null]
  'select': [value: any]
}>()

const loading = ref(true)
const results = ref<any[]>([])
const totalResults = ref(0)

const options = computed<LuiSelectOption[]>(() =>
  results.value
    .map((item: any) => ({
      value: getResultId(item),
      label: getResultLabel(item),
    }))
    .filter((option) => !props.excludedResultIds.includes(option.value)),
)

const onSelectUpdate = (event: string | number | null) => {
  emit('update:value', event)
  emit(
    'select',
    results.value.find((result: any) => `${getResultId(result)}` === event),
  )
}

const getResultId = (result: any) =>
  typeof props.resultIdProperty === 'function' ? props.resultIdProperty(result) : get(result, props.resultIdProperty)

const getResultLabel = (result: any) =>
  typeof props.resultLabelProperty === 'function'
    ? props.resultLabelProperty(result)
    : get(result, props.resultLabelProperty)

const load = async () => {
  loading.value = true

  try {
    const response = await http.get(props.endpoint, {
      page_size: 100,
      ...props.params,
    })
    totalResults.value = get(response, 'data.meta.total', 0)
    results.value = get(response, props.path, [])
  } catch (error) {
    handleError(error)
  } finally {
    loading.value = false
  }
}

watch(
  () => props.params,
  (oldValue, newValue) => {
    if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
      load()

      emit('update:value', null)
    }
  },
  { deep: true },
)

onMounted(async () => {
  await nextTick() // Fixes race condition when changing merchant id directly in the route
  await load()

  if (props.autoSelect && !props.value && options.value.length) {
    onSelectUpdate(options.value[0].value)
  }
})
</script>
