import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Printing/Configurations/PrintingConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Printing/Configurations/PrintingConfigurationCreate.vue')
const EditConfiguration = () => import('@/pages/Applications/Printing/Configurations/PrintingConfigurationEdit.vue')
const ConfigurationList = () => import('@/pages/Applications/Printing/Configurations/PrintingConfigurationsList.vue')
const PrinterContainer = () => import('@/pages/Applications/Printing/Printers/PrintingPrinterContainer.vue')
const EditPrinter = () => import('@/pages/Applications/Printing/Printers/PrintingPrinterEdit.vue')
const PrinterList = () => import('@/pages/Applications/Printing/Printers/PrintingPrintersList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  PRINTING_CONFIGURATIONS_LIST: 'PRINTING_CONFIGURATIONS_LIST',
  PRINTING_CONFIGURATION_CREATE: 'PRINTING_CONFIGURATION_CREATE',
  PRINTING_CONFIGURATIONS_SHOW: 'PRINTING_CONFIGURATIONS_SHOW',
  PRINTING_CONFIGURATIONS_EDIT: 'PRINTING_CONFIGURATIONS_EDIT',
  PRINTING_CONFIGURATIONS_PRINTERS: 'PRINTING_CONFIGURATIONS_PRINTERS',
  PRINTING_CONFIGURATIONS_PRINTERS_EDIT: 'PRINTING_CONFIGURATIONS_PRINTERS_EDIT',
  PRINTING_CONFIGURATIONS_PRINTER_SHOW: 'PRINTING_CONFIGURATIONS_PRINTER_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'printing/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.PRINTING_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.PRINTING_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.PRINTING_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.PRINTING_CONFIGURATIONS_PRINTERS }),
          },
          {
            path: 'printers',
            name: routeNames.PRINTING_CONFIGURATIONS_PRINTERS,
            component: PrinterList,
            meta: { subsection: 'printers' },
          },
          {
            path: 'edit',
            name: routeNames.PRINTING_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            meta: { subsection: 'edit' },
          },
          {
            path: 'printers/:printerId',
            component: PrinterContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.PRINTING_CONFIGURATIONS_PRINTER_SHOW,
                redirect: () => ({ name: routeNames.PRINTING_CONFIGURATIONS_PRINTERS_EDIT }),
              },
              {
                path: 'edit',
                name: routeNames.PRINTING_CONFIGURATIONS_PRINTERS_EDIT,
                component: EditPrinter,
                meta: { subsection: 'printers' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
