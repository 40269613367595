import { defineStore } from 'pinia'
import { ref } from 'vue'

import type { ConfirmModalContext } from '@/components/Modals/ConfirmModal.vue'

export const useConfirmModalStore = defineStore('confirm-modal-store', () => {
  const confirmModalContext = ref<null | ConfirmModalContext>(null)

  const showConfirmModal = (inputContext: ConfirmModalContext) => {
    confirmModalContext.value = inputContext
  }

  const closeConfirmModal = () => {
    confirmModalContext.value = null
  }

  return {
    confirmModalContext,
    showConfirmModal,
    closeConfirmModal,
  }
})
