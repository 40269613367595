import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  SYSTEM_CLIENTS_LIST: 'SYSTEM_CLIENTS_LIST',
  SYSTEM_CLIENT_CREATE: 'SYSTEM_CLIENT_CREATE',
  SYSTEM_CLIENT_EDIT: 'SYSTEM_CLIENT_EDIT',
  SYSTEM_CLIENT_EDIT_REDIRECT: 'SYSTEM_CLIENT_EDIT_REDIRECT',

  SYSTEM_NOTICES_LIST: 'SYSTEM_NOTICES_LIST',
  SYSTEM_NOTICE_CREATE: 'SYSTEM_NOTICE_CREATE',
  SYSTEM_NOTICE_EDIT: 'SYSTEM_NOTICE_EDIT',
  SYSTEM_NOTICE_EDIT_REDIRECT: 'SYSTEM_NOTICE_EDIT_REDIRECT',

  SYSTEM_ROLES_LIST: 'SYSTEM_ROLES_LIST',
  SYSTEM_ROLE_CREATE: 'SYSTEM_ROLE_CREATE',
  SYSTEM_ROLE_EDIT: 'SYSTEM_ROLE_EDIT',

  SYSTEM_TERMINAL_TYPES_LIST: 'SYSTEM_TERMINAL_TYPES_LIST',
  SYSTEM_TERMINAL_TYPE_CREATE: 'SYSTEM_TERMINAL_TYPE_CREATE',
  SYSTEM_TERMINAL_TYPE_EDIT: 'SYSTEM_TERMINAL_TYPE_EDIT',
  SYSTEM_TERMINAL_TYPE_EDIT_REDIRECT: 'SYSTEM_TERMINAL_TYPE_EDIT_REDIRECT',

  SYSTEM_SKATTEVERKET_REGISTERED_TERMINALS: 'SYSTEM_SKATTEVERKET_REGISTERED_TERMINALS',

  SYSTEM_LOG_LIST: 'SYSTEM_LOG_LIST',

  SYSTEM_ONBOARDING_PROFILES_LIST: 'SYSTEM_ONBOARDING_PROFILES_LIST',
  SYSTEM_ONBOARDING_PROFILE_CREATE: 'SYSTEM_ONBOARDING_PROFILE_CREATE',
  SYSTEM_ONBOARDING_PROFILE_EDIT: 'SYSTEM_ONBOARDING_PROFILE_EDIT',
  SYSTEM_ONBOARDING_PROFILE_EDIT_REDIRECT: 'SYSTEM_ONBOARDING_PROFILE_EDIT_REDIRECT',

  MERCHANTS_LIST: 'MERCHANTS_LIST',
  MERCHANTS_CREATE_MERCHANT: 'MERCHANTS_CREATE_MERCHANT',
  MERCHANTS_EDIT_MERCHANT: 'MERCHANTS_EDIT_MERCHANT',

  SYSTEM_TERMINAL_EVENTS: 'SYSTEM_TERMINAL_EVENTS',

  USERS_LIST: 'USERS_LIST',
  USER_CREATE: 'USER_CREATE',
  USER_EDIT: 'USER_EDIT',
  USER_EDIT_REDIRECT: 'USER_EDIT_REDIRECT',
} as const

const routes: RouteRecordRaw[] = [
  {
    path: 'clients',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_CLIENTS_LIST,
        component: () => import('@/pages/Authentication/Clients/List.vue'),
      },
      {
        path: 'create',
        name: routeNames.SYSTEM_CLIENT_CREATE,
        component: () => import('@/pages/Authentication/Clients/Create.vue'),
      },
      {
        path: ':clientId',
        name: routeNames.SYSTEM_CLIENT_EDIT,
        component: () => import('@/pages/Authentication/Clients/Edit.vue'),
        props: true,
      },
      {
        path: ':clientId/edit',
        name: routeNames.SYSTEM_CLIENT_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.SYSTEM_CLIENT_EDIT }),
      },
    ],
  },
  {
    path: 'notices',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_NOTICES_LIST,
        component: () => import('@/pages/Authentication/Notices/NoticesList.vue'),
      },
      {
        path: 'create',
        name: routeNames.SYSTEM_NOTICE_CREATE,
        component: () => import('@/pages/Authentication/Notices/NoticeCreate.vue'),
      },
      {
        path: ':noticeId',
        name: routeNames.SYSTEM_NOTICE_EDIT,
        component: () => import('@/pages/Authentication/Notices/NoticeEdit.vue'),
        props: true,
      },
      {
        path: ':noticeId/edit',
        name: routeNames.SYSTEM_NOTICE_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.SYSTEM_NOTICE_EDIT }),
      },
    ],
  },
  {
    path: 'roles',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_ROLES_LIST,
        component: () => import('@/pages/Authentication/Roles/RolesList.vue'),
      },
      {
        path: 'create',
        name: routeNames.SYSTEM_ROLE_CREATE,
        component: () => import('@/pages/Authentication/Roles/RolesCreate.vue'),
      },
      {
        path: ':roleId/edit',
        name: routeNames.SYSTEM_ROLE_EDIT,
        component: () => import('@/pages/Authentication/Roles/RolesEdit.vue'),
        props: true,
      },
    ],
  },
  {
    path: 'skatteverket',
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    name: routeNames.SYSTEM_SKATTEVERKET_REGISTERED_TERMINALS,
    component: () => import('@/pages/System/RegisteredTerminals.vue'),
  },

  {
    path: 'terminal-types',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_TERMINAL_TYPES_LIST,
        component: () => import('@/pages/Stores/TerminalTypes/List.vue'),
      },
      {
        path: 'create',
        name: routeNames.SYSTEM_TERMINAL_TYPE_CREATE,
        component: () => import('@/pages/Stores/TerminalTypes/CreateTerminalType.vue'),
      },
      {
        path: ':terminalTypeId',
        name: routeNames.SYSTEM_TERMINAL_TYPE_EDIT,
        component: () => import('@/pages/Stores/TerminalTypes/EditTerminalType.vue'),
        props: true,
      },
      {
        path: ':terminalTypeId/edit',
        name: routeNames.SYSTEM_TERMINAL_TYPE_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.SYSTEM_TERMINAL_TYPE_EDIT }),
      },
    ],
  },

  {
    path: 'log',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_LOG_LIST,
        component: () => import('@/pages/Administration/Log/LogList.vue'),
      },
    ],
  },
  {
    path: 'onboarding-profiles',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        name: routeNames.SYSTEM_ONBOARDING_PROFILES_LIST,
        component: () => import('@/pages/System/OnboardingProfiles/OnboardingProfilesList.vue'),
      },
      {
        path: 'create',
        name: routeNames.SYSTEM_ONBOARDING_PROFILE_CREATE,
        component: () => import('@/pages/System/OnboardingProfiles/OnboardingProfilesCreate.vue'),
      },
      {
        path: ':onboardingProfileId',
        name: routeNames.SYSTEM_ONBOARDING_PROFILE_EDIT,
        component: () => import('@/pages/System/OnboardingProfiles/OnboardingProfilesEdit.vue'),
        props: true,
      },
      {
        path: ':onboardingProfileId/edit',
        name: routeNames.SYSTEM_ONBOARDING_PROFILE_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.SYSTEM_ONBOARDING_PROFILE_EDIT }),
      },
    ],
  },
  {
    path: 'pos-logs',
    name: routeNames.SYSTEM_TERMINAL_EVENTS,
    component: () => import('@/pages/System/TerminalEvents.vue'),
  },

  {
    path: 'users',
    component: Blank,
    meta: {
      section: Section.ADMIN,
    },
    children: [
      {
        path: '',
        name: routeNames.USERS_LIST,
        component: () => import('@/pages/Authentication/Users/UsersList.vue'),
      },
      {
        path: 'create',
        name: routeNames.USER_CREATE,
        component: () => import('@/pages/Authentication/Users/UsersCreate.vue'),
      },
      {
        path: ':userId',
        name: routeNames.USER_EDIT,
        component: () => import('@/pages/Authentication/Users/UsersEdit.vue'),
        props: true,
      },
      {
        path: ':userId/edit',
        name: routeNames.USER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.USER_EDIT }),
      },
    ],
  },
]

export default routes
