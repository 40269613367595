import { onMounted } from 'vue'

export const useBooleanFormGroup = (value: boolean, emit: (event: 'update:value', value: boolean) => void) => {
  onMounted(() => {
    /* A lot of the legacy forms has by default and undefined value, which results in a lot of unnecessary
     * and not very user friendly 422/input validation errors.
     * So to make sure they have a boolean value, we emit it whenever we mount a toggle
     */
    emit('update:value', !!value)
  })
}
