import { defineStore } from 'pinia'

import type {
  PreparationConfiguration,
  PreparationCourse,
  PreparationCourseAttributes,
  PreparationLocation,
  PreparationLocationAttributes,
  PreparationStation,
  PreparationStationAttributes,
} from '@/interfaces/Applications/Preparation/PreparationTypes'

import type { PortalId } from '@/types/portal'
import type { PreparationStationCreateAttributes } from '@/types/preparation'

import {
  createCourse,
  createLocation,
  createStation,
  deleteCourse,
  deleteStation,
  fetchConfigurations,
  fetchCourse,
  fetchCourses,
  fetchLocation,
  fetchLocationsByConfigurationId,
  fetchStation,
  fetchStations,
  sortCourses,
  updateCourse,
  updateLocation,
  updateStation,
} from '@/api/applications/preparation'
import notify from '@/notify'

export const usePreparationStore = defineStore('preparation', {
  state: () => ({
    configurations: [] as PreparationConfiguration[],
    locationsById: null as Record<string, PreparationLocation> | null,
    sortedCourseIds: [] as PortalId[],
    coursesById: null as Record<string, PreparationCourse> | null,
    stationsById: null as Record<string, PreparationStation> | null,
  }),
  actions: {
    /**
     * CONFIGURATIONS
     */
    async fetchConfigurations(merchantId: PortalId) {
      try {
        const response = await fetchConfigurations(merchantId)
        this.configurations = response.data.data
        return this.configurations
      } catch (error: any) {
        if (error.response?.status !== 401) {
          notify.error('There was an error fetching the configurations. Please try again later.')
          throw error
        }
      }
    },
    /**
     * COURSES
     */
    addToCoursesById(courses: PreparationCourse[]) {
      this.coursesById = {
        ...this.coursesById,
        ...courses.reduce((acc: Record<string, PreparationCourse>, course) => {
          acc[`${course.id}`] = course
          return acc
        }, {}),
      }
    },
    async createCourse(attributes: PreparationCourseAttributes) {
      const response = await createCourse(attributes)
      const course = response.data.data
      this.addToCoursesById([course])
      this.sortedCourseIds.push(course.id)
      return course
    },
    async updateCourse(courseId: PortalId, fieldsToUpdate: Partial<PreparationCourseAttributes>) {
      const response = await updateCourse(courseId, fieldsToUpdate)
      const course = response.data.data
      this.addToCoursesById([course])
      return course
    },
    async deleteCourse(courseId: PortalId) {
      await deleteCourse(courseId)
      delete this.coursesById?.[`${courseId}`]
      this.sortedCourseIds = this.sortedCourseIds.filter((id) => String(id) !== String(courseId))
    },
    async fetchCourses(locationId: PortalId) {
      const response = await fetchCourses(locationId)
      const courses = response.data.data
      this.addToCoursesById(courses)
      this.sortedCourseIds = courses.map((course) => course.id)
      return courses
    },
    async fetchCourse(courseId: PortalId): Promise<PreparationCourse> {
      if (this.coursesById?.[courseId]) {
        return this.coursesById[courseId]
      }
      const response = await fetchCourse(courseId)
      this.addToCoursesById([response.data.data])
      return response.data.data
    },
    async sortCourses(courses: PreparationCourse[]) {
      await sortCourses(courses)
    },
    /**
     * LOCATIONS
     */
    addToLocationsById(locations: PreparationLocation[]) {
      this.locationsById = {
        ...this.locationsById,
        ...locations.reduce(
          (acc, location) => {
            acc[location.id] = location
            return acc
          },
          {} as Record<string, PreparationLocation>,
        ),
      }
    },
    async fetchLocations(configurationId: PortalId) {
      const response = await fetchLocationsByConfigurationId(configurationId)
      const locations = response.data.data
      this.addToLocationsById(locations)
      return locations
    },
    async fetchLocation(locationId: PortalId) {
      if (this.locationsById?.[locationId]) {
        return this.locationsById[locationId]
      }
      const response = await fetchLocation(locationId)
      const location = response.data.data
      this.addToLocationsById([location])
      return location
    },
    async createLocation(payload: { storeId: PortalId; configurationId: PortalId }) {
      const response = await createLocation(payload)
      const location = response.data.data
      this.addToLocationsById([location])
      return location
    },
    async updateLocation(locationId: PortalId, fieldsToUpdate: Partial<PreparationLocationAttributes>) {
      const { data } = await updateLocation(locationId, fieldsToUpdate)
      const location = data.data
      this.addToLocationsById([location])
      return location
    },

    /**
     * STATIONS
     */
    addToStationsById(stations: PreparationStation[]) {
      this.stationsById = {
        ...this.stationsById,
        ...stations.reduce(
          (acc, station) => {
            acc[station.id] = station
            return acc
          },
          {} as Record<string, PreparationStation>,
        ),
      }
    },
    async fetchStations(locationId: PortalId) {
      const response = await fetchStations(locationId)
      const stations = response.data.data
      this.addToStationsById(stations)
      return stations
    },
    async createStation(attributes: PreparationStationCreateAttributes) {
      const response = await createStation(attributes)
      const station = response.data.data
      this.addToStationsById([station])
      return station
    },
    async fetchStation(stationId: PortalId) {
      if (this.stationsById?.[stationId]) {
        return this.stationsById[stationId]
      }
      const response = await fetchStation(stationId)
      const station = response.data.data
      this.addToStationsById([station])
      return station
    },
    async updateStation(stationId: PortalId, fieldsToUpdate: Partial<PreparationStationAttributes>) {
      const response = await updateStation(stationId, fieldsToUpdate)
      const updatedStation = response.data.data
      this.addToStationsById([updatedStation])
      return updatedStation
    },
    async deleteStation(stationId: PortalId) {
      await deleteStation(stationId)
      delete this.stationsById?.[stationId]
    },
  },
  getters: {
    locations(state) {
      return Object.values(state.locationsById || {})
    },
    stations(state) {
      return Object.values(state.stationsById || {})
    },
    courses(state) {
      return Object.values(state.coursesById || {})
    },
    configurationForMerchant(state): (merchantId: PortalId) => PreparationConfiguration | undefined {
      return (merchantId) =>
        state.configurations.find(
          (configuration) => String(configuration.attributes.merchant_id) === String(merchantId),
        )
    },
    locationForStore(): (storeId: PortalId) => PreparationLocation | undefined {
      return (storeId) => this.locations.find((location) => String(location.attributes.store_id) === String(storeId))
    },
    stationsForLocation(): (locationId: PortalId) => PreparationStation[] {
      return (locationId) =>
        this.stations.filter((station) => String(station.attributes.location_id) === String(locationId))
    },
    coursesForLocation(): (locationId: PortalId) => PreparationCourse[] {
      return (locationId) =>
        this.courses.filter((course) => String(course.attributes.location_id) === String(locationId))
    },
    productsUsedInCourses(): {
      id: PortalId
    }[] {
      // Takes the products from all courses and returns a set of unique product ids
      return this.courses.flatMap((course) => course.attributes.products)
    },
  },
})
