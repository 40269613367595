import axios from 'axios'
import Echo from 'laravel-echo'
// pusherJs required for Laravel Echo
// eslint-disable-next-line
import pusherJs from 'pusher-js'

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios

axios.defaults.baseURL = import.meta.env.VITE_API_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
