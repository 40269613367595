<template>
  <div>
    <LuiInput
      :model-value="value"
      type="text"
      :has-error="hasError"
      :disabled="disabled"
      :suffix-icon="loading ? 'more-horizontal' : result ? 'check-circled' : 'close-circled'"
      @update:model-value="$emit('update:value', $event)"
    />
  </div>
</template>

<script setup lang="ts">
import { LuiInput } from '@loomispay/loomis-ui'
import { onMounted, ref, watch } from 'vue'

import http from '@/http'

const props = withDefaults(
  defineProps<{
    value?: string
    disabled?: boolean
    hasError?: boolean
  }>(),
  {
    value: '',
  },
)

defineEmits<{
  'update:value': [string]
}>()

const result = ref<string | null>(null)
const loading = ref(false)
const timerId = ref<number | undefined>(undefined)

watch(
  () => props.value,
  () => {
    window.clearTimeout(timerId.value)

    timerId.value = window.setTimeout(() => load(), 1000)
  },
)

onMounted(() => load())

const load = async () => {
  loading.value = true

  try {
    const response = await http.post('/api/v2/date-calculations', { input: props.value })
    result.value = response.data.data.attributes.result
  } catch (error) {
    result.value = null
  } finally {
    loading.value = false
  }
}
</script>
