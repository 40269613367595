<template>
  <MultiselectWidget
    :model-value="multiselectValue"
    :class="{ 'multiselect--has-error': hasError }"
    :options="options"
    :value="value"
    :loading="loading"
    track-by="optionValue"
    label="label"
    :placeholder="placeholder || $t('global.search')"
    :internal-search="false"
    :preserve-search="true"
    :show-no-options="true"
    :show-no-results="true"
    :disabled="disabled"
    :multiple="false"
    @update:model-value="onUpdate"
    @search-change="handleSearchChange"
  >
    <!-- Replace the caret with a clear button -->
    <template #caret>
      <div
        v-if="value && clearable"
        class="tw-flex tw-items-center tw-order-2 -tw-z-1"
      >
        <LuiButton
          type="button"
          emphasis="low"
          size="small"
          icon="close"
          :icon-size="14"
          class="tw-text-gray-9 tw-mr-2"
          :aria-label="$t('global.clear')"
          @click="$emit('update:value', null)"
        />
      </div>
      <div v-else></div>
    </template>
    <template #noOptions>
      <div>{{ $t('typeahead.noOptionsLabel') }}</div>
    </template>
    <template #noResult>
      <div v-if="searchValue.length >= 2 && !loading">{{ $t('global.noResults') }}</div>
      <div v-else-if="loading">{{ $t('global.loading') }}</div>
      <div v-else>{{ $t('typeahead.noOptionsLabel') }}</div>
    </template>
    <template #beforeList>
      <div
        v-if="!results.length && loading"
        class="tw-p-2"
      >
        {{ $t('global.loading') }}
      </div>
    </template>
  </MultiselectWidget>
</template>

<script setup lang="ts">
import { LuiButton } from '@loomispay/loomis-ui'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { computed, ref, toRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import type { PortalId } from '@/types/portal'

import MultiselectWidget from '@/components/FormGroups/Widgets/MultiSelectWidget.vue'
import http from '@/http'

const props = withDefaults(
  defineProps<{
    placeholder?: string
    endpoint: string
    params?: Record<string, unknown>
    path?: string
    resultLabelProperty?: string | Function
    resultIdProperty?: PortalId | Function
    value?: number | string | null
    inputClass?: string
    disabled?: boolean
    hasError?: boolean
    clearable?: boolean
    initialResults?: any[]
    excludedResultIds?: PortalId[]
  }>(),
  {
    placeholder: undefined,
    params: () => ({}),
    inputClass: '',
    disabled: false,
    hasError: false,
    clearable: false,
    resultIdProperty: () => 'id',
    resultLabelProperty: 'name',
    value: null,
    path: 'data.data',
    initialResults: () => [],
    excludedResultIds: () => [],
  },
)

const emit = defineEmits<{
  'update:value': [any]
  'select': [any]
}>()

const { t } = useI18n()

type MultiSelectValue = {
  label: string
  optionValue: number | string
}

const multiselectValue = ref<MultiSelectValue | null>(null)

const initialResults = toRef(props, 'initialResults')

const results = ref<any[]>(initialResults.value)
const loading = ref(false)
const searchValue = ref('')

const onUpdate = (option: MultiSelectValue | null) => {
  if (!option) {
    emit('update:value', null)
    emit('select', null)
    return
  }

  const selectedProductData = results.value.find((el) => el.id === option.optionValue)
  emit('update:value', option.optionValue)
  emit('select', selectedProductData)
}

const resultLabel = (result: unknown) => {
  return typeof props.resultLabelProperty === 'function'
    ? props.resultLabelProperty(result)
    : get(result, props.resultLabelProperty)
}

const resultId = (result: unknown) => {
  return typeof props.resultIdProperty === 'function'
    ? props.resultIdProperty(result)
    : get(result, props.resultIdProperty)
}

const options = computed(() => {
  return results.value
    .map((result: unknown) => ({
      label: resultLabel(result),
      optionValue: resultId(result),
    }))
    .filter((option) => !props.excludedResultIds.includes(option.optionValue))
})

const handleSearchChange = (val: string) => {
  searchValue.value = val
  if (val.length < 2) {
    return
  }
  loading.value = true
  search(val)
}

const search = debounce(async (val: string) => {
  const params = {
    ...(props.params || {}),
  }
  if (val) {
    params['filter[search]'] = val
    // Not all api endpoints support the filter[search]` param, so we add a `search` param too
    params['search'] = val
  }
  try {
    const response = await http.get(props.endpoint, params)
    results.value = get(response, props.path)
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}, 400)

const updateValue = async (value: number | string | null | undefined) => {
  if (!value) {
    multiselectValue.value = null
    return
  }
  if (multiselectValue.value?.optionValue === value) {
    return
  }
  const existingOption = options.value?.find((option: MultiSelectValue) => option.optionValue === value)
  if (existingOption) {
    multiselectValue.value = existingOption
  } else {
    multiselectValue.value = {
      label: t('global.loading'),
      optionValue: value,
    }
    // get the label from the server
    try {
      loading.value = true
      const { data } = await http.get(props.endpoint + '/' + value)
      const label = resultLabel(data.data)
      multiselectValue.value = {
        label,
        optionValue: value,
      }
    } catch (e) {
      // The endpoint might not support getting a single item by id, so we just set the label to the value if we can't get the label from the server
      multiselectValue.value = {
        label: String(value),
        optionValue: value,
      }
    } finally {
      loading.value = false
    }
  }
}

watch(
  () => props.value,
  async (value) => {
    await updateValue(value)
  },
  { immediate: true },
)
</script>
