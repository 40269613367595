import type { RouteRecordRaw } from 'vue-router'

const AccountGroupInvoiceScheduleList = () =>
  import('@/pages/Applications/Economic/AccountGroupInvoiceSchedules/List.vue')
const AccountGroupMappingList = () => import('@/pages/Applications/Economic/AccountGroupMappings/List.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/Economic/Configurations/EconomicConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Economic/Configurations/EconomicCreateConfiguration.vue')
const EditConfiguration = () => import('@/pages/Applications/Economic/Configurations/Edit.vue')
const ConfigurationList = () => import('@/pages/Applications/Economic/Configurations/EconomicConfigurationsList.vue')
const ReAuthorizeConfiguration = () => import('@/pages/Applications/Economic/Configurations/ReAuthorize.vue')
const ModifierMappingList = () => import('@/pages/Applications/Economic/ModifierMappings/List.vue')
const ProductMappingList = () => import('@/pages/Applications/Economic/ProductMappings/List.vue')
const SourceMappingList = () => import('@/pages/Applications/Economic/SourceMappings/List.vue')
const StoreMappingList = () => import('@/pages/Applications/Economic/StoreMappings/List.vue')
const EditTransfer = () => import('@/pages/Applications/Economic/Transfers/Edit.vue')
const TransferList = () => import('@/pages/Applications/Economic/Transfers/EconomicTransfersList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ECONOMIC_CONFIGURATIONS_LIST: 'ECONOMIC_CONFIGURATIONS_LIST',
  ECONOMIC_CONFIGURATIONS_TRANSFERS: 'ECONOMIC_CONFIGURATIONS_TRANSFERS',
  ECONOMIC_CONFIGURATIONS_TRANSFER_EDIT: 'ECONOMIC_CONFIGURATIONS_TRANSFER_EDIT',
  ECONOMIC_CONFIGURATIONS_CREATE: 'ECONOMIC_CONFIGURATIONS_CREATE',
  ECONOMIC_CONFIGURATIONS_RE_AUTHORIZE: 'ECONOMIC_CONFIGURATIONS_RE_AUTHORIZE',
  ECONOMIC_CONFIGURATIONS_SHOW: 'ECONOMIC_CONFIGURATIONS_SHOW',
  ECONOMIC_SOURCE_MAPPING_LIST: 'ECONOMIC_SOURCE_MAPPING_LIST',
  ECONOMIC_PRODUCT_MAPPING_LIST: 'ECONOMIC_PRODUCT_MAPPING_LIST',
  ECONOMIC_MODIFIER_MAPPING_LIST: 'ECONOMIC_MODIFIER_MAPPING_LIST',
  ECONOMIC_STORE_MAPPING_LIST: 'ECONOMIC_STORE_MAPPING_LIST',
  ECONOMIC_ACCOUNT_GROUP_MAPPING_LIST: 'ECONOMIC_ACCOUNT_GROUP_MAPPING_LIST',
  ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULE_LIST: 'ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULE_LIST',
  ECONOMIC_CONFIGURATIONS_EDIT: 'ECONOMIC_CONFIGURATIONS_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'economic/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.ECONOMIC_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.ECONOMIC_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId/re-authorize',
        name: routeNames.ECONOMIC_CONFIGURATIONS_RE_AUTHORIZE,
        component: ReAuthorizeConfiguration,
        props: true,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.ECONOMIC_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.ECONOMIC_CONFIGURATIONS_TRANSFERS }),
          },
          {
            path: 'source-mappings',
            name: routeNames.ECONOMIC_SOURCE_MAPPING_LIST,
            component: SourceMappingList,
            meta: { subsection: 'journals' },
          },
          {
            path: 'product-mappings',
            name: routeNames.ECONOMIC_PRODUCT_MAPPING_LIST,
            component: ProductMappingList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'modifier-mappings',
            name: routeNames.ECONOMIC_MODIFIER_MAPPING_LIST,
            component: ModifierMappingList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'store-mappings',
            name: routeNames.ECONOMIC_STORE_MAPPING_LIST,
            component: StoreMappingList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'account-group-mappings',
            name: routeNames.ECONOMIC_ACCOUNT_GROUP_MAPPING_LIST,
            component: AccountGroupMappingList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'account-group-invoice-schedules',
            name: routeNames.ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULE_LIST,
            component: AccountGroupInvoiceScheduleList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'transfers',
            name: routeNames.ECONOMIC_CONFIGURATIONS_TRANSFERS,
            component: TransferList,
            meta: { subsection: 'transfers' },
          },
          {
            path: 'transfers/:transferId/edit',
            name: routeNames.ECONOMIC_CONFIGURATIONS_TRANSFER_EDIT,
            component: EditTransfer,
            meta: { subsection: 'transfers' },
            props: true,
          },
          {
            path: 'edit',
            name: routeNames.ECONOMIC_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            meta: { subsection: 'edit' },
          },
        ],
      },
    ],
  },
]

export default routes
