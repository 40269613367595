import { createRouter, createWebHistory } from 'vue-router'

import Layout from '@/components/Layout/POSLayout.vue'
import administrationRoutes from '@/router/administration-routes'
import applicationRoutes from '@/router/application-routes'
import cashRoutes from '@/router/cash-routes'
import financesRoutes from '@/router/finances-routes'
import merchantsRoutes from '@/router/merchants-routes'
import authMiddleware from '@/router/middleware/AuthMiddleware'
import productAnalyticsPageViewMiddleware from '@/router/middleware/ProductAnalyticsPageViewMiddleware'
import OAuthCallbackRoutes from '@/router/oauth-callbacks-routes'
import packagesRoutes from '@/router/packages-routes'
import productsRoutes from '@/router/products-routes'
import reportsRoutes from '@/router/reports-routes'
import reservationsRoutes from '@/router/reservations-routes'
import salesChannelsRoutes from '@/router/sales-channels-routes'
import { Section } from '@/router/shared/constants'
import systemRoutes from '@/router/system-routes'
import tablesRoutes from '@/router/tables-routes'
import { RouteNames } from '@/shared/routes'
import { useAuthStore } from '@/stores/auth'

import { localeMiddleware } from './middleware/LocaleMiddleware'

const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      path: '/',
      name: RouteNames.HOME,
      redirect: () => {
        const authStore = useAuthStore()
        if (!authStore.user) {
          return { name: RouteNames.LOGIN }
        }
        return { name: RouteNames.DASHBOARD }
      },
    },
    {
      path: '/login',
      name: RouteNames.LOGIN,
      component: () => import('@/pages/Login/LoginPage.vue'),
      meta: { auth: false },
    },
    {
      path: '/login/update-details',
      name: RouteNames.LOGIN_UPDATE_DETAILS,
      component: () => import('@/pages/Login/UpdateDetailsPage.vue'),
      meta: { auth: false },
      beforeEnter: (to, from, next) => {
        const authStore = useAuthStore()
        if (!authStore.user) {
          next({ name: RouteNames.LOGIN })
        } else {
          next()
        }
      },
    },
    {
      path: '/passwords/reset',
      component: () => import('@/pages/Login/PasswordEmail.vue'),
      meta: { auth: false },
    },
    {
      path: '/passwords/reset/:token',
      props: true,
      component: () => import('@/pages/Login/ResetPassword.vue'),
      meta: { auth: false },
    },
    {
      path: '/merchant-selection',
      meta: {
        auth: true,
        section: Section.USER,
        global: true,
      },
      component: Layout,
      children: [
        {
          path: '',
          name: RouteNames.SELECT_MERCHANT,
          component: () => import('@/pages/Authentication/SelectMerchant.vue'),
        },
      ],
    },
    {
      path: '/:selectedMerchantId?',
      props: true,
      component: Layout,
      meta: { auth: true },
      children: [
        {
          path: '',
          redirect: () => ({ name: RouteNames.DASHBOARD }),
        },
        {
          path: 'dashboard',
          name: RouteNames.DASHBOARD,
          component: () => import('@/pages/Dashboard/DashboardPage.vue'),
          meta: {
            section: Section.DASHBOARD,
          },
        },
        ...administrationRoutes,
        ...cashRoutes,
        ...financesRoutes,
        ...merchantsRoutes,
        ...OAuthCallbackRoutes,
        ...packagesRoutes,
        ...productsRoutes,
        ...reservationsRoutes,
        ...reportsRoutes,
        ...systemRoutes,
        ...tablesRoutes,
        ...applicationRoutes,
        ...salesChannelsRoutes,

        {
          path: 'settings',
          name: RouteNames.SETTINGS,
          component: () => import('@/pages/Authentication/ProfileSettings.vue'),
          meta: {
            auth: true,
            section: Section.ACCOUNT_SETTINGS,
            global: true,
          },
        },
        {
          path: 'error',
          name: RouteNames.GENERIC_ERROR,
          component: () => import('@/pages/GenericError.vue'),
          meta: {
            auth: true,
            global: true,
          },
        },
        {
          path: ':pathMatch(.*)*', // 404
          name: RouteNames.NOT_FOUND,
          component: () => import('@/pages/NotFound.vue'),
          meta: {
            auth: true,
            global: true,
          },
        },
      ],
    },
  ],
})

router.beforeEach(authMiddleware)
router.afterEach(productAnalyticsPageViewMiddleware)
router.beforeEach(localeMiddleware)

export default router
