<template>
  <div class="tw-grid tw-grid-cols-2 tw-gap-4">
    <SelectWidget
      v-model:value="hour"
      :disabled="disabled"
      :placeholder="$t('component.time.setHour')"
      :options="hours"
    />
    <SelectWidget
      v-model:value="minute"
      :disabled="disabled"
      :placeholder="$t('component.time.setMinute')"
      :options="minutes"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import SelectWidget from '@/components/FormGroups/Widgets/SelectWidget.vue'

const props = defineProps<{
  value?: string | null
  disabled?: boolean
}>()

const emit = defineEmits<{
  'update:value': [string]
}>()

const generateTimeOptions = (range: number) =>
  computed(() =>
    [...Array(range).keys()]
      .map((time) => (time < 10 ? `0${time}` : `${time}`))
      .map((time) => ({ value: time, label: time })),
  )

const hours = generateTimeOptions(24)
const minutes = generateTimeOptions(60)

const hour = computed({
  get() {
    if (props.value === null || typeof props.value === 'undefined') {
      return null
    }

    return props.value.split(':')[0]
  },

  set(value) {
    emit('update:value', `${value}:${minute.value ? minute.value : '00'}`)
  },
})

const minute = computed({
  get() {
    if (props.value === null || typeof props.value === 'undefined') {
      return null
    }

    return props.value.split(':')[1]
  },

  set(value) {
    emit('update:value', `${hour.value ? hour.value : '00'}:${value}`)
  },
})
</script>
