import type { RouteRecordRaw } from 'vue-router'

const ShowCash = () => import('@/pages/Applications/CityOfCopenhagen/Cash/CityOfCopenhagenCash.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/CityOfCopenhagen/Configurations/CityOfCopenhagenConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/CityOfCopenhagen/Configurations/CityOfCopenhagenCreateConfiguration.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/CityOfCopenhagen/Configurations/CityOfCopenhagenConfigurationsList.vue')
const StoreContainer = () => import('@/pages/Applications/CityOfCopenhagen/Stores/CityOfCopenhagenStoreContainer.vue')
const EditStore = () => import('@/pages/Applications/CityOfCopenhagen/Stores/CityOfCopenhagenEditStore.vue')
const StoreList = () => import('@/pages/Applications/CityOfCopenhagen/Stores/CityOfCopenhagenStoresList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  CITY_OF_COPENHAGEN_CONFIGURATIONS_LIST: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_LIST',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_SHOW: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_SHOW',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_CREATE: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_CREATE',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_CASH: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_CASH',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_EDIT: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_EDIT',
  CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_SHOW: 'CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'city-of-copenhagen/configurations',
    component: Blank,
    children: [
      {
        path: '',
        component: ConfigurationList,
        name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_LIST,
      },
      {
        path: 'create',
        component: CreateConfiguration,
        name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_CREATE,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES }),
          },
          {
            path: 'stores',
            name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES,
            component: StoreList,
            meta: { subsection: 'stores' },
          },
          {
            path: 'stores/:storeId',
            component: StoreContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_SHOW,
                redirect: () => ({ name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_CASH }),
              },
              {
                path: 'cash',
                name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_CASH,
                component: ShowCash,
                meta: { subsection: 'cash' },
              },
              {
                path: 'edit',
                name: routeNames.CITY_OF_COPENHAGEN_CONFIGURATIONS_STORES_EDIT,
                component: EditStore,
                meta: { subsection: 'edit' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
