<template>
  <LuiSelect
    :id="name"
    ref="select"
    class="lui-select"
    :class="{
      'select--small': inputClass === 'input-sm',
      'select--borderless': borderLess,
    }"
    :align-right="alignRight"
    :model-value="value"
    :disabled="disabled"
    :has-error="hasError"
    :options="optionsWithNullable"
    :option-groups="optionGroups"
    @update:model-value="$emit('update:value', $event)"
  />
</template>

<script setup lang="ts">
/**
 * @deprecated
 * A wrapper for LuiSelect and the old select logic. Use LuiSelect directly instead
 */
import { LuiSelect, type LuiSelectOption, type LuiSelectOptionGroup } from '@loomispay/loomis-ui'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export type SelectWidgetOptions =
  | Record<string, LuiSelectOption | LuiSelectOptionGroup>
  | (LuiSelectOption | LuiSelectOptionGroup)[]

const props = withDefaults(
  defineProps<{
    alignRight?: boolean
    borderLess?: boolean
    name?: string | null
    value?: any
    options: SelectWidgetOptions
    placeholder?: string | null
    nullable?: boolean
    disabled?: boolean
    inputClass?: string | null
    hasError?: boolean
  }>(),
  {
    alignRight: false,
    borderLess: false,
    name: null,
    value: null,
    options: () => [],
    placeholder: null,
    nullable: false,
    disabled: false,
    inputClass: null,
    hasError: false,
  },
)

defineEmits<{
  'update:value': [any]
}>()

const { t } = useI18n()

const computedOptions = computed(() => {
  if (props.options instanceof Array) {
    return props.options
  }

  const optionsRecord = props.options as Record<string, LuiSelectOption | LuiSelectOptionGroup>

  return Object.keys(optionsRecord).map((key) => ({
    value: optionsRecord[key].value,
    label: optionsRecord[key].label,
  }))
})

/**
 * Since the old SelectWidget (this) takes both options and option groups in the same prop, we need to check
 * if the options array is options or option groups. We do this by checking if any of the option items has a option array.
 */
const optionGroups = computed(() => {
  if (
    computedOptions.value.length === 0 ||
    computedOptions.value.some((option) => !(option as LuiSelectOptionGroup).options)
  ) {
    return []
  }
  const optionGroups = computedOptions.value.filter(
    (option) => ((option as LuiSelectOptionGroup).options?.length || 0) > 0,
  )
  return [nullOption, ...optionGroups] as LuiSelectOptionGroup[]
})

const optionsWithNullable = computed(() => {
  if (optionGroups.value.length > 0) {
    return []
  }
  return [nullOption.value, ...computedOptions.value]
})

const nullOption = computed<LuiSelectOption>(() => {
  let label: string
  if (props.placeholder) {
    label = props.placeholder
  } else if (props.nullable) {
    label = t('global.none')
  } else {
    label = t('filters.datePicker.choose')
  }

  return {
    value: null,
    disabled: !props.nullable,
    label,
  }
})

const select = ref<HTMLElement | null>(null)

const focus = () => {
  select.value?.focus()
}

defineExpose({
  focus,
})
</script>

<style lang="scss">
.select {
  &--small {
    $icon-offset: 4px;

    --lui-select-height: 36px;
    --lui-select-padding: 0 calc(#{$icon-offset} + 16px + 8px) 0 6px;

    &::part(icon) {
      right: $icon-offset;
    }
  }

  &--borderless {
    --lui-select-border-color: transparent;
  }
}
</style>
