import type { RouteRecordRaw } from 'vue-router'

import { Section } from './shared/constants'

const Blank = () => import('@/pages/Blank.vue')
const CreateBundle = () => import('@/pages/Products/Bundles/BundlesCreate.vue')
const EditBundle = () => import('@/pages/Products/Bundles/BundlesEdit.vue')
const BundleList = () => import('@/pages/Products/Bundles/BundlesList.vue')
const CreateModifierGroup = () => import('@/pages/Products/ModifierGroups/ModifierGroupsCreate.vue')
const EditModifierGroup = () => import('@/pages/Products/ModifierGroups/ModifierGroupsEdit.vue')
const ModifierGroupList = () => import('@/pages/Products/ModifierGroups/ModifierGroupsList.vue')
const CreateModifierImport = () => import('@/pages/Products/ModifierImports/ModifierImportsCreate.vue')
const CreateModifier = () => import('@/pages/Products/Modifiers/ModifiersCreate.vue')
const EditModifier = () => import('@/pages/Products/Modifiers/ModifiersEdit.vue')
const ModifierList = () => import('@/pages/Products/Modifiers/ModifiersList.vue')
const CreatePriceGroup = () => import('@/pages/Products/PriceGroups/PriceGroupsCreate.vue')
const EditPriceGroup = () => import('@/pages/Products/PriceGroups/PriceGroupsEdit.vue')
const PriceGroupList = () => import('@/pages/Products/PriceGroups/PriceGroupsList.vue')
const CreateProductFolder = () => import('@/pages/Products/ProductFolders/ProductFoldersCreate.vue')
const EditProductFolder = () => import('@/pages/Products/ProductFolders/ProductFoldersEdit.vue')
const ProductFolderList = () => import('@/pages/Products/ProductFolders/ProductFoldersList.vue')
const CreateProductGroup = () => import('@/pages/Products/ProductGroups/ProductGroupsCreate.vue')
const EditProductGroup = () => import('@/pages/Products/ProductGroups/ProductGroupsEdit.vue')
const ProductGroupList = () => import('@/pages/Products/ProductGroups/ProductGroupsList.vue')
const CreateProductImport = () => import('@/pages/Products/Products/ProductImport.vue')
const EditProduct = () => import('@/pages/Products/Products/ProductEdit.vue')
const CreateProduct = () => import('@/pages/Products/Products/ProductCreate.vue')
const ProductList = () => import('@/pages/Products/Products/ProductList.vue')

export const routeNames = {
  PRODUCTS_PRODUCTS_LIST: 'PRODUCTS_PRODUCTS_LIST',
  PRODUCTS_PRODUCT_CREATE: 'PRODUCTS_PRODUCT_CREATE',
  PRODUCTS_PRODUCT_EDIT: 'PRODUCTS_PRODUCT_EDIT',
  PRODUCTS_PRODUCT_EDIT_REDIRECT: 'PRODUCTS_PRODUCT_EDIT_REDIRECT',
  PRODUCTS_PRODUCT_IMPORT: 'PRODUCTS_PRODUCT_IMPORT',

  PRODUCTS_PRODUCT_FOLDERS_LIST: 'PRODUCTS_PRODUCT_FOLDERS_LIST',
  PRODUCTS_PRODUCT_FOLDER_CREATE: 'PRODUCTS_PRODUCT_FOLDER_CREATE',
  PRODUCTS_PRODUCT_FOLDER_EDIT: 'PRODUCTS_PRODUCT_FOLDER_EDIT',
  PRODUCTS_PRODUCT_FOLDER_EDIT_REDIRECT: 'PRODUCTS_PRODUCT_FOLDER_EDIT_REDIRECT',

  PRODUCTS_PRODUCT_GROUPS_LIST: 'PRODUCTS_PRODUCT_GROUPS_LIST',
  PRODUCTS_PRODUCT_GROUP_CREATE: 'PRODUCTS_PRODUCT_GROUP_CREATE',
  PRODUCTS_PRODUCT_GROUP_EDIT: 'PRODUCTS_PRODUCT_GROUP_EDIT',
  PRODUCTS_PRODUCT_GROUP_EDIT_REDIRECT: 'PRODUCTS_PRODUCT_GROUP_EDIT_REDIRECT',

  PRODUCTS_MODIFIERS_LIST: 'PRODUCTS_MODIFIERS_LIST',
  PRODUCTS_MODIFIER_CREATE: 'PRODUCTS_MODIFIER_CREATE',
  PRODUCTS_MODIFIER_EDIT: 'PRODUCTS_MODIFIER_EDIT',
  PRODUCTS_MODIFIER_EDIT_REDIRECT: 'PRODUCTS_MODIFIER_EDIT_REDIRECT',
  PRODUCTS_MODIFIER_IMPORT: 'PRODUCTS_MODIFIER_IMPORT',

  PRODUCTS_MODIFIER_GROUPS_LIST: 'PRODUCTS_MODIFIER_GROUPS_LIST',
  PRODUCTS_MODIFIER_GROUP_CREATE: 'PRODUCTS_MODIFIER_GROUP_CREATE',
  PRODUCTS_MODIFIER_GROUP_EDIT: 'PRODUCTS_MODIFIER_GROUP_EDIT',
  PRODUCTS_MODIFIER_GROUP_EDIT_REDIRECT: 'PRODUCTS_MODIFIER_GROUP_EDIT_REDIRECT',

  PRODUCTS_BUNDLES_LIST: 'PRODUCTS_BUNDLES_LIST',
  PRODUCTS_BUNDLE_CREATE: 'PRODUCTS_BUNDLE_CREATE',
  PRODUCTS_BUNDLE_EDIT: 'PRODUCTS_BUNDLE_EDIT',
  PRODUCTS_BUNDLE_EDIT_REDIRECT: 'PRODUCTS_BUNDLE_EDIT_REDIRECT',

  PRODUCTS_PRICE_GROUPS_LIST: 'PRODUCTS_PRICE_GROUPS_LIST',
  PRODUCTS_PRICE_GROUP_CREATE: 'PRODUCTS_PRICE_GROUP_CREATE',
  PRODUCTS_PRICE_GROUP_EDIT: 'PRODUCTS_PRICE_GROUP_EDIT',
  PRODUCTS_PRICE_GROUP_EDIT_REDIRECT: 'PRODUCTS_PRICE_GROUP_EDIT_REDIRECT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'products',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_PRODUCTS_LIST,
        component: ProductList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_PRODUCT_CREATE,
        component: CreateProduct,
        meta: {
          merchant: true,
        },
      },
      {
        path: 'import',
        name: routeNames.PRODUCTS_PRODUCT_IMPORT,
        component: CreateProductImport,
      },
      {
        path: ':productId',
        name: routeNames.PRODUCTS_PRODUCT_EDIT,
        component: EditProduct,
        props: true,
      },
      {
        path: ':productId/edit',
        name: routeNames.PRODUCTS_PRODUCT_EDIT_REDIRECT,
        redirect: { name: routeNames.PRODUCTS_PRODUCT_EDIT },
      },
    ],
  },

  {
    path: 'product-folders',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_PRODUCT_FOLDERS_LIST,
        component: ProductFolderList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_PRODUCT_FOLDER_CREATE,
        component: CreateProductFolder,
      },
      {
        path: ':productFolderId',
        name: routeNames.PRODUCTS_PRODUCT_FOLDER_EDIT,
        component: EditProductFolder,
        props: true,
      },
      {
        path: ':productFolderId/edit',
        name: routeNames.PRODUCTS_PRODUCT_FOLDER_EDIT_REDIRECT,
        redirect: { name: routeNames.PRODUCTS_PRODUCT_FOLDER_EDIT },
      },
    ],
  },

  {
    path: 'product-groups',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_PRODUCT_GROUPS_LIST,
        component: ProductGroupList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_PRODUCT_GROUP_CREATE,
        component: CreateProductGroup,
      },
      {
        path: ':productGroupId',
        name: routeNames.PRODUCTS_PRODUCT_GROUP_EDIT,
        props: true,
        component: EditProductGroup,
      },
      {
        path: ':productGroupId/edit',
        name: routeNames.PRODUCTS_PRODUCT_GROUP_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.PRODUCTS_PRODUCT_GROUP_EDIT }),
      },
    ],
  },

  {
    path: 'modifiers',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_MODIFIERS_LIST,
        component: ModifierList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_MODIFIER_CREATE,
        component: CreateModifier,
      },
      {
        path: 'import',
        name: routeNames.PRODUCTS_MODIFIER_IMPORT,
        component: CreateModifierImport,
      },
      {
        path: ':modifierId',
        component: EditModifier,
        props: true,
        name: routeNames.PRODUCTS_MODIFIER_EDIT,
      },
      {
        path: ':modifierId/edit',
        name: routeNames.PRODUCTS_MODIFIER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.PRODUCTS_MODIFIER_EDIT }),
      },
    ],
  },
  {
    path: 'modifier-groups',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_MODIFIER_GROUPS_LIST,
        component: ModifierGroupList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_MODIFIER_GROUP_CREATE,
        component: CreateModifierGroup,
      },
      {
        path: ':modifierGroupId',
        props: true,
        name: routeNames.PRODUCTS_MODIFIER_GROUP_EDIT,
        component: EditModifierGroup,
      },
      {
        path: ':modifierGroupId/edit',
        name: routeNames.PRODUCTS_MODIFIER_GROUP_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.PRODUCTS_MODIFIER_GROUP_EDIT }),
      },
    ],
  },
  {
    path: 'bundles',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_BUNDLES_LIST,
        component: BundleList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_BUNDLE_CREATE,
        component: CreateBundle,
      },
      {
        path: ':bundleId',
        component: EditBundle,
        props: true,
        name: routeNames.PRODUCTS_BUNDLE_EDIT,
      },
      {
        path: ':bundleId/edit',
        name: routeNames.PRODUCTS_BUNDLE_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.PRODUCTS_BUNDLE_EDIT }),
      },
    ],
  },
  {
    path: 'price-groups',
    component: Blank,
    meta: {
      section: Section.PRODUCTS,
    },
    children: [
      {
        path: '',
        name: routeNames.PRODUCTS_PRICE_GROUPS_LIST,
        component: PriceGroupList,
      },
      {
        path: 'create',
        name: routeNames.PRODUCTS_PRICE_GROUP_CREATE,
        component: CreatePriceGroup,
      },
      {
        path: ':priceGroupId',
        props: true,
        component: EditPriceGroup,
        name: routeNames.PRODUCTS_PRICE_GROUP_EDIT,
      },
      {
        path: ':priceGroupId/edit',
        name: routeNames.PRODUCTS_PRICE_GROUP_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.PRODUCTS_PRICE_GROUP_EDIT }),
      },
    ],
  },
]

export default routes
