import axios from 'axios'
import { createI18n } from 'vue-i18n'

import en_US from '@/locale/en_US/en_US.json'
import modal from '@/modal'

export enum SUPPORT_LOCALES {
  en_US = 'en_US',
  es_ES = 'es_ES',
  sv_SE = 'sv_SE',
  da_DK = 'da_DK',
}

export type SUPPORT_LOCALES_TYPE = keyof typeof SUPPORT_LOCALES

export const BASE_LOCALE = SUPPORT_LOCALES.en_US

export const i18n = createI18n({
  legacy: false,
  flatJson: true,
  locale: BASE_LOCALE,
  fallbackLocale: BASE_LOCALE,
  allowComposition: true,
  messages: { en_US, es_ES: {}, sv_SE: {}, da_DK: {} },
})

const loadedLocales = [BASE_LOCALE]

function setI18nLanguage(locale: SUPPORT_LOCALES) {
  i18n.global.locale.value = locale
  axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html')?.setAttribute('locale', locale.replace('_', '-'))
  return locale
}

export async function loadLanguageAsync(locale: SUPPORT_LOCALES, filename: string = '') {
  if (!i18n.global.availableLocales.includes(locale)) {
    locale = BASE_LOCALE
  }
  let messages
  try {
    messages = (await import(`./locale/${locale}/${filename ? filename + '-' : ''}${locale}.json`)).default
  } catch (error) {
    modal.confirm(i18n.global.t('newDeploymentRefreshNeededModal.body'), () => window.location.reload())
  }
  i18n.global.mergeLocaleMessage(locale, messages)
  // i18n.global.setLocaleMessage(locale, messages)
  loadedLocales.push(locale)
  return setI18nLanguage(locale)
}
