<template>
  <FormGroup v-bind="{ topHelpText, optional, link, name, label, helpText, error }">
    <AdaptiveWidget
      :value="value"
      :placeholder="placeholder"
      :endpoint="endpoint"
      :params="params"
      :path="path"
      :has-error="!!error"
      :result-label-property="resultLabelProperty"
      :result-id-property="resultIdProperty"
      :disabled="disabled"
      :nullable="nullable"
      :auto-select="autoSelect"
      @update:value="$emit('update:value', $event)"
      @select="$emit('select', $event)"
    />
  </FormGroup>
</template>

<script setup lang="ts">
import type { FormGroupProps } from '@/interfaces/Frontend/FormGroup'

import FormGroup from '@/components/FormGroups/Block/BlockFormGroup.vue'
import AdaptiveWidget from '@/components/FormGroups/Widgets/AdaptiveWidget.vue'
import { useFormGroup } from '@/composables/useFormGroup'

interface Props extends FormGroupProps {
  endpoint: string
  inputAttrs?: Record<string, unknown>
  nullable?: boolean
  params?: Record<string, unknown>
  path?: string
  placeholder?: string
  resultIdProperty?: string | Function
  resultLabelProperty?: string | Function
  suffix?: string
  value?: string | number | null
  autoSelect?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  inputAttrs: () => ({}),
  params: () => ({}),
  path: undefined,
  placeholder: '',
  resultIdProperty: 'id',
  resultLabelProperty: 'name',
  suffix: undefined,
  value: null,
  autoSelect: false,
})

defineEmits<{
  'update:value': [string | number | null]
  'select': [any]
}>()

useFormGroup(props)
</script>
