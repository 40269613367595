import type { RouteRecordRaw } from 'vue-router'

const BumpScreenList = () => import('@/pages/Applications/BumpScreens/BumpScreens/BumpScreensBumpScreensList.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/BumpScreens/Configurations/BumpScreensConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/BumpScreens/Configurations/BumpScreensConfigurationCreate.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/BumpScreens/Configurations/BumpScreensConfigurationsList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  BUMP_SCREENS_CONFIGURATIONS_LIST: 'BUMP_SCREENS_CONFIGURATIONS_LIST',
  BUMP_SCREENS_CONFIGURATIONS_CREATE: 'BUMP_SCREENS_CONFIGURATIONS_CREATE',
  BUMP_SCREENS_CONFIGURATIONS_SHOW: 'BUMP_SCREENS_CONFIGURATIONS_SHOW',
  BUMP_SCREENS_CONFIGURATIONS_BUMP_SCREENS: 'BUMP_SCREENS_CONFIGURATIONS_BUMP_SCREENS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'bump-screens/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.BUMP_SCREENS_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.BUMP_SCREENS_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.BUMP_SCREENS_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.BUMP_SCREENS_CONFIGURATIONS_BUMP_SCREENS }),
          },
          {
            path: 'bump-screens',
            name: routeNames.BUMP_SCREENS_CONFIGURATIONS_BUMP_SCREENS,
            component: BumpScreenList,
          },
        ],
      },
    ],
  },
]

export default routes
