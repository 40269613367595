import { defineStore } from 'pinia'

import { useTailwindStore } from '@/stores/tailwind'

const lsManuallyCollapsed = localStorage.getItem('navigationManuallyCollapsed')

export const useNavigationStore = defineStore('navigation', {
  state: () => ({
    // Always start with the navigation collapsed if the user has manually collapsed it,
    // otherwise only start collapsed if the screen is < 1440px.
    expanded:
      (lsManuallyCollapsed && !(lsManuallyCollapsed === 'true')) || window.matchMedia('(min-width: 1440px)').matches,
    manuallyCollapsed: lsManuallyCollapsed ? lsManuallyCollapsed === 'true' : false,
    navigationInert: false,
  }),
  actions: {
    SET_EXPANDED(expanded: boolean, isMobile: boolean) {
      this.expanded = expanded
      localStorage.setItem('navigationExpanded', String(expanded))
      //Disables scroll if navigation is expanded and client is mobile
      const body = document.querySelector('body')
      const layoutRoot = document.querySelector('#layout-root')
      if (isMobile && expanded) {
        body?.classList.add('no-scroll')
        layoutRoot?.classList.add('no-scroll')
      } else {
        body?.classList.remove('no-scroll')
        layoutRoot?.classList.remove('no-scroll')
      }
    },
    SET_MANUALLY_COLLAPSED(manuallyCollapsed: boolean) {
      this.manuallyCollapsed = manuallyCollapsed
      localStorage.setItem('navigationManuallyCollapsed', String(manuallyCollapsed))
    },
    expandNavigation() {
      const tailwindStore = useTailwindStore()
      const { lg, xl } = tailwindStore.screens
      this.SET_EXPANDED(true, window.innerWidth < lg)
      // If the screen is >= xl, "forget" that the user collapsed the navigation manually.
      if (window.innerWidth >= xl) {
        this.SET_MANUALLY_COLLAPSED(false)
      }
    },
    collapseNavigation() {
      const tailwindStore = useTailwindStore()
      const { lg, xl } = tailwindStore.screens
      this.SET_EXPANDED(false, window.innerWidth < lg)
      // If the screen is >= xl, remember that the user collapsed the navigation manually.
      if (window.innerWidth >= xl) {
        this.SET_MANUALLY_COLLAPSED(true)
      }
    },
    toggleNavigation() {
      if (this.expanded) {
        this.collapseNavigation()
        return
      }
      this.expandNavigation()
    },
    setNavigationInert(inert: boolean) {
      this.navigationInert = inert
    },
  },
})
