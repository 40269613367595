/**
 * A composable version of the validation error mixin.
 * Provides some functions and state, to help handle input validation errors.
 */
import { type Ref, ref } from 'vue'

import type { ValidationErrorBody } from '@/interfaces/BaseApi'

import notify from '@/notify'

export const useValidationError = () => {
  const errors: Ref<ValidationErrorBody> = ref({})
  const resetErrors = () => {
    errors.value = {}
  }
  const getError = (name: string, errorBody: ValidationErrorBody = errors.value): string | null => {
    if (!errorBody[name]) {
      return null
    }
    return errorBody[name][0]
  }

  const getErrorsWithPrefix = (prefix: string): ValidationErrorBody => {
    return Object.entries(errors.value)
      .filter((entry) => entry[0].startsWith(prefix))
      .reduce((errors, entry) => {
        errors[entry[0].substring(prefix.length)] = entry[1]

        return errors
      }, {} as ValidationErrorBody)
  }

  const hasError = (name: string): boolean => {
    return !!getError(name)
  }

  /**
   * Catch validation errors from an async function.
   * This can be used to decode blobs, so for download functions this should be used.
   * @param error
   */
  const catchValidationErrorsAsync = async (error: any) => {
    if (error.response?.status === 422) {
      let parsedError = error.response.data
      //If error is a blob (file download)
      if (error.response?.data?.text?.()) {
        parsedError = JSON.parse(await error.response.data.text())
      }
      notify.error(parsedError.message)
      errors.value = parsedError.errors
      return errors.value
    }
    return false
  }

  const catchValidationErrors = (error: any) => {
    if (error.response?.status === 422) {
      notify.error(error.response.data.message)
      errors.value = error.response.data.errors
      return errors.value
    }
    return false
  }

  return {
    errors,
    catchValidationErrorsAsync,
    resetErrors,
    getError,
    getErrorsWithPrefix,
    hasError,
    catchValidationErrors,
  }
}
