<template>
  <a
    v-if="isExternalLink"
    v-bind="$attrs"
    :href="String(to)"
    target="_blank"
  >
    <slot />
  </a>
  <RouterLink
    v-else
    v-bind="$attrs"
    :to="to"
    :exact-active-class="exactActiveClass"
  >
    <slot />
  </RouterLink>
</template>

<script lang="ts" setup>
/**
 * To avoid switching between router links and anchor tags (when the link is external), we use a custom component.
 * This is inspired by the Vue Router documentation:
 * https://router.vuejs.org/guide/advanced/extending-router-link.html
 */
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps<{
  to: RouteLocationRaw
  exactActiveClass?: string
}>()

const isExternalLink = computed(() => {
  return (
    typeof props.to === 'string' &&
    (props.to.startsWith('http') || props.to.startsWith('mailto') || props.to.startsWith('tel'))
  )
})
</script>
