<template>
  <div v-show="multipleMerchants">
    <select
      v-if="loading"
      class="form-control"
      :class="inputClass"
      :disabled="disabled"
    />

    <div
      v-else-if="merchantCount <= 10"
      class="tw-bg-gray-5 tw-p-1 tw-rounded"
    >
      <LuiButton
        v-for="option in options"
        :key="option.value"
        full-width
        emphasis="low"
        class="tw-text-white tw-text-left"
        :class="[option.value === selectedMerchantId ? 'tw-bg-gray-7' : 'tw-bg-transparent']"
        @click="inputHandler(option.value)"
      >
        <span class="tw-inline-flex tw-w-full tw-text-md tw-justify-between tw-font-body tw-gap-2">
          <span>{{ option.label }}</span>
          <LuiIcon
            v-if="option.value === selectedMerchantId"
            name="check"
            class="tw-shrink-0"
          />
        </span>
      </LuiButton>
    </div>

    <div
      v-else
      class="tw-bg-gray-5 tw-p-1 tw-rounded"
    >
      <TypeaheadWidget
        input-class="tw-text-gray-black"
        :placeholder="placeholder || $t('placeholder.searchMerchants')"
        endpoint="/api/v2/merchants"
        :params="params"
        :value="selectedMerchantId"
        :result-label-property="(row: any) => row.attributes.name"
        result-id-property="id"
        :disabled="!!disabled"
        @update:value="inputHandler"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import TypeaheadWidget from '@/components/FormGroups/Widgets/TypeaheadWidget.vue'
import { useMerchantsStore } from '@/stores/merchants'

const router = useRouter()

withDefaults(
  defineProps<{
    placeholder?: string
    params?: Record<string, unknown>
    inputClass?: string
    disabled?: boolean
    nullable?: boolean
  }>(),
  {
    placeholder: undefined,
    params: () => ({}),
    inputClass: '',
    disabled: false,
    nullable: false,
  },
)

const { merchantsById, selectedMerchantId, loading, selectedMerchant, multipleMerchants, merchantCount } =
  storeToRefs(useMerchantsStore())

const options = computed(() => {
  if (loading.value) {
    return []
  }

  return Object.keys(merchantsById.value).map((id) => {
    return {
      value: id,
      label: merchantsById.value[id].attributes.name,
    }
  })
})

/**
 * Updates the selectedMerchantId in the route
 * @param value
 */
const inputHandler = async (value: string | number | null) => {
  if (value || value === 0) {
    router.replace({ params: { selectedMerchantId: value } })
  }
}
</script>
