import type { AxiosPromise } from 'axios'

import type {
  PreparationConfiguration,
  PreparationCourse,
  PreparationCourseAttributes,
  PreparationLocation,
  PreparationLocationAttributes,
  PreparationStation,
  PreparationStationAttributes,
} from '@/interfaces/Applications/Preparation/PreparationTypes'
import type { BaseApiResponse } from '@/interfaces/BaseApi'

import type { BaseApiPromise } from '@/types/api'
import type { PortalId } from '@/types/portal'
import type { PreparationStationCreateAttributes } from '@/types/preparation'

import http from '@/http'

const endpoint = '/api/v2/applications/preparation'

// Configurations
export const fetchConfigurations = (merchantId: PortalId) =>
  http.get(`${endpoint}/configurations`, { 'filter[merchant_id]': merchantId }) as BaseApiPromise<
    PreparationConfiguration[]
  >

export const createConfiguration = (merchantId: PortalId) =>
  http.post(`${endpoint}/configurations`, { merchant_id: merchantId }) as BaseApiPromise<PreparationConfiguration>

// Courses
export const fetchCourses = (locationId: PortalId) => {
  return http.get(`${endpoint}/courses`, http.wrapKeysInApiFilterSyntax({ location_id: locationId })) as BaseApiPromise<
    PreparationCourse[]
  >
}

export const deleteCourse = (courseId: PortalId) => {
  return http.delete(`${endpoint}/courses/${courseId}`)
}

export const fetchCourse = (courseId: PortalId) => {
  return http.get(`${endpoint}/courses/${courseId}`)
}

export const createCourse = (
  attributes: PreparationCourseAttributes,
): AxiosPromise<BaseApiResponse<PreparationCourse>> => {
  return http.post<PreparationCourse>(`${endpoint}/courses`, attributes)
}

export const updateCourse = (
  courseId: PortalId,
  fieldsToUpdate: Partial<PreparationCourseAttributes>,
): BaseApiPromise<PreparationCourse> => {
  return http.put<BaseApiResponse<PreparationCourse>>(`${endpoint}/courses/${courseId}`, fieldsToUpdate)
}

export const sortCourses = (courses: { id: PortalId }[]): AxiosPromise<null> => {
  return http.put<null>(`${endpoint}/courses/priorities`, { courses })
}

// Locations
export const fetchLocations = (params?: { configurationId?: PortalId; storeId?: PortalId }) => {
  const { configurationId, storeId } = params || {}
  return http.get(`${endpoint}/locations`, {
    ...(configurationId && { 'filter[configuration_id]': configurationId }),
    ...(storeId && { 'filter[store_id]': storeId }),
  }) as BaseApiPromise<PreparationLocation[]>
}

// Locations
export const fetchLocationsByConfigurationId = (configurationId: PortalId) => fetchLocations({ configurationId })

export const fetchLocationsByStoreId = (storeId: PortalId) => fetchLocations({ storeId })

export const fetchLocation = (locationId: PortalId) =>
  http.get(`${endpoint}/locations/${locationId}`) as BaseApiPromise<PreparationLocation>

export const createLocation = ({ configurationId, storeId }: { configurationId: PortalId; storeId: PortalId }) =>
  http.post(`${endpoint}/locations`, {
    configuration_id: configurationId,
    store_id: storeId,
  }) as BaseApiPromise<PreparationLocation>

export const updateLocation = (locationId: PortalId, fieldsToUpdate: Partial<PreparationLocationAttributes>) =>
  http.put(`${endpoint}/locations/${locationId}`, fieldsToUpdate) as BaseApiPromise<PreparationLocation>

// Stations
export const fetchStations = (locationId: PortalId) =>
  http.get(`${endpoint}/stations`, { 'filter[location_id]': locationId }) as BaseApiPromise<PreparationStation[]>

export const createStation = (attributes: PreparationStationCreateAttributes) =>
  http.post(`${endpoint}/stations`, attributes) as BaseApiPromise<PreparationStation>

export const fetchStation = (stationId: PortalId) =>
  http.get(`${endpoint}/stations/${stationId}`) as BaseApiPromise<PreparationStation>

export const updateStation = (stationId: PortalId, fieldsToUpdate: Partial<PreparationStationAttributes>) =>
  http.put(`${endpoint}/stations/${stationId}`, fieldsToUpdate) as BaseApiPromise<PreparationStation>

export const deleteStation = (stationId: PortalId) => http.delete(`${endpoint}/stations/${stationId}`)
