import { storeToRefs } from 'pinia'
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

import notify from '@/notify'
import { usePreparationStore } from '@/stores/preparation'

const PreparationListStores = () => import('@/pages/Applications/Preparation/PreparationListLocations.vue')
const PreparationLocation = () => import('@/pages/Applications/Preparation/Location/PreparationLocation.vue')
const PreparationListStations = () =>
  import('@/pages/Applications/Preparation/Location/Stations/PreparationListStations.vue')
const PreparationEditStation = () =>
  import('@/pages/Applications/Preparation/Location/Stations/PreparationEditStation.vue')
const PreparationCreateStation = () =>
  import('@/pages/Applications/Preparation/Location/Stations/PreparationCreateStation.vue')
const PreparationListCourses = () =>
  import('@/pages/Applications/Preparation/Location/Courses/PreparationListCourses.vue')
const PreparationCreateCourse = () =>
  import('@/pages/Applications/Preparation/Location/Courses/PreparationCreateCourse.vue')
const PreparationEditCourse = () =>
  import('@/pages/Applications/Preparation/Location/Courses/PreparationEditCourse.vue')

export const routeNames = {
  PREPARATION_LIST_LOCATIONS: 'PREPARATION_LIST_LOCATIONS',
  PREPARATION_LOCATION: 'PREPARATION_LOCATION',
  PREPARATION_LIST_STATIONS: 'PREPARATION_LIST_STATIONS',
  PREPARATION_CREATE_STATION: 'PREPARATION_CREATE_STATION',
  PREPARATION_EDIT_STATION: 'PREPARATION_EDIT_STATION',
  PREPARATION_LIST_COURSES: 'PREPARATION_LIST_COURSES',
  PREPARATION_CREATE_COURSE: 'PREPARATION_CREATE_COURSE',
  PREPARATION_EDIT_COURSE: 'PREPARATION_EDIT_COURSE',
}

const fetchPreparationData = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const locationId = to.params.locationId as string

  const { fetchLocation, fetchStations, fetchCourses } = usePreparationStore()
  const { locationsById, stationsForLocation, coursesForLocation } = storeToRefs(usePreparationStore())

  // Only fetch relevant data if it doesn't exist, so we don't make requests on every navigation
  const locationFromStore = locationsById.value?.[locationId]
  const promises = []
  if (!locationFromStore) {
    promises.push(fetchLocation(locationId))
  }
  if (!stationsForLocation.value(locationId).length) {
    promises.push(fetchStations(locationId))
  }
  if (!coursesForLocation.value(locationId).length) {
    promises.push(fetchCourses(locationId))
  }
  try {
    await Promise.all(promises)
    // If there are any stations, redirect to the stations list child page
    if (stationsForLocation.value(locationId).length && to.name === routeNames.PREPARATION_LOCATION) {
      next({ name: routeNames.PREPARATION_LIST_STATIONS, params: { locationId } })
    } else {
      next()
    }
  } catch (error: any) {
    notify.error(error.message)
    next()
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: 'kitchen-management',
    component: PreparationListStores,
    name: routeNames.PREPARATION_LIST_LOCATIONS,
  },
  {
    path: 'kitchen-management/:locationId',
    component: PreparationLocation,
    name: routeNames.PREPARATION_LOCATION,
    beforeEnter: fetchPreparationData,
    meta: {
      merchant: true,
    },
    children: [
      {
        path: 'stations',
        component: PreparationListStations,
        props: true,
        name: routeNames.PREPARATION_LIST_STATIONS,
      },
      {
        path: 'courses',
        component: PreparationListCourses,
        props: true,
        name: routeNames.PREPARATION_LIST_COURSES,
      },
    ],
  },
  {
    path: 'kitchen-management/:locationId/stations/create',
    component: PreparationCreateStation,
    name: routeNames.PREPARATION_CREATE_STATION,
    props: true,
    meta: {
      merchant: true,
    },
    beforeEnter: fetchPreparationData,
  },
  {
    path: 'kitchen-management/:locationId/stations/:stationId',
    component: PreparationEditStation,
    name: routeNames.PREPARATION_EDIT_STATION,
    props: true,
    meta: {
      merchant: true,
    },
    beforeEnter: fetchPreparationData,
  },
  {
    path: 'kitchen-management/:locationId/courses/create',
    component: PreparationCreateCourse,
    name: routeNames.PREPARATION_CREATE_COURSE,
    props: true,
    meta: {
      merchant: true,
    },
    beforeEnter: fetchPreparationData,
  },
  {
    path: 'kitchen-management/:locationId/courses/:courseId',
    component: PreparationEditCourse,
    name: routeNames.PREPARATION_EDIT_COURSE,
    props: true,
    meta: {
      merchant: true,
    },
    beforeEnter: fetchPreparationData,
  },
]

export default routes
