<template>
  <FormGroup v-bind="{ topHelpText, optional, link, name, label, helpText, error }">
    <LuiNumberInput
      ref="inputRef"
      :model-value="value"
      :placeholder="placeholder || label"
      :has-error="!!error"
      focus-on-mounted
      v-bind="{
        name,
        disabled,
        min,
        max,
        currency,
        currencyDisplay,
        fractionDigits,
        locale,
        ...inputProps,
      }"
      @update:model-value="onUpdate"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @enter="$emit('enter', $event)"
    />
  </FormGroup>
</template>

<script setup lang="ts">
import {
  type LuiIconName,
  LuiNumberInput,
  type LuiNumberInputCurrencyDisplay,
  type LuiNumberInputFractionDigits,
} from '@loomispay/loomis-ui'
import { type Ref, ref } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'

import type { FormGroupProps } from '@/interfaces/Frontend/FormGroup'

import FormGroup from '@/components/FormGroups/Block/BlockFormGroup.vue'
import { useFormGroup } from '@/composables/useFormGroup'
import { useAuthStore } from '@/stores/auth'

interface Props extends FormGroupProps {
  value?: number | null | undefined
  placeholder?: string
  prefix?: string
  suffix?: string
  prefixIcon?: LuiIconName
  suffixIcon?: LuiIconName
  min?: number | undefined
  max?: number | undefined
  inputProps?: Record<string, unknown>
  fractionDigits?: LuiNumberInputFractionDigits
  currency?: string
  currencyDisplay?: LuiNumberInputCurrencyDisplay
  locale?: string
  focusOnMounted?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  name: undefined,
  label: undefined,
  error: undefined,
  topHelpText: undefined,
  link: undefined,
  helpText: undefined,
  value: undefined,
  disabled: false,
  placeholder: '',
  prefix: undefined,
  suffix: undefined,
  prefixIcon: undefined,
  suffixIcon: undefined,
  min: undefined,
  max: undefined,
  inputProps: () => ({}),
  fractionDigits: undefined,
  currency: undefined,
  currencyDisplay: undefined,
  focusOnMounted: false,
  locale: () => useAuthStore().currentNumberLocale,
})

const emit = defineEmits<{
  'update:value': [value: number | null]
  'focus': [event: Event]
  'blur': [event: Event]
  'enter': [event: KeyboardEvent]
}>()

const onUpdate = ($event: number | null) => {
  emit('update:value', $event)
}

const inputRef = ref<ComponentExposed<typeof LuiNumberInput> | null>()

useFormGroup(props, inputRef as Ref<HTMLElement | null>)
</script>
