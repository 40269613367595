<template>
  <td :class="[column?.disableTruncation ? '' : 'tw-truncate', resolveFunctionOrValue(column?.class)]">
    <component
      :is="resolveFunctionOrValue(column?.to, tableItem) ? AppLink : 'span'"
      class="tw-w-full tw-h-full tw-justify-center tw-items-stretch tw-inline-flex tw-flex-col"
      :to="resolveFunctionOrValue(column?.to, tableItem)"
      :class="[
        column?.disableTruncation ? '' : 'tw-truncate',
        innerClass,
        resolveFunctionOrValue(column?.innerClass, tableItem),
      ]"
    >
      <slot>
        <div
          v-if="column?.type === 'clickIndicator'"
          class="tw-flex tw-justify-end"
        >
          <LuiIcon
            name="chevron-right-small"
            class="tw-shrink-0"
            :height="16"
            :width="16"
          />
        </div>
        <template v-else>
          <slot name="value">
            <span :class="[column?.disableTruncation ? '' : 'tw-truncate', column?.lgValue ? 'lg:tw-hidden' : '']">
              {{ getValue('value') }}
            </span>
            <span
              class="tw-hidden"
              :class="{ 'lg:tw-inline': column?.lgValue }"
            >
              {{ getValue('lgValue') }}
            </span>
          </slot>
          <div
            class="tw-text-muted tw-text-xs"
            :class="{ 'tw-hidden': !column?.subValue, 'lg:tw-hidden': column?.lgSubValue === null }"
          >
            <slot name="subValue">
              <span
                class="tw-truncate tw-break-all"
                :class="{ 'lg:tw-hidden': column?.lgSubValue !== undefined }"
              >
                {{ getValue('subValue') }}
              </span>
              <span
                class="tw-truncate tw-break-words"
                :class="{ 'tw-hidden': !column?.lgSubValue }"
              >
                {{ getValue('lgSubValue') }}
              </span>
            </slot>
          </div>
        </template>
      </slot>
    </component>
  </td>
</template>

<script setup lang="ts">
import get from 'lodash/get'

import type { BasicTableColumn } from '@/interfaces/Frontend/BasicTable'

import AppLink from '@/components/Utilities/AppLink.vue'
import { resolveFunctionOrValue } from '@/utils/string/resolveFunctionOrValue'

const props = defineProps<{
  innerClass?: string | string[]
  column?: BasicTableColumn
  tableItem?: any
}>()

/**
 * Gets the value of the given key from the given item.
 * The valueType can either be 'value', 'lgValue', 'subValue' or 'lgSubValue'
 */
const getValue = (valueType: 'value' | 'lgValue' | 'subValue' | 'lgSubValue'): string => {
  if (!props.column) {
    return ''
  }
  if (props.column[valueType] instanceof Function) {
    return (props.column[valueType] as Function)(props.tableItem)
  }
  return get(props.tableItem, `${props.column[valueType]}`)
}
</script>
