import type { RouteRecordRaw } from 'vue-router'

const ConfigurationList = () =>
  import('@/pages/Applications/Tables/Configurations/TablesApplicationListConfigurations.vue')

export const routeNames = {
  TABLES_LIST_CONFIGURATIONS: 'TABLES_LIST_CONFIGURATIONS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'tables/configurations',
    name: routeNames.TABLES_LIST_CONFIGURATIONS,
    component: ConfigurationList,
  },
]

export default routes
