import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

const PackagesList = () => import('@/pages/System/Packages/PackagesList.vue')
const PackagesCreate = () => import('@/pages/System/Packages/PackagesCreate.vue')
const PackagesEdit = () => import('@/pages/System/Packages/PackagesEdit.vue')

const Blank = () => import('@/pages/Blank.vue')
export const routeNames = {
  PACKAGES_LIST: 'PACKAGES_LIST',
  PACKAGES_EDIT_PACKAGE: 'PACKAGES_EDIT_PACKAGES',
  PACKAGES_CREATE_PACKAGE: 'PACKAGES_CREATE_PACKAGES',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'packages',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        component: PackagesList,
        name: routeNames.PACKAGES_LIST,
      },
      {
        path: 'create',
        component: PackagesCreate,
        name: routeNames.PACKAGES_CREATE_PACKAGE,
      },
      {
        path: ':packageId',
        props: true,
        name: routeNames.PACKAGES_EDIT_PACKAGE,
        component: PackagesEdit,
      },
    ],
  },
]

export default routes
