export const Section = {
  USER: 'user',
  ACCOUNT_SETTINGS: 'account-settings',
  DASHBOARD: 'dashboard',
  INTEGRATIONS: 'integrations',
  SALES_CHANNELS: 'sales-channels',
  ADMINISTRATION: 'administration',
  FINANCES: 'finances',
  PRODUCTS: 'products',
  REPORTS: 'reports',
  ADMIN: 'admin',
  HELP: 'help',
} as const

export type SectionType = keyof typeof Section
export type SectionTypeValue = (typeof Section)[SectionType]
