import type { PortalPermissionValue } from '@/shared/constants/permissions'
import { useAuthStore } from '@/stores/auth'

/**
 * A composable version of the Permissions mixin.
 * Provides utility function to check if the current user has or lacks a specific permission.
 */

export const usePermissions = () => {
  const authStore = useAuthStore()

  const hasPermission = (permission: PortalPermissionValue): boolean => {
    return authStore.hasPermission(permission)
  }
  const missingPermission = (permission: PortalPermissionValue): boolean => {
    return !hasPermission(permission)
  }
  const hasOneOfPermissions = (permissions: PortalPermissionValue[]): boolean => {
    return permissions.reduce((result, permission) => result || hasPermission(permission), false)
  }
  return {
    hasPermission,
    hasOneOfPermissions,
    missingPermission,
  }
}
