import type { PortalTransaction } from '@/interfaces/PortalTransaction'

import { i18n } from '@/i18n'

export const localisedCategoryForTransaction = (transaction: PortalTransaction) =>
  i18n.global.t(`transactions.${transaction.attributes.category}`)

export const localisedStatusForTransaction = (transaction: PortalTransaction) => {
  if (transaction.attributes.status === 'none') {
    return ''
  }
  return i18n.global.t(`transactions.status.${transaction.attributes.status}`)
}
