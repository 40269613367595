<template>
  <FormGroup v-bind="{ topHelpText, optional, link, name, label, helpText, error }">
    <LuiToggle
      :id="name"
      :name="name"
      :label="label"
      :model-value="value"
      :error="error"
      :disabled="disabled"
      :text="
        text || {
          active: $t('global.yes'),
          inactive: $t('global.no'),
        }
      "
      :toggle-right="toggleRight"
      @update:model-value="$emit('update:value', $event)"
    />
  </FormGroup>
</template>

<script setup lang="ts">
import { LuiToggle } from '@loomispay/loomis-ui'

import type { FormGroupProps } from '@/interfaces/Frontend/FormGroup'

import FormGroup from '@/components/FormGroups/Block/BlockFormGroup.vue'
import { useBooleanFormGroup } from '@/composables/useBooleanFormGroup'

interface Props extends FormGroupProps {
  value?: boolean
  text?: {
    active: string
    inactive: string
  }
  toggleRight?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: false,
  text: undefined,
})

const emit = defineEmits<{
  'update:value': [boolean]
}>()

useBooleanFormGroup(props.value, emit)
</script>
