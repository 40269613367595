<template>
  <div>
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense>
          <!-- main content -->
          <component
            :is="Component"
            v-if="!showErrorModal"
          />
          <!-- loading state -->
          <template #fallback>
            <LoadingSpinner class="tw-py-12" />
          </template>
        </Suspense>
      </template>
    </RouterView>

    <EnterEmailToDownloadModal />
    <ConfirmModal />

    <GenericErrorModal
      v-if="showErrorModal"
      :error-message="errorMessage"
      @close="handleCloseErrorModal"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onErrorCaptured, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

import GenericErrorModal from '@/components/Layout/GenericErrorModal.vue'
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'
import ConfirmModal from '@/components/Modals/ConfirmModal.vue'
import EnterEmailToDownloadModal from '@/components/Modals/EnterEmailToDownloadModal.vue'
import { type SUPPORT_LOCALES_TYPE, i18n } from '@/i18n'
import { handleError } from '@/shared/errorService'
import { RouteNames } from '@/shared/routes'
import { useDevStore } from '@/stores/dev'

const router = useRouter()

const showErrorModal = ref(false)
const errorMessage = ref('')

const tempLocale = ref('')
const tempFallbackLocale = ref('')

const { showI18nCodes } = storeToRefs(useDevStore())

onMounted(() => {
  document.addEventListener('keyup', (event) => {
    if (showI18nCodes.value && event.key.toLowerCase() === 'alt') {
      if (tempLocale.value) {
        resetLocaleKey()
      } else {
        showTextKeys()
      }
    }
  })
})

const resetLocaleKey = () => {
  i18n.global.locale.value = tempLocale.value as SUPPORT_LOCALES_TYPE
  i18n.global.fallbackLocale.value = tempFallbackLocale.value as SUPPORT_LOCALES_TYPE
  tempLocale.value = ''
  tempFallbackLocale.value = ''
}
const showTextKeys = () => {
  tempLocale.value = i18n.global.locale.value
  tempFallbackLocale.value = i18n.global.fallbackLocale.value as string
  i18n.global.locale.value = 'no-such-locale' as SUPPORT_LOCALES_TYPE
  i18n.global.fallbackLocale.value = 'no-such-locale' as SUPPORT_LOCALES_TYPE
}

onErrorCaptured((e: any) => {
  handleError(e)
  showErrorModal.value = true
})

const handleCloseErrorModal = async () => {
  await router.push({ name: RouteNames.DASHBOARD })
  showErrorModal.value = false
  errorMessage.value = ''
}
</script>
