import type { RouteRecordRaw } from 'vue-router'

import Blank from '@/pages/Blank.vue'

const InvoicesConfigurationDetails = () => import('@/pages/Applications/Invoices/InvoicesAppConfigurationDetails.vue')
const CreateConfiguration = () => import('@/pages/Applications/Invoices/InvoicesAppCreateConfigurations.vue')
const ConfigurationList = () => import('@/pages/Applications/Invoices/InvoicesAppListConfigurations.vue')

export const routeNames = {
  INVOICES_APP_LIST_CONFIGURATIONS: 'INVOICES_LIST_CONFIGURATIONS',
  INVOICES_APP_CREATE_CONFIGURATION: 'INVOICES_CREATE_CONFIGURATION',
  INVOICES_APP_EDIT_CONFIGURATION: 'INVOICES_EDIT_CONFIGURATION',
  INVOICES_APP_CONFIGURATION_DETAILS: 'INVOICES_CONFIGURATION_DETAILS',
  INVOICES_APP_LIST_INVOICES: 'INVOICES_LIST_INVOICES',
  INVOICES_APP_COST_CENTERS: 'INVOICES_COST_CENTERS'
}

const routes: RouteRecordRaw[] = [
  {
    path: 'invoices/configurations',
    component: Blank,
    meta: { localeFilename: 'invoices' },
    children: [
      {
        path: '',
        name: routeNames.INVOICES_APP_LIST_CONFIGURATIONS,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.INVOICES_APP_CREATE_CONFIGURATION,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: InvoicesConfigurationDetails,
        name: routeNames.INVOICES_APP_CONFIGURATION_DETAILS,
        redirect: () => ({ name: routeNames.INVOICES_APP_LIST_INVOICES }),
        props: true,
        children: [
          {
            path: 'invoices',
            name: routeNames.INVOICES_APP_LIST_INVOICES,
            component: () => import('@/pages/Applications/Invoices/InvoicesAppListInvoices.vue'),
          },
          {
            path: 'edit',
            name: routeNames.INVOICES_APP_EDIT_CONFIGURATION,
            component: () => import('@/pages/Applications/Invoices/InvoicesAppEditConfiguration.vue'),
          },
          {
            path: 'cost-centers',
            name: routeNames.INVOICES_APP_COST_CENTERS,
            component: () =>
              import('@/pages/Applications/Invoices/InvoicesAppCostCentersList.vue'),
          }
        ],
      },
    ],
  },
]

export default routes
