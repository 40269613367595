<template>
  <KeepAlive>
    <InlineForm
      v-if="isLargeScreen"
      :key="`InlineForm-${merchantId}`"
      :value="value"
      :fields="fields"
      :disabled="disabled"
      @click="$event === 'clear' && $emit('clear')"
      @update:value="$emit('update:value', $event)"
      @submit="$emit('submit', $event)"
    >
      <slot />
    </InlineForm>
    <BottomSheetFilter
      v-else
      :key="`BottomSheetFilter-${merchantId}`"
      :value="value"
      :fields="fields"
      :disabled="disabled"
      @clear="onClear"
      @update:value="$emit('update:value', $event)"
      @submit="$emit('submit', $event)"
    />
  </KeepAlive>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@vueuse/core'
import { computed, inject, ref } from 'vue'

import type { PortalFilters } from '@/interfaces/PortalFilters'

import type { PortalId } from '@/types/portal'

import BottomSheetFilter from '@/components/Forms/BottomSheetFilter.vue'
import InlineForm from '@/components/Forms/InlineForm.vue'
import { useTailwindStore } from '@/stores/tailwind'

defineProps<{
  fields: PortalFilters
  value: Record<string, any>
  disabled?: boolean
}>()

const merchantId = inject<PortalId>('merchantId', '')

const emit = defineEmits<{
  'update:value': [Record<string, any>]
  'submit': [void]
  'clear': [void]
}>()

const breakpoints = ref(useBreakpoints(useTailwindStore().screens))

const isLargeScreen = computed(() => {
  return breakpoints.value?.lg
})

const onClear = () => {
  emit('clear')
  emit('update:value', {})
}
</script>
