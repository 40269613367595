import type PortalStore from '@/interfaces/PortalStore'
import type { PortalStoreAttributes } from '@/interfaces/PortalStore'

import type { BaseApiPromise } from '@/types/api'
import type { PortalId } from '@/types/portal'

import http from '@/http'

import '../interfaces/PortalProduct'

const endpoint = '/api/v2/stores'

export const fetchAllStoresForMerchant = (merchantId: PortalId) =>
  http.getList<PortalStore>(endpoint, { 'filter[merchant_id]': merchantId, 'page_size': 100 })

export const getStore = (id: PortalId): BaseApiPromise<PortalStore> => http.get(`${endpoint}/${id}`)

export const deleteStore = (id: PortalId) => http.delete(`${endpoint}/${id}`)

export const deleteStores = (ids: PortalId[]) => Promise.all(ids.map((id) => deleteStore(id)))

export const createStore = (store: Partial<PortalStoreAttributes>) =>
  http.post(endpoint, store) as BaseApiPromise<PortalStore>

export const updateStore = (store: PortalStore, data: Partial<PortalStoreAttributes>) => {
  /* Merge attributes with data to get all attributes, so we don't have to manually pass them all in the data object when calling this function to get the (weirdly) required attributes */
  data = { ...store.attributes, ...data }
  return http.put(`${endpoint}/${store.id}`, data) as BaseApiPromise<PortalStore>
}

export const uploadLogoForStore = ({
  storeId,
  logoFile,
}: {
  storeId: PortalId
  logoFile: File
}): BaseApiPromise<PortalStore> => {
  const formData = new FormData()
  formData.append('_method', 'put')
  formData.append('image', logoFile)
  return http.post(`/api/v2/stores/${storeId}/logo`, formData)
}

export const deleteLogoForStore = (storeId: PortalId): BaseApiPromise<PortalStore> =>
  http.delete(`${endpoint}/${storeId}/logo`)
