import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

import accountCardsRoutes, { routeNames as accountCardsAppRouteNames } from './applications/account-cards-routes'
import accountingRoutes, { routeNames as accountingAppRouteNames } from './applications/accounting-routes'
import accountsRoutes, { routeNames as accountsAppRouteNames } from './applications/accounts-routes'
import alexAndersenOlundRoutes, {
  routeNames as alexAndersenOlundRouteNames,
} from './applications/alex-andersen-olund-routes'
import bumpScreensRoutes, { routeNames as bumpScreensRouteNames } from './applications/bump-screens-routes'
import cityOfCopenhagenRoutes, {
  routeNames as cityOfCopenhagenRouteNames,
} from './applications/city-of-copenhagen-routes'
import complimentaRoutes, { routeNames as complimentaRouteNames } from './applications/complimenta-routes'
import economicRoutes, { routeNames as economicRouteNames } from './applications/economic-routes'
import elavonRoutes, { routeNames as elavonRouteNames } from './applications/elavon-routes'
import employeesRoutes, { routeNames as employeesRouteNames } from './applications/employees-routes'
import invoicesRoutes, { routeNames as invoicesAppRouteNames } from './applications/invoices-routes'
import invoicingRoutes, { routeNames as invoicingRouteNames } from './applications/invoicing-routes'
import ISSMenuPlanRoutes, { routeNames as issMenuPlanRouteNames } from './applications/iss-menu-plan-routes'
import meetingsRoutes, { routeNames as meetingsRouteNames } from './applications/meetings-routes'
import nexudusRoutes, { routeNames as nexudusRouteNames } from './applications/nexudus-routes'
import payWithCashRoutes, { routeNames as payWithCashRouteNames } from './applications/pay-with-cash-routes'
import pickupRoutes, { routeNames as pickupRouteNames } from './applications/pickup-routes'
import plandayRoutes, { routeNames as plandayRouteNames } from './applications/planday-routes'
import preparationRoutes, { routeNames as preparationRouteNames } from './applications/preparation-routes'
import printingRoutes, { routeNames as printingRouteNames } from './applications/printing-routes'
import retailRoutes, { routeNames as retailRouteNames } from './applications/retail-routes'
import selfServiceRoutes, { routeNames as selfServiceRouteNames } from './applications/self-service-routes'
import skatteverketRoutes, { routeNames as skatteverketRouteNames } from './applications/skatteverket-routes'
import stockRoutes, { routeNames as stockRouteNames } from './applications/stock-routes'
import surveyRoutes, { routeNames as surveyRouteNames } from './applications/survey-routes'
import swishRoutes, { routeNames as swishRouteNames } from './applications/swish-routes'
import tablesRoutes, { routeNames as tablesApplicationRouteNames } from './applications/tables-routes'
import takeawayRoutes, { routeNames as takeawayRouteNames } from './applications/takeaway-routes'
import tippingRoutes, { routeNames as tippingRouteNames } from './applications/tipping-routes'
import webRoutes, { routeNames as webRouteNames } from './applications/web-routes'
import winbagRoutes, { routeNames as winbagRouteNames } from './applications/winbag-routes'

export const routeNames = {
  APPLICATIONS_LIST_APPLICATIONS: 'APPLICATIONS_LIST_APPLICATIONS',

  ...accountCardsAppRouteNames,
  ...accountingAppRouteNames,
  ...accountsAppRouteNames,
  ...alexAndersenOlundRouteNames,
  ...bumpScreensRouteNames,
  ...cityOfCopenhagenRouteNames,
  ...complimentaRouteNames,
  ...economicRouteNames,
  ...elavonRouteNames,
  ...employeesRouteNames,
  ...invoicesAppRouteNames,
  ...invoicingRouteNames,
  ...issMenuPlanRouteNames,
  ...meetingsRouteNames,
  ...nexudusRouteNames,
  ...payWithCashRouteNames,
  ...pickupRouteNames,
  ...plandayRouteNames,
  ...preparationRouteNames,
  ...printingRouteNames,
  ...retailRouteNames,
  ...selfServiceRouteNames,
  ...skatteverketRouteNames,
  ...stockRouteNames,
  ...surveyRouteNames,
  ...swishRouteNames,
  ...tablesApplicationRouteNames,
  ...takeawayRouteNames,
  ...tippingRouteNames,
  ...webRouteNames,
  ...winbagRouteNames,
}

const routes: RouteRecordRaw[] = [
  {
    path: 'applications',
    component: () => import('@/pages/Blank.vue'),
    meta: {
      section: Section.INTEGRATIONS,
    },
    children: [
      {
        path: '',
        component: () => import('@/pages/Applications/ListApplications.vue'),
        name: routeNames.APPLICATIONS_LIST_APPLICATIONS,
      },
      ...accountCardsRoutes,
      ...accountsRoutes,
      ...accountingRoutes,
      ...alexAndersenOlundRoutes,
      ...bumpScreensRoutes,
      ...cityOfCopenhagenRoutes,
      ...complimentaRoutes,
      ...economicRoutes,
      ...elavonRoutes,
      ...employeesRoutes,
      ...invoicesRoutes,
      ...invoicingRoutes,
      ...ISSMenuPlanRoutes,
      ...meetingsRoutes,
      ...nexudusRoutes,
      ...payWithCashRoutes,
      ...pickupRoutes,
      ...plandayRoutes,
      ...preparationRoutes,
      ...printingRoutes,
      ...retailRoutes,
      ...selfServiceRoutes,
      ...skatteverketRoutes,
      ...stockRoutes,
      ...surveyRoutes,
      ...swishRoutes,
      ...tablesRoutes,
      ...takeawayRoutes,
      ...tippingRoutes,
      ...webRoutes,
      ...winbagRoutes,
    ],
  },
]

export default routes
