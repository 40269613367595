import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  ALEX_ANDERSEN_OLUND_CONFIGURATIONS_LIST: 'ALEX_ANDERSEN_OLUND_CONFIGURATIONS_LIST',
  ALEX_ANDERSEN_OLUND_CONFIGURATION_EDIT: 'ALEX_ANDERSEN_OLUND_CONFIGURATION_EDIT',
  ALEX_ANDERSEN_OLUND_CONFIGURATION_CREATE: 'ALEX_ANDERSEN_OLUND_CONFIGURATION_CREATE',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'alex-andersen-olund/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.ALEX_ANDERSEN_OLUND_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/AlexAndersenOlund/Configurations/List.vue'),
      },
      {
        path: 'create',
        name: routeNames.ALEX_ANDERSEN_OLUND_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/AlexAndersenOlund/Configurations/Create.vue'),
      },
      {
        path: ':configurationId',
        name: routeNames.ALEX_ANDERSEN_OLUND_CONFIGURATION_EDIT,
        component: () => import('@/pages/Applications/AlexAndersenOlund/Configurations/Edit.vue'),
        props: true,
      },
    ],
  },
]

export default routes
