import posthog from 'posthog-js'

import type { PortalUserSubject } from '@/interfaces/PortalUser'

import { useAuthStore } from '@/stores/auth'

let allowedCookieCategories: string[] = []

// Cookie Categories (https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9)
export enum CookieCategories {
  StrictlyNecessary = 'C0001',
  Performance = 'C0002',
  Functional = 'C0003',
  Targeting = 'C0004',
  SocialMedia = 'C0005',
  Video = 'VID1',
}

export const initiateAnalytics = () => {
  const apiKey = import.meta.env.VITE_POSTHOG_API_KEY
  if (apiKey) {
    posthog.init(apiKey, {
      api_host: 'https://eu.posthog.com',
      capture_pageview: false, //We manually trigger pageview events
      autocapture: false,
      opt_in_site_apps: true,
    })

    // Toogle debug mode
    posthog.debug(import.meta.env.VITE_POSTHOG_DEBUG === 'true')
  }

  // Listen for OneTrust cookie consent changes - this fires when the user changes their cookie preferences or when the
  // page is loaded
  window.addEventListener('OneTrustGroupsUpdated', (event: any) => {
    allowedCookieCategories = event.detail
    refreshAnalytics()
  })
}

const postHogRelatedCookiesAllowed = (): boolean => {
  // We check for both Performance and Functional cookies, as we have manually set PostHog cookies to be in the
  // Functional category in OneTrust (reason is that we transmit the user id to PostHog, which is considered personal
  // data by GDPR).
  // It doesn't seem that OneTrust has registered the change in the cookie categories though, so for now we're checking
  // for both and treating them as the same (i.e. if either is allowed, we consider PostHog cookies to be allowed)
  return (
    allowedCookieCategories.includes(CookieCategories.Performance) ||
    allowedCookieCategories.includes(CookieCategories.Functional)
  )
}

const refreshAnalytics = () => {
  if (!postHogRelatedCookiesAllowed()) {
    posthog.opt_out_capturing()
  } else {
    posthog.opt_in_capturing()
    // If the user is already logged in, identify them in PostHog
    const { user } = useAuthStore()
    if (user) {
      identifyUser(user)
    }
  }
}

export const sendPageViewEvent = (page: string): void => {
  posthog.capture('$pageview', {
    page,
    $ip: null,
  })
}

export const sendEvent = (event: string, properties?: Record<string, any>): void => {
  posthog.capture(event, properties)
}

export const identifyUser = (user: PortalUserSubject): void => {
  const { isInternalUser } = useAuthStore()
  posthog.setPersonProperties({ isInternalUser })

  // Should be a check for Functional cookies, but we're checking for Performance as well for now (see comment in
  // postHogRelatedCookiesAllowed)
  if (postHogRelatedCookiesAllowed()) {
    posthog.identify(String(user.id))
  }
}

export const resetAnalytics = (): void => {
  posthog.reset()
}
