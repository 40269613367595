import { type RemovableRef, useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import type PortalMerchant from '@/interfaces/PortalMerchant'
import type PortalStore from '@/interfaces/PortalStore'

import type { PortalId } from '@/types/portal'

import * as merchantsApi from '@/api/merchants-api'
import { fetchAllStoresForMerchant } from '@/api/stores-api'
import { RouteNames } from '@/shared/routes'
import { useAuthStore } from '@/stores/auth'

export const useMerchantsStore = defineStore('merchants', () => {
  const route = useRoute()
  const router = useRouter()

  watch(
    () => route.params.selectedMerchantId,
    async (id: any) => {
      if (id && route.meta.auth) {
        try {
          await updateSelectedMerchantId(id as PortalId)
        } catch (error) {
          router.replace({ name: RouteNames.NOT_FOUND })
        }
      }
    },
    { immediate: true },
  )

  // ** STATE **
  const merchantsById = ref<Record<string, PortalMerchant>>({})
  const selectedMerchantId = ref<RemovableRef<PortalId | null>>(useStorage('selectedMerchantId', null))
  const loading = ref(false)
  const meta = ref<any | null>(null)
  const storesForMerchant = ref<Record<string, PortalStore[]>>({})

  // ** ACTIONS **
  const setMerchants = (merchants: any[]) => {
    merchantsById.value = {
      ...merchantsById.value,
      ...merchants.reduce((acc, merchant) => {
        acc[merchant.id] = merchant
        return acc
      }, {}),
    }
  }

  /*
   * Fetches the merchants for the current user.
   * If there's a selected merchant, it will fetch that one too.
   */
  const fetchMerchants = async () => {
    loading.value = true
    try {
      const { data } = await merchantsApi.fetchMerchants()
      const merchants = data.data

      setMerchants(merchants)
      meta.value = data.meta

      // If there's no selected merchant, select the first one - else make sure the selected merchant is fetched
      await updateSelectedMerchantId(selectedMerchantId.value || merchants[0].id)

      return Object.values(merchantsById.value)
    } finally {
      loading.value = false
    }
  }

  const fetchMerchantWithId = async (merchantId: PortalId) => {
    const { data } = await merchantsApi.fetchMerchant(merchantId)
    const merchant = data.data
    setMerchants([merchant])
    return merchant
  }

  const updateSelectedMerchantId = async (merchantId: PortalId) => {
    if (!merchantsById.value[merchantId]) {
      try {
        await fetchMerchantWithId(merchantId)
      } catch (error) {
        // User probably doesn't have access to the merchant (merchantId might be from a previous session with another
        // user), default to the first one
        const firstMerchant = Object.values(merchantsById.value)[0]
        if (firstMerchant) {
          selectedMerchantId.value = firstMerchant.id
          return
        } else {
          throw new Error('No merchants available')
        }
      }
    }

    selectedMerchantId.value = String(merchantId)
  }

  const fetchStoresForMerchant = async (merchantId: PortalId) => {
    if (storesForMerchant.value[merchantId]) {
      return storesForMerchant.value[merchantId]
    }
    const stores = await fetchAllStoresForMerchant(merchantId)
    storesForMerchant.value[merchantId] = stores
    return stores
  }

  const resetMerchantsStore = () => {
    merchantsById.value = {}
    meta.value = null
  }

  const { user } = useAuthStore()

  // ** GETTERS **
  const anyMerchantHasAccessToFeature = (feature: string) =>
    computed(
      () =>
        user?.is_global_admin ||
        Object.values(merchantsById.value).some((merchant) => merchant.attributes.features.includes(feature)),
    )
  const selectedMerchantHasAccessToFeature = (feature: string) =>
    computed(() => selectedMerchant.value?.attributes.features.includes(feature))

  const selectedMerchant = computed(() =>
    selectedMerchantId.value ? merchantsById.value[selectedMerchantId.value] : null,
  )

  const selectedMerchantIsFromCountry = (country: string) => selectedMerchantCountry.value === country

  const selectedMerchantCountry = computed(() => selectedMerchant.value?.attributes.country)
  const merchantCount = computed(() => meta.value?.total || 0)
  const multipleMerchants = computed(() => meta.value?.total > 1)
  const allMerchants = computed(() => Object.values(merchantsById.value))
  const merchantsLoaded = computed(() => meta.value !== null)
  const merchantCurrency = computed(() => selectedMerchant.value?.attributes.currency)

  return {
    merchantsById,
    selectedMerchantId,
    loading,
    meta,
    storesForMerchant,
    setMerchants,
    fetchMerchants,
    fetchMerchantWithId,
    fetchStoresForMerchant,
    resetMerchantsStore,
    anyMerchantHasAccessToFeature,
    selectedMerchantHasAccessToFeature,
    selectedMerchant,
    selectedMerchantCountry,
    merchantCount,
    multipleMerchants,
    allMerchants,
    merchantsLoaded,
    merchantCurrency,
    selectedMerchantIsFromCountry,
  }
})
