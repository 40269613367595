import Bugsnag, { type Event } from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import type { App, Plugin } from 'vue'

import { useAuthStore } from '@/stores/auth'

import { version } from '../../package.json'

const onError = (event: Event) => {
  // Ignore axios errors
  if (event.originalError?.isAxiosError) {
    return false
  }

  const authStore = useAuthStore()

  event.setUser(authStore.user ? String(authStore.user.id) : undefined)
}

const BugsnagPlugin: Plugin = {
  install(app: App) {
    const bugsnagApiKey = import.meta.env.VITE_BUGSNAG_KEY
    if (bugsnagApiKey) {
      Bugsnag.start({
        apiKey: bugsnagApiKey,
        plugins: [new BugsnagPluginVue()],
        releaseStage: import.meta.env.VITE_SERVER_ENV,
        appVersion: version,
        onError: onError,
      })
      const bugsnagVue = Bugsnag.getPlugin('vue')
      app.use(bugsnagVue!)
    }
  },
}

export default BugsnagPlugin
