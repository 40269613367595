import type { RouteRecordRaw } from 'vue-router'

const PayWithCashMachinesList = () => import('@/pages/Applications/PayWithCash/PayWithCashMachinesList.vue')

export const routeNames = {
  PAY_WITH_CASH_MACHINES_LIST: 'PAY_WITH_CASH_MACHINES_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'pay-with-cash',
    name: routeNames.PAY_WITH_CASH_MACHINES_LIST,
    component: PayWithCashMachinesList,
  },
]

export default routes
