/**
 * Formats a credit card number from @panStart and @panMax with spaces every 4 chars
 *
 * @param panStart - First part of the card number
 * @param panEnd - Last part of the card number
 * @param placeholder - The character to repeat between start and end
 * @param totalLength - Number of characters in the returned card number
 * @return {string}
 */

export const formatCardNumberFromPan = (
  panStart: string,
  panEnd: string,
  placeholder: string = '*',
  totalLength: number = 16,
): string => {
  const panLength = panStart.length + panEnd.length
  const repeatLength = Math.max(totalLength - panLength, 0)
  const cardNo = `${panStart}${placeholder.repeat(repeatLength)}${panEnd}`

  return cardNo.match(/.{1,4}/g)?.join(' ') || ''
}
