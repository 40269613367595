import { storeToRefs } from 'pinia'

import { usePermissions } from '@/composables/usePermissions'
import { useAuthStore } from '@/stores/auth'
import { useMerchantsStore } from '@/stores/merchants'

/**
 * Gets the mavenoid payload.
 * If the user is logged in, it includes user data. If not it wipes the current user data.
 * Email/Name are capitalized on purpose, since that's how the mavenoid configuration expects them.
 */
const getMavenoidPayload = async () => {
  const { phone, email, name, user, language } = useAuthStore()
  const baseObject = {
    event: 'troubleshooter-open',
    productId: 5425529,
    orgName: 'Loomis-Pay',
  }
  if (!user) {
    return {
      ...baseObject,
      initFormData: {
        'Email': '',
        'Name': '',
        'phone': '',
        'roles': '',
        'permissions': '',
        'org-number': '',
        'company-number': '',
      },
    }
  }
  const { allMerchants } = storeToRefs(useMerchantsStore())
  const { fetchMerchants } = useMerchantsStore()
  const { hasPermission } = usePermissions()
  if (!allMerchants.value.length && hasPermission('show-merchants')) {
    await fetchMerchants()
  }
  return {
    ...baseObject,
    initFormData: {
      'phone': phone,
      'Email': email,
      'Name': name,
      'roles': user?.roles.join(', '),
      'permissions': user?.permissions.map((permission) => permission.name).join(', '),
      'org-number': allMerchants.value.map((merchant: any) => merchant.attributes.cvr).join(', '),
      'company-number': allMerchants.value.map((merchant: any) => merchant.attributes.name).join(', '),
    },
    defaultLang: language,
  }
}

export const initiateOrUpdateMavenoid = async () => {
  /* @ts-ignore */
  const mavenoid: any = window['mavenoid']

  if (!mavenoid) {
    return
  }
  /**
   * This pushes user info to mavenoid and is triggered when the base-layout is mounted or the user is logged out.
   */
  mavenoid.push(await getMavenoidPayload())
}
