import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Elavon/Configurations/ElavonConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Elavon/Configurations/ElavonConfigurationCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Elavon/Configurations/ElavonConfigurationsList.vue')
const ElavonStoresList = () => import('@/pages/Applications/Elavon/ElavonStores/ElavonStoresList.vue')
const ElavonStoreContainer = () => import('@/pages/Applications/Elavon/ElavonStores/ElavonStoreContainer.vue')
const ShowElavonStore = () => import('@/pages/Applications/Elavon/ElavonStores/ElavonStoreDetails.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ELAVON_CONFIGURATIONS_LIST: 'ELAVON_CONFIGURATIONS_LIST',
  ELAVON_CONFIGURATION_SHOW: 'ELAVON_CONFIGURATION_SHOW',
  ELAVON_CONFIGURATION_CREATE: 'ELAVON_CONFIGURATION_CREATE',
  ELAVON_CONFIGURATION_STORE_SHOW: 'ELAVON_CONFIGURATION_STORE_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'elavon/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.ELAVON_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.ELAVON_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.ELAVON_CONFIGURATION_SHOW,
            component: ElavonStoresList,
          },
          {
            path: 'elavon-stores/:elavonStoreId',
            component: ElavonStoreContainer,
            props: true,
            children: [
              {
                name: routeNames.ELAVON_CONFIGURATION_STORE_SHOW,
                path: '',
                component: ShowElavonStore,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
