<template>
  <menu
    ref="overlay"
    class="menu-overlay-section tw-bg-gray-2 tw-shadow-1-dark tw-overflow-y-scroll tw-absolute tw-left-0 tw-bottom-0 tw-rounded tw-z-20"
  >
    <div :style="overlayStyle">
      <li
        v-for="(link, index) in links"
        :key="index"
      >
        <MenuButton
          v-if="link.display === undefined || link.display"
          class="tw-my-2"
          active-class="tw-bg-gray-white tw-text-gray-black hover:tw-bg-gray-7 focus:tw-text-gray-black focus:tw-outline-gray-8"
          :to="link.to"
          :align-missing-icon="false"
          :icon="link.icon"
          :text="link.text"
          :class="link.class"
          @click="onMenuButtonClick(link)"
        />
      </li>
    </div>
  </menu>
</template>

<script setup lang="ts">
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import { computed, onMounted, ref } from 'vue'

import type { LinkSectionLink } from '@/interfaces/Frontend/Navigation'

import MenuButton from '@/components/Layout/Navigation/MenuButton.vue'

const props = defineProps<{
  links: LinkSectionLink[]
  buttonElement: HTMLElement
}>()

const emit = defineEmits<{
  (event: 'close'): void
}>()

const overlay = ref<HTMLElement | null>(null)

onMounted(() => {
  createPopper(props.buttonElement, overlay.value!, {
    placement: 'top',
    strategy: 'fixed',
  })
})

onClickOutside(
  overlay,
  () => {
    emit('close')
  },
  {
    ignore: [props.buttonElement],
  },
)

const onMenuButtonClick = (link: LinkSectionLink) => {
  if (link.click) {
    link.click()
  }
  emit('close')
}

// Make a computed overlayStyle that sets the height of the menu to the available space from the top of props.buttonElement to the top of the window - 32px (for some padding on the menu)
const overlayStyle = computed(() => {
  if (props.buttonElement) {
    const buttonRect = props.buttonElement.getBoundingClientRect()
    return {
      maxHeight: `calc(${buttonRect.top}px - 32px)`,
    }
  }
  return {}
})
</script>

<style lang="scss" scoped>
@use '@/sass/variables' as *;

.menu-overlay-section {
  width: calc($width-sidebar-expanded-mobile - 32px);
  @media (min-width: 1024px) {
    width: calc($width-sidebar-expanded - 32px);
  }
}
</style>
