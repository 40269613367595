<template>
  <menu
    ref="dropdown"
    class="menu-dropdown"
  >
    <h3
      role="presentation"
      class="menu-dropdown__label"
    >
      {{ label }}
    </h3>
    <li
      v-for="(link, index) in items"
      :key="index"
    >
      <component
        :is="link.to ? AppLink : 'button'"
        v-if="link.display === undefined || link.display"
        class="menu-dropdown__link"
        :title="link.text"
        :to="link.to"
        :class="link.class"
        @click="onClick(link)"
      >
        <span class="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis">{{ link.text }}</span>
      </component>
    </li>
  </menu>
</template>

<script setup lang="ts">
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import { onMounted, ref } from 'vue'

import type { LinkSectionLink } from '@/interfaces/Frontend/Navigation'

import AppLink from '@/components/Utilities/AppLink.vue'

const props = defineProps<{
  label: string
  items: LinkSectionLink[]
  buttonElement: HTMLElement
}>()

const dropdown = ref<HTMLElement | null>(null)

onClickOutside(
  dropdown,
  () => {
    emit('close')
  },
  {
    ignore: [props.buttonElement],
  },
)

const emit = defineEmits<{
  (event: 'close'): void
}>()

onMounted(() => {
  createPopper(props.buttonElement, dropdown.value!, {
    placement: 'right-start',
    strategy: 'fixed',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 32],
        },
      },
    ],
  })
})

const onClick = (item: LinkSectionLink) => {
  if (item.click) {
    item.click()
  }
  emit('close')
}
</script>

<style lang="scss" scoped>
@use '@/sass/variables' as *;

.menu-dropdown {
  $self: &;
  display: block;
  position: absolute;
  z-index: 2;
  width: 220px;
  padding: 16px 4px;
  border-radius: 4px;
  left: calc($width-sidebar + 16px);
  @apply tw-shadow-1 tw-bg-gray-white tw-max-h-screen tw-overflow-y-auto;

  &__label {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.002em;
    padding: 0 10px;
    margin: 0 0 8px;
    @apply tw-text-muted;
  }

  &__link {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 16px 10px;
    cursor: pointer;
    border-radius: 4px;
    transition:
      background-color 150ms ease-in-out,
      color 150ms ease-in-out;
    @apply tw-text-gray-1 hover:tw-bg-gray-13 hover:tw-text-gray-black focus:tw-text-gray-black;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }
  }
}

.router-link-exact-active {
  @apply tw-bg-gray-black tw-text-gray-white hover:tw-bg-gray-black hover:tw-text-gray-white;
}
</style>
