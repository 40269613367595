<template>
  <span class="tw-inline-flex tw-items-center tw-tabular-nums">
    <LuiIcon
      v-if="card"
      :name="cardIcon"
      class="tw-mr-2"
    />
    <span v-if="digits"> {{ placeholder }} {{ digits }} </span>
  </span>
</template>
<script lang="ts" setup>
import { type LuiIconName } from '@loomispay/loomis-ui'
import { computed } from 'vue'

import { CardType } from '@/shared/constants/cards'

const props = withDefaults(
  defineProps<{
    card?: CardType
    placeholder?: string
    digits?: string | number
  }>(),
  {
    card: undefined,
    placeholder: '••••',
  },
)

const cardIcon = computed<LuiIconName>(() => {
  switch (props.card) {
    case CardType.VISA:
      return 'payments-visa'
    case CardType.MasterCard:
      return 'payments-mastercard'
    case CardType.Maestro:
      return 'payments-maestro'
    case CardType.AMEX:
      return 'payments-amex'
    case CardType.Dankort:
      return 'payments-dankort'
    default:
      return 'payments-generic-filled'
  }
})
</script>
