<template>
  <div
    class="tw-relative tw-px-5 tw-py-3 tw-border tw-border-solid tw-border-transparent tw-rounded"
    :class="{
      'tw-bg-blue-14': props.level === 'info',
      'tw-bg-green-14': props.level === 'success',
      'tw-bg-yellow-14': props.level === 'warning',
      'tw-bg-red-14 tw-text-red-6': props.level === 'danger',
    }"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  level: 'info' | 'success' | 'warning' | 'danger'
}>()
</script>
