import { useConfirmModalStore } from '@/stores/confirm-modal-store'

export default {
  /**
   * Shows a confirmation dialog
   * 
   * @param text
   * @param callback
   * @param cancelCallback
   */
  confirm(text: string, confirmCallback: () => void, cancelCallback?: () => void) {
    const { showConfirmModal } = useConfirmModalStore()
    showConfirmModal({
      text,
      confirmCallback,
      cancelCallback,
    })
  },

  alert(text: string, confirmCallback?: () => void) {
    const { showConfirmModal } = useConfirmModalStore()
    showConfirmModal({
      text,
      confirmCallback: confirmCallback || (() => {}),
      isAlert: true,
    })
  },
}
