import type { RouteRecordRaw } from 'vue-router'

const CashDeposits = () => import('@/pages/Cash/CashDeposits.vue')

export const routeNames = {
  CASH_DEPOSITS: 'CASH_DEPOSITS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'cash-deposits',
    name: routeNames.CASH_DEPOSITS,
    component: CashDeposits,
    meta: {
      section: 'finance',
    },
  },
]

export default routes
