import Bugsnag from '@bugsnag/js'
import { LoomisUIPlugin, LuiButton, LuiIcon } from '@loomispay/loomis-ui'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import updateLocal from 'dayjs/plugin/updateLocale'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import type { Plugin } from 'vue'

import App from '@/App.vue'
import '@/bootstrap'
import { BASE_LOCALE, i18n, loadLanguageAsync } from '@/i18n'
import BugsnagPlugin from '@/plugins/bugsnag'
import router from '@/router'
import '@/sass/app.scss'
import { initiateAnalytics } from '@/shared/ProductAnalyticsService'
import { useAuthStore } from '@/stores/auth'

dayjs.extend(updateLocal)
dayjs.extend(advancedFormat)
dayjs.extend(isoWeek)
dayjs.updateLocale('en', {
  weekStart: 1,
})

initiateAnalytics()

const pinia = createPinia()

createApp(App)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(FloatingVue, {
    themes: {
      'image-tooltip': {
        $extend: 'tooltip',
      },
    },
  })
  .use(BugsnagPlugin)
  .use(LoomisUIPlugin as unknown as Plugin)
  // Register broadly used components so we don't have to import them in every component
  .component('LuiButton', LuiButton)
  .component('LuiIcon', LuiIcon)
  .mount('#app')

const authStore = useAuthStore()
const userLocale = authStore.user?.language || BASE_LOCALE
if (userLocale !== BASE_LOCALE) {
  if (Bugsnag.isStarted()) {
    Bugsnag.leaveBreadcrumb('Initialising app, loading user locale', {
      userLocale,
      userId: authStore.user?.id,
    })
  }
  loadLanguageAsync(userLocale)
}
