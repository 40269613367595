import { defineStore } from 'pinia'

const lsShowI18nCodes = localStorage.getItem('showI18nCodes') === 'true'

export const useDevStore = defineStore('dev', {
  state: () => ({
    showI18nCodes: lsShowI18nCodes,
  }),
  actions: {
    updateShowI18nCodes(show: boolean) {
      this.showI18nCodes = show
      localStorage.setItem('showI18nCodes', String(show))
    },
  },
})
