<template>
  <Teleport to="#app">
    <!--The lui modal class doesn't apply any styling, but is used to identify the modal and add/remove the no scroll styling on the body depending on it's presence-->
    <div class="lui-modal">
      <div
        :class="active ? 'tw-bg-opacity-black-12' : 'tw-bg-transparent'"
        class="tw-fixed tw-z-[960] tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-backface-hidden tw-transition-colors tw-padding-4 tw-overflow-x-hidden tw-overflow-y-auto"
        @click.self="close"
      >
        <div
          class="tw-relative tw-width-auto tw-m-4 tw-mb-20 tw-flex tw-items-center tw-justify-center tw-min-h-[calc(100%-6rem)] tw-pointer-events-none"
        >
          <FocusTrap
            :active="active"
            :escape-deactivates="false"
          >
            <div
              :class="[
                active ? 'tw-translate-y-0 tw-opacity-100' : 'tw-translate-y-[-2rem] tw-opacity-0',
                size === 'small' ? 'tw-w-100' : 'tw-w-[664px]',
              ]"
              role="dialog"
              :aria-modal="true"
              :tabindex="-1"
              class="tw-pointer-events-auto tw-transition-all tw-relative tw-flex tw-flex-col tw-p-6 tw-rounded-2xl tw-bg-white"
            >
              <div
                v-if="title || description || $slots.title || $slots.titleRight"
                class="tw-mb-4"
              >
                <div class="tw-flex tw-justify-between tw-grow-0 tw-w-full tw-text-ellipsis tw-items-start">
                  <slot name="title">
                    <h4 class="tw-text-xl lg:tw-text-2xl tw-grow-0 tw-w-full tw-text-ellipsis tw-overflow-hidden">
                      {{ title }}
                    </h4>
                  </slot>
                  <slot name="titleRight">
                    <LuiButton
                      v-if="!persistent"
                      class="tw-mt-2.5"
                      type="button"
                      emphasis="low"
                      icon="close"
                      size="small"
                      :aria-label="$t('global.close')"
                      @click="close"
                    />
                  </slot>
                </div>
                <div
                  v-if="description"
                  class="tw-font-lg tw-whitespace-pre-wrap tw-mt-1.5"
                >
                  {{ description }}
                </div>
              </div>
              <div class="tw-flex-grow">
                <slot name="body-and-footer">
                  <div>
                    <slot />
                  </div>

                  <div class="tw-mt-6">
                    <slot name="footer">
                      <div class="tw-flex tw-justify-end">
                        <LuiButton
                          emphasis="low"
                          @click="close"
                        >
                          {{ $t('global.close') }}
                        </LuiButton>
                      </div>
                    </slot>
                  </div>
                </slot>
              </div>
            </div>
          </FocusTrap>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { FocusTrap } from 'focus-trap-vue'
import { onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'large'
    title?: string
    description?: string
    persistent?: boolean
  }>(),
  {
    size: 'small',
    title: undefined,
    description: undefined,
  },
)

const emit = defineEmits<{
  close: []
}>()

const active = ref(false)

const body = document.querySelector('body')
body?.classList.add('no-scroll')
setTimeout(() => {
  active.value = true
}, 1)

const closeOnEscape = ($event: KeyboardEvent) => {
  if ($event.key === 'Escape' && !props.persistent) {
    close()
  }
}

onMounted(() => {
  document.addEventListener('keyup', closeOnEscape)
})

onBeforeUnmount(() => {
  document.removeEventListener('keyup', closeOnEscape)
})

onUnmounted(() => {
  if (!document.querySelector('.lui-modal')) {
    const body = document.querySelector('body')
    body?.classList.remove('no-scroll')
  }

  active.value = false
})
const close = () => {
  if (!props.persistent) {
    active.value = false
    setTimeout(() => {
      emit('close')
    }, 150)
  }
}
</script>
