<template>
  <button
    type="button"
    class="tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12"
    :aria-label="$t('navigation.toggleNavigation')"
    @click="toggleNavigation"
  >
    <LuiIcon
      class="lg:tw-hidden"
      name="close"
    />
    <LuiIcon
      :key="expanded"
      class="tw-hidden lg:tw-block"
      :height="32"
      :width="32"
      :name="expanded ? 'menu-resize-left-alt' : 'menu-resize-right-alt'"
    />
  </button>
</template>

<script>
import { mapActions, mapState } from 'pinia'

import { useNavigationStore } from '@/stores/navigation'

export default {
  computed: {
    ...mapState(useNavigationStore, {
      expanded: (store) => store.expanded,
    }),
  },
  methods: {
    ...mapActions(useNavigationStore, ['toggleNavigation']),
  },
}
</script>
