<template>
  <LuiIcon
    name="chevron-right"
    class="tw-transition-transform tw-pointer-events-none"
    :class="rotationClass"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

/**
 * A simple reusable chevron, with rotation logic and transitions.
 */

const props = withDefaults(
  defineProps<{
    isExpanded: boolean
    closedDirection?: 'right' | 'down'
    openDirection?: 'up' | 'down'
  }>(),
  {
    closedDirection: 'down',
    openDirection: 'up',
  },
)

const rotationClass = computed(() => {
  if (props.isExpanded) {
    return props.openDirection === 'down' ? 'tw-rotate-90' : '-tw-rotate-90'
  }
  return props.closedDirection === 'down' ? 'tw-rotate-90' : ''
})
</script>
