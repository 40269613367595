import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

const DeliveryStoreMappingSetup = () => import('@/pages/Delivery/DeliveryStoreMappingSetup.vue')
const DeliveryStoreMappingEdit = () => import('@/pages/Delivery/DeliveryStoreMappingEdit.vue')
const DeliveryStoreMappingsList = () => import('@/pages/Delivery/DeliveryStoreMappingsList.vue')

export const routeNames = {
  DELIVERY_STORE_MAPPINGS_LIST: 'DELIVERY_STORE_MAPPINGS_LIST',
  DELIVERY_STORE_MAPPING_SETUP: 'DELIVERY_STORE_MAPPING_SETUP',
  DELIVERY_STORE_MAPPING_EDIT: 'DELIVERY_STORE_MAPPING_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'delivery',
    meta: {
      merchant: true,
      section: Section.SALES_CHANNELS,
    },
    children: [
      {
        path: '',
        name: routeNames.DELIVERY_STORE_MAPPINGS_LIST,
        component: DeliveryStoreMappingsList,
      },
      {
        path: 'store-mappings',
        children: [
          {
            path: 'setup/:storeId',
            name: routeNames.DELIVERY_STORE_MAPPING_SETUP,
            props: true,
            component: DeliveryStoreMappingSetup,
          },
          {
            path: ':storeMappingId',
            name: routeNames.DELIVERY_STORE_MAPPING_EDIT,
            props: true,
            component: DeliveryStoreMappingEdit,
          },
        ],
      },
    ],
  },
]

export default routes
