import TailwindForms from '@tailwindcss/forms'
import type { Config } from 'tailwindcss'
import plugin from 'tailwindcss/plugin'

const backfaceVisibility = plugin(({ addUtilities }) => {
  addUtilities({
    '.backface-visible': {
      'backface-visibility': 'visible',
    },
    '.backface-hidden': {
      'backface-visibility': 'hidden',
    },
  })
})

const highContrastBw = plugin(({ addUtilities }) => {
  addUtilities({
    '.high-contrast-bw': {
      filter: 'grayscale(100%) contrast(10000%)',
    },
  })
})

const config: Config = {
  prefix: 'tw-',
  content: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}', './node_modules/@loomispay/**/*.js'],
  theme: {
    extend: {
      fontSize: {
        // Font-size: 9pt, line-height: 12pt
        '2xs': [
          '0.5625rem',
          {
            lineHeight: '1.33333333',
            letterSpacing: '-0.002em',
          },
        ],
        // Font-size: 12pt, line-height: 16pt
        'xs': [
          '0.75rem',
          {
            lineHeight: '1rem',
            letterSpacing: '-0.002em',
          },
        ],
        // Font-size: 14pt, line-height: 20pt
        'sm': [
          '0.875rem',
          {
            lineHeight: '1.25rem',
            letterSpacing: '-0.002em',
          },
        ],
        // Font-size: 16pt, line-height: 24pt
        'md': [
          '1rem',
          {
            lineHeight: '1.5rem',
            letterSpacing: '-0.002em',
          },
        ],
        // Font-size: 18pt, line-height: 24pt
        'lg': [
          '1.125rem',
          {
            lineHeight: '1.75rem',
            letterSpacing: '-0.002em',
          },
        ],
        // Font-size: 24pt, line-height: 32pt
        'xl': [
          '1.5rem',
          {
            lineHeight: '2rem',
            letterSpacing: '0',
          },
        ],
        // Font-size: 32pt, line-height: 40pt
        '2xl': [
          '2rem',
          {
            lineHeight: '2.5rem',
            letterSpacing: '0',
          },
        ],
        // Font-size: 40pt, line-height: 48pt
        '3xl': [
          '2.5rem',
          {
            lineHeight: '3rem',
            letterSpacing: '0',
          },
        ],
        // Font-size: 48pt, line-height: 56pt
        '4xl': [
          '3rem',
          {
            lineHeight: '3.5rem',
            letterSpacing: '0',
          },
        ],
        // Font-size: 80pt, line-height: 80pt
        '5xl': [
          '5rem',
          {
            lineHeight: '5rem',
            letterSpacing: '0',
          },
        ],
      },
      width: {
        30: '7.5rem',
        62: '15.5rem',
        100: '25rem',
        128: '32rem',
      },
      height: {
        30: '7.5rem',
        100: '25rem',
        128: '32rem',
      },
      boxShadow: {
        '1': '0px 4px 8px rgba(0, 0, 0, 0.2)',
        '2': '0px 4px 12px rgba(0, 0, 0, 0.2)',
        '3': '0px 12px 32px rgba(0, 0, 0, 0.2)',
        '4': '0px 32px 64px rgba(0, 0, 0, 0.2)',
        '1-dark': '0px 4px 8px rgba(0, 0, 0, 0.5)',
      },
    },
    // These breakpoints match the ones defined in bootstrap at _variables.scss
    screens: {
      sm: '576px',
      md: '768px',
      lg: '1024px',
      xl: '1440px',
    },
    container: {
      center: true,
      padding: '1rem',
    },
    fontFamily: {
      display: ["'Loomi Display'", "'Times New Roman'", 'serif'],
      headline: ["'Loomi Headline'", "'Times New Roman'", 'serif'],
      body: ['Roobert', 'Arial', 'sans-serif'],
    },
    colors: {
      'danger': '#932010',
      'success': '#1d7747',
      'warning': '#936D06',
      'muted': '#595959',
      'success-bg': '#EAFAF2',
      // Grayscale ⚫️⚪️________________________________________________________________
      ['gray-black']: '#121212',
      ['gray-white']: '#ffffff', // FIXME: gray-white, why not just white?
      'white': '#ffffff',
      'transparent': 'transparent',

      // Colour ramps from https://www.figma.com/file/Sy45AQL2mkcwGC0HfqAuVp/Colour-Ramps?node-id=2%3A70
      'gray': {
        1: '#0F0F0F',
        2: '#1A1A1A',
        3: '#212121',
        4: '#292929',
        5: '#363636',
        6: '#404040',
        7: '#595959',
        8: '#8C8C8C',
        9: '#B3B3B3',
        10: '#CCCCCC',
        11: '#D6D6D6',
        12: '#E0E0E0',
        13: '#E8E8E8',
        14: '#F0F0F0',
        15: '#F7F7F7',
      },
      // Reds 🔴 _____________________________________________________________________
      'red': {
        1: '#090201',
        2: '#1C0603',
        3: '#2E0A05',
        4: '#4E1109',
        5: '#6E180C',
        6: '#932010',
        7: '#C52A16',
        8: '#E8422C',
        9: '#EB5C48',
        10: '#F18C7E',
        11: '#F4A99F',
        12: '#F7C1BA',
        13: '#FADAD6',
        14: '#FCEAE8',
        15: '#FEFBFA',
      },
      // Yellows 🟡 __________________________________________________________________
      'yellow': {
        1: '#0A0700',
        2: '#1D1601',
        3: '#312402',
        4: '#533E03',
        5: '#6C5004',
        6: '#936D06',
        7: '#D39D09',
        8: '#F6BC1E',
        9: '#F7C53C',
        10: '#F9D676',
        11: '#FBE098',
        12: '#FCE9B6',
        13: '#FDF2D3',
        14: '#FEF8E7',
        15: '#FFFEFA',
      },
      // Greens 🟢 ___________________________________________________________________
      'green': {
        1: '#020805',
        2: '#06190F',
        3: '#0A2918',
        4: '#11462A',
        5: '#155633',
        6: '#1D7747',
        7: '#28A462',
        8: '#42D185',
        9: '#5CD795',
        10: '#8CE3B5',
        11: '#A9EAC7',
        12: '#C1F0D7',
        13: '#DAF6E7',
        14: '#EAFAF2',
        15: '#FBFEFC',
      },
      // Blues 🔵 ____________________________________________________________________
      'blue': {
        1: '#020408',
        2: '#060C19',
        3: '#0A1329',
        4: '#102146',
        5: '#1B3674',
        6: '#244799',
        7: '#2951AE',
        8: '#416DD2',
        9: '#6A8CDC',
        10: '#8BA6E4',
        11: '#A8BCEB',
        12: '#C1CFF0',
        13: '#DAE2F6',
        14: '#EAEFFA',
        15: '#FBFCFE',
      },
      // Purples 🟣 __________________________________________________________________
      'purple': {
        1: '#060209',
        2: '#11051A',
        3: '#1C082B',
        4: '#300D4A',
        5: '#441268',
        6: '#5B188B',
        7: '#7A21BA',
        8: '#9738DC',
        9: '#A452E0',
        10: '#BF86EA',
        11: '#CFA4EF',
        12: '#DDBEF4',
        13: '#EBD8F8',
        14: '#F4E9FB',
        15: '#FDFBFE',
      },
      // Cyans  __________________________________________________________________
      'cyan': {
        1: '#020908',
        2: '#051A17',
        3: '#082B26',
        4: '#0D4A40',
        5: '#12685A',
        6: '#178271',
        7: '#21BAA2',
        8: '#38DCC2',
        9: '#52E0CA',
        10: '#86EADA',
        11: '#A4EFE3',
        12: '#BEF4EB',
        13: '#D8F8F3',
        14: '#E9FBF8',
        15: '#FBFEFE',
      },

      // Opacity _____________________________________________________________________
      'opacity-black': {
        1: 'rgba(0,0,0, 0.05)',
        2: 'rgba(0,0,0, 0.1)',
        3: 'rgba(0,0,0, 0.15)',
        4: 'rgba(0,0,0, 0.2)',
        5: 'rgba(0,0,0, 0.25)',
        6: 'rgba(0,0,0, 0.3)',
        7: 'rgba(0,0,0, 0.4)',
        8: 'rgba(0,0,0, 0.5)',
        9: 'rgba(0,0,0, 0.6)',
        10: 'rgba(0,0,0, 0.7)',
        11: 'rgba(0,0,0, 0.75)',
        12: 'rgba(0,0,0, 0.8)',
        13: 'rgba(0,0,0, 0.85)',
        14: 'rgba(0,0,0, 0.9)',
        15: 'rgba(0,0,0, 0.95)',
      },

      'opacity-white': {
        1: 'rgba(255, 255, 255, 0.05)',
        2: 'rgba(255, 255, 255, 0.1)',
        3: 'rgba(255, 255, 255, 0.15)',
        4: 'rgba(255, 255, 255, 0.2)',
        5: 'rgba(255, 255, 255, 0.25)',
        6: 'rgba(255, 255, 255, 0.3)',
        7: 'rgba(255, 255, 255, 0.4)',
        8: 'rgba(255, 255, 255, 0.5)',
        9: 'rgba(255, 255, 255, 0.6)',
        10: 'rgba(255, 255, 255, 0.7)',
        11: 'rgba(255, 255, 255, 0.75)',
        12: 'rgba(255, 255, 255, 0.8)',
        13: 'rgba(255, 255, 255, 0.85)',
        14: 'rgba(255, 255, 255, 0.9)',
        15: 'rgba(255, 255, 255, 0.95)',
      },
    },
  },
  plugins: [
    TailwindForms({
      strategy: 'class', // only generate classes
    }),
    backfaceVisibility,
    highContrastBw,
  ],
}

export default config
