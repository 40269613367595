import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  SELF_SERVICE_CONFIGURATIONS_LIST: 'SELF_SERVICE_CONFIGURATIONS_LIST',
  SELF_SERVICE_CONFIGURATION_CREATE: 'SELF_SERVICE_CONFIGURATION_CREATE',
  SELF_SERVICE_CONFIGURATION_EDIT: 'SELF_SERVICE_CONFIGURATION_EDIT',
  SELF_SERVICE_TERMINAL_EDIT: 'SELF_SERVICE_TERMINAL_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'self-service/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.SELF_SERVICE_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/SelfService/Configurations/List.vue'),
      },
      {
        path: 'create',
        name: routeNames.SELF_SERVICE_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/SelfService/Configurations/Create.vue'),
      },
      {
        path: ':configurationId',
        component: () => import('@/pages/Applications/SelfService/Configurations/Show.vue'),
        props: true,
        children: [
          {
            path: '',
            name: routeNames.SELF_SERVICE_CONFIGURATION_EDIT,
            component: () => import('@/pages/Applications/SelfService/Configurations/Edit.vue'),
          },
          {
            path: 'self-service-terminals/:selfServiceTerminalId',
            name: routeNames.SELF_SERVICE_TERMINAL_EDIT,
            component: () => import('@/pages/Applications/SelfService/SelfServiceTerminals/Edit.vue'),
            props: true,
          },
        ],
      },
    ],
  },
]

export default routes
