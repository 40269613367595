import type { NavigationHookAfter } from 'vue-router'

import { sendPageViewEvent } from '@/shared/ProductAnalyticsService'

/**
 * Since Posthog's default pageview event is not triggered properly in SPA's we need to trigger it manually
 * https://posthog.com/tutorials/spa
 * @param to
 * @param _
 * @param failure
 */
const productAnalyticsPageViewMiddleware: NavigationHookAfter = (to, _, failure) => {
  if (failure) {
    return
  }
  sendPageViewEvent(to.path)
}

export default productAnalyticsPageViewMiddleware
