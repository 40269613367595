import { i18n } from '@/i18n'
import notify from '@/notify'
import { useAuthStore } from '@/stores/auth'

/**
 * Takes an Axios error and returns a user-friendly error message
 * @param error
 */
const getErrorMessage = async (error: any) => {
  //Errors from the server
  if (error.response?.data?.message) {
    return error.response.data.message
  }
  //If error is a blob (file download)
  if (error.response?.data instanceof Blob) {
    const text = await error.response.data.text()
    return JSON.parse(text).message
  }
  //Errors from the client
  if (error.request) {
    return i18n.global.t('error.network')
  }
  return error.message
}

export const handleError = (error: any) => {
  getErrorMessage(error).then((err) => notify.error(err))
}

/**
 * Takes an array of PromiseSettledResults and shows a toast compiling the errors
 * Should be used with the Promise.allSettled() function.
 * If there's no fails, return true
 * @param results
 */
export const handleErrorsForMultipleSettledRequests = async (results: PromiseSettledResult<any>[]) => {
  const fails = results.filter((result) => result.status === 'rejected') as PromiseRejectedResult[]
  /**
   * If there's no fails, return true, so the caller can continue
   */
  if (fails.length === 0) {
    return true
  }
  const specificMessages = (
    await Promise.all(
      fails.map((fail) => {
        return getErrorMessage(fail.reason)
      }),
    )
  ).join('\n')
  /**
   * If there's one request total, show the specific message
   */
  if (results.length === 1) {
    notify.error(specificMessages)
    return false
  }
  const { isInternalUser } = useAuthStore()

  /**
   * If all requests fail
   * Only show specific messages for support/internal users
   */
  if (fails.length === results.length) {
    let message = i18n.global.t('errors.multipleApiCalls.allFailed')
    if (isInternalUser) {
      message += `:\n${specificMessages}`
    }
    notify.error(message)
    return false
  }

  /**
   * If some, but not all requests fail.
   * Only show specific messages for support/internal users
   */
  let message = i18n.global.t('errors.multipleApiCalls.partiallyFailed', {
    failed: fails.length,
    total: results.length,
  })
  if (isInternalUser) {
    message += `:\n${specificMessages}`
  }
  notify.warning(message)
  return false
}
