import Bugsnag from '@bugsnag/js'
import { type RemovableRef, StorageSerializers, useStorage } from '@vueuse/core'
import { useJwt } from '@vueuse/integrations/useJwt'
import axios from 'axios'
import Echo from 'laravel-echo'
import { defineStore, storeToRefs } from 'pinia'
import type { RouteLocationRaw } from 'vue-router'

import type { PortalUserSubject } from '@/interfaces/PortalUser'

import http from '@/http'
import { i18n, loadLanguageAsync } from '@/i18n'
import notify from '@/notify'
import router from '@/router'
import { identifyUser, resetAnalytics } from '@/shared/ProductAnalyticsService'
import type { PortalPermissionValue } from '@/shared/constants/permissions'
import { initiateOrUpdateMavenoid } from '@/shared/mavenoidService'
import { RouteNames } from '@/shared/routes'
import { useMerchantsStore } from '@/stores/merchants'

import { useMessagesStore } from './messages-store'

interface AuthState {
  user: RemovableRef<PortalUserSubject | null>
  token: RemovableRef<string | null>
  currentTime: number
  originalToken: RemovableRef<string | null>
  redirect: string | null
  userSelectedNumberLocale: RemovableRef<string>
  echo: Echo | null
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: useStorage('user', null, localStorage, {
      serializer: StorageSerializers.object,
    }),
    token: useStorage('token', null, localStorage),
    currentTime: Date.now(),
    originalToken: useStorage('originalToken', null, localStorage),
    redirect: null,
    userSelectedNumberLocale: useStorage('userSelectedNumberLocale', '', localStorage),
    echo: null,
  }),

  actions: {
    async requestAuthenticationCode(payload: { email: string; password: string }) {
      await axios.post('/api/v2/authentication-codes', payload)
    },

    async reRequestAuthenticationCode(payload: { email: string; password: string }) {
      try {
        await axios.post('/api/v2/resent-authentication-codes', payload)
        notify.success(i18n.global.t('login.notification.authCodeResent'))
      } catch (error) {
        notify.error(i18n.global.t('login.notification.authCodeExpired'))
        throw error
      }
    },

    initializeEcho() {
      const key = import.meta.env.VITE_PUSHER_APP_KEY
      if (!key) {
        Bugsnag.notify(new Error('No Pusher app key found, cannot initialize Echo'))
        return
      }
      const apiUrl = import.meta.env.VITE_API_URL
      if (this.token && this.user) {
        this.echo = new Echo({
          broadcaster: 'pusher',
          key,
          cluster: 'eu',
          encrypted: true,
          namespace: '',
          authEndpoint: `${apiUrl}${apiUrl.endsWith('/') ? '' : '/'}api/v1/broadcasting/auth`,
          disableStats: true,
          auth: {
            headers: {
              Authorization: 'Bearer ' + this.token,
            },
          },
        })

        const channel = this.echo.channel(`reports-for-user.${this.user.id}`)
        channel?.listen('report-export-finished', (payload: { name: string; status: 'success' | 'failure' }) => {
          const { addMessage } = useMessagesStore()
          if (payload.status === 'success') {
            addMessage({
              level: 'success',
              content: i18n.global.t('reports.generatedReports.generationSuccess', { reportName: payload.name }),
              button_text: i18n.global.t('reports.generatedReports.viewReport'),
              button_url: {
                name: RouteNames.REPORTS_GENERATED_REPORTS,
              },
            })
          } else if (payload.status === 'failure') {
            addMessage({
              level: 'danger',
              content: i18n.global.t('reports.generatedReports.generationFailed'),
            })
          }
        })
      } else {
        // Throw an error in Bugsnag
        Bugsnag.notify(new Error('No token or user found, cannot initialize Echo'))
      }
    },

    clearEcho() {
      if (this.echo) {
        this.echo.disconnect()
        this.echo = null
      }
    },

    async logIn(payload: { email: string; password: string; authentication_code: string }) {
      const { data } = await axios.post('/api/v2/tokens', {
        grant_type: 'authentication-code',
        grant_parameters: payload,
      })
      // Store the session token
      this.token = data.data.id

      const user = await this.afterLogin()

      // Perform analytics chores after login (like opting out internal users from analytics)
      identifyUser(user)

      return user
    },

    async afterLogin() {
      const [user] = await Promise.all([
        this.fetchLoggedInUser(),
        useMerchantsStore().fetchMerchants(),
        useMessagesStore().loadMessages(),
      ])
      return user
    },

    async fetchLoggedInUser() {
      const { data } = await http.get('/api/v1/me')
      const user = data.data.subject as PortalUserSubject
      this.user = user
      await loadLanguageAsync(user.language)
      return user
    },

    async updateUser(payload: {
      authentication_code_driver?: string
      language?: string
      name?: string
      phone?: string
      country?: string
      work_hours_from?: string | null
      work_hours_to?: string | null
    }): Promise<PortalUserSubject> {
      // Since api only supports PUT, we need to send all required fields, not just the ones that are being updated
      const { authentication_code_driver, language, name, phone, country, work_hours_from, work_hours_to } = this.user!
      const updatedUser = {
        authentication_code_driver,
        language,
        name,
        phone,
        country,
        work_hours_from,
        work_hours_to,
        ...payload,
      }
      await http.put('/api/v2/profile', updatedUser)
      const user = await this.fetchLoggedInUser()
      notify.success(i18n.global.t('notification.profileUpdated'))
      return user
    },

    async acceptTerms() {
      await http.post('/api/v2/accepted-terms')
    },

    async refreshToken() {
      try {
        const { data } = await axios.post('/api/v2/tokens', {
          grant_type: 'refresh',
          grant_parameters: {
            access_token: this.token,
          },
        })
        this.token = data.data.id
      } catch (error: any) {
        notify.error(error.response?.data?.message || error)
        this.logOut(true)
      }
    },

    resetUser() {
      this.user = null
      this.token = null
      this.originalToken = null
      this.userSelectedNumberLocale = ''
      useMerchantsStore().resetMerchantsStore()
      useMessagesStore().resetMessages()
    },

    async logOut(redirectToLogin: boolean) {
      if (redirectToLogin) {
        try {
          await router.push({ name: RouteNames.LOGIN })
        } catch (error) {
          // If we get an error, most likely new frontend code has been deployed, so the chunk is not available anymore.
          // Instead of not handling the error and sending it to Bugsnag, we just log it to console.
          // See https://jira.loomis.com/browse/PP-587
          // eslint-disable-next-line no-console
          console.error(error)
        }
      }
      if (!this.isTokenExpired) {
        http.delete('/api/v2/tokens')
      }
      this.resetUser()
      resetAnalytics()
      this.clearEcho()
      await initiateOrUpdateMavenoid()
    },

    async startImpersonation({ userId }: { userId: string | number }) {
      try {
        const { data } = await axios.post('/api/v2/tokens', {
          grant_type: 'impersonate',
          grant_parameters: {
            user_id: userId,
            access_token: this.token,
          },
        })
        const originalToken = this.token
        this.resetUser()
        this.originalToken = originalToken
        this.token = data.data.id

        await this.afterLogin()

        notify.success(i18n.global.t('users.notification.impersonate', { name: this.user!.name }))

        if (this.redirect) {
          await router.push(this.redirect)
        }
      } catch (error: any) {
        notify.error(error.response?.data?.message || error)
      }
    },
    async stopImpersonation() {
      this.token = this.originalToken
      this.originalToken = null
      await this.afterLogin()
      await router.push({ name: RouteNames.DASHBOARD })
      notify.success(i18n.global.t('navigation.notification.impersonationStopped'))
    },

    updateRedirect(payload: string | null) {
      this.redirect = payload
    },

    getRedirect(): RouteLocationRaw {
      if (this.redirect) {
        const location = { path: this.redirect }
        this.redirect = null
        return location
      }

      if (this.user?.redirect) {
        return { path: this.user.redirect }
      }

      return { name: RouteNames.DASHBOARD }
    },
  },

  getters: {
    /**
     * Returns true if the user has a loomis or loomispay email address
     * @param state
     */
    isInternalUser: (state) => {
      return state.user?.email.includes('@loomis.com') || state.user?.email.includes('@loomispay.com')
    },
    isGlobalAdmin: (state) => state.user?.is_global_admin,
    userPermissions: (state) => state.user?.all_permissions,
    impersonating: (state) => !!state.originalToken,
    name: (state) => state.user?.name || '',
    phone: (state) => state.user?.phone,
    email: (state) => state.user?.email,
    hasCompleteProfile: (state) => state.user?.name && state.user?.phone && state.user?.terms_accepted_at,
    hasPermission:
      (state) =>
      (permissionName: PortalPermissionValue): boolean => {
        return !!(
          state.user?.is_global_admin ||
          state.user?.all_permissions.some((permission) => permission.name === permissionName)
        )
      },
    language: (state) => state.user?.language,
    termsAndConditionsUrl: (state) => {
      switch (state.user?.language) {
        case 'sv_SE':
          return 'https://loomispay.com/sv-se/legal/provisions-loomis-digital'
        case 'es_ES':
          return 'https://loomispay.com/es-es/legal/provisions-loomis-digital'
        case 'da_DK':
          return 'https://loomispay.com/da-dk/legal/provisions-loomis-digital'
        default:
          return 'https://loomispay.com/en-se/legal/provisions-loomis-digital'
      }
    },
    privacyPolicyUrl: (state) => {
      switch (state.user?.language) {
        case 'sv_SE':
          return 'https://loomispay.com/sv-se/legal/privacy-policy'
        case 'es_ES':
          return 'https://loomispay.com/es-es/legal/privacy-policy'
        case 'da_DK':
          return 'https://loomispay.com/da-dk/legal/privacy-policy'
        default:
          return 'https://loomispay.com/en-se/legal/privacy-policy'
      }
    },
    tokenExpiresAt: (state) => {
      if (!state.token) {
        return null
      }
      const { payload } = useJwt(state.token)
      if (payload.value?.exp) {
        return payload.value.exp * 1000
        // Leaving this here for debugging purposes: Overrides the token expiration time to six minutes from now
        // const sixMinutesInMs = 1000 * 60 * 6
        // return Math.max(payload.value.exp * 1000 - 1000 * 60 * 60 * 4 + sixMinutesInMs, sixMinutesInMs)
      } else {
        throw new Error('Token does not have an expiration date')
      }
    },
    isTokenExpired(): boolean {
      if (!this.tokenExpiresAt) {
        return true
      }
      return this.tokenExpiresAt < this.currentTime
    },
    isTokenExpiringSoon(): boolean | null {
      if (!this.tokenExpiresAt) {
        return null
      }
      const minutesBeforeExtendOption = 5
      return this.tokenExpiresAt < this.currentTime + 1000 * 60 * minutesBeforeExtendOption
    },
    secondsUntilTokenExpires(): number {
      if (!this.tokenExpiresAt) {
        return 0
      }
      return Math.max(0, Math.round((this.tokenExpiresAt - this.currentTime) / 1000))
    },
    userHasWorkHours(): boolean {
      return !!(this.user?.work_hours_from && this.user?.work_hours_to)
    },
    currentNumberLocale(): string {
      return this.userSelectedNumberLocale || this.browserLocale
    },
    browserLocale(): string {
      return (navigator.languages || [navigator.language])[0]
    },
  },
})
