<template>
  <FormModal
    v-if="downloadFunction"
    ref="formModal"
    v-model:value="formValue"
    :title="$t('enterEmailToDownloadModal.heading')"
    :description="$t('enterEmailToDownloadModal.description')"
    :fields="fields"
    :submit-button-text="$t('enterEmailToDownloadModal.button')"
    @close="downloadFunction = null"
    @submit="download"
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'
import { useI18n } from 'vue-i18n'

import FormModal from '@/components/Modals/Templates/FormModal.vue'
import notify from '@/notify'
import { useAuthStore } from '@/stores/auth'
import { useEnterEmailToDownloadModalStore } from '@/stores/enter-email-to-download-modal-store'

const { downloadFunction } = storeToRefs(useEnterEmailToDownloadModalStore())

const { t } = useI18n()

const fields = [
  {
    name: 'email',
    type: 'email',
    label: t('global.email'),
    focusOnMounted: true,
  },
]

const formModal = ref<ComponentExposed<typeof FormModal> | null>(null)

const { user } = useAuthStore()

watch(
  () => user,
  () => {
    formValue.value.email = user?.email || ''
  },
)

const formValue = ref<{
  email: string
}>({
  email: user?.email || '',
})

const download = async () => {
  if (!formValue.value.email) {
    formModal.value!.errors = {
      email: [t('validation.required')],
    }
    return
  }
  formModal.value!.resetErrors()
  await downloadFunction.value!(formValue.value.email)
  notify.success(t('enterEmailToDownloadModal.successToast'))
  downloadFunction.value = null
}
</script>
