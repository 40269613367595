import type { NavigationGuard } from 'vue-router'

import { RouteNames } from '@/shared/routes'
import { useAuthStore } from '@/stores/auth'
import { useMerchantsStore } from '@/stores/merchants'

const authMiddleware: NavigationGuard = async (to, from, next) => {
  const authStore = useAuthStore()
  const { merchantsLoaded, fetchMerchants, selectedMerchantId } = useMerchantsStore()
  if (to.matched.some((route) => route.meta.auth)) {
    try {
      if (!authStore.user) {
        await authStore.fetchLoggedInUser()
      }
      if (!merchantsLoaded) {
        await fetchMerchants()
      }
      if (!authStore.hasCompleteProfile && !authStore.impersonating) {
        authStore.updateRedirect(to.fullPath)
        return next({ name: RouteNames.LOGIN_UPDATE_DETAILS })
      }
    } catch (error) {
      authStore.updateRedirect(to.fullPath)
      authStore.resetUser()
      return next({ name: RouteNames.LOGIN })
    }
    // Routes with meta.global are accessible without a merchantId
    if (!to.params.selectedMerchantId && !to.meta.global) {
      const location = { ...to, params: { ...to.params, selectedMerchantId } }
      return next(location)
    }
    return next()
  }
  next()
}

export default authMiddleware
