<template>
  <div>
    <template v-for="(field, index) in fields">
      <div
        v-if="field.type === 'spacer'"
        :key="index"
      />

      <AdaptiveFormGroup
        v-if="field.type === 'adaptive' && shouldDisplay(field.display)"
        :key="index"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :class="field.class"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :endpoint="field.endpoint"
        :params="field.params"
        :nullable="field.nullable"
        :placeholder="field.placeholder"
        :result-label-property="field.resultLabelProperty || ((row: any) => row.attributes.name)"
        :help-text="field.helpText"
        :link="field.link"
        :auto-select="!!field.autoSelect"
        :optional="!!field.optional"
        v-on="field.on ? field.on : {}"
        @update:value="input(field.name, $event)"
      />

      <TextFormGroup
        v-if="field.type === 'text' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :focus-on-mounted="field.focusOnMounted || false"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :help-text="field.helpText"
        :suffix="field.suffix"
        :link="field.link"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <EmailFormGroup
        v-if="field.type === 'email' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :help-text="field.helpText"
        :focus-on-mounted="!!field.focusOnMounted"
        @update:value="input(field.name, $event)"
      />

      <TextareaFormGroup
        v-if="field.type === 'textarea' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <TimeFormGroup
        v-if="field.type === 'time' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="field.disabled || disabled"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <DateCalculationFormGroup
        v-if="field.type === 'date-calculation' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <SelectFormGroup
        v-if="field.type === 'select' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :options="getValue(field.options)"
        :disabled="isDisabled(field)"
        :nullable="field.nullable"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <DateFormGroup
        v-if="field.type === 'date' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :mode="field.mode"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <CheckboxGroupBlockFormGroup
        v-if="field.type === 'checkbox' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :options="getValue(field.options)"
        :disabled="isDisabled(field)"
        :nullable="field.nullable"
        :help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <BooleanFormGroup
        v-if="field.type === 'boolean' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :name="field.name"
        :label="field.label"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :top-help-text="field.helpText"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <StaticFormGroup
        v-if="field.type === 'static' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :label="field.label"
        :optional="!!field.optional"
        :help-text="field.helpText"
      />

      <NumberBlockFormGroup
        v-if="field.type === 'number' && shouldDisplay(field.display)"
        :key="index"
        :class="field.class"
        :value="value[field.name]"
        :focus-on-mounted="field.focusOnMounted || false"
        :name="field.name"
        :label="field.label"
        :input-props="{ prefix: field.prefix, suffix: field.suffix, step: field.step }"
        :error="getError(field.error_name || field.name)"
        :disabled="isDisabled(field)"
        :help-text="field.helpText"
        :suffix="field.suffix"
        :link="field.link"
        :min="field.min"
        :max="field.max"
        :currency="field.currency"
        :currency-display="field.currencyDisplay"
        :fraction-digits="field.fractionDigits"
        :locale="field.locale"
        :optional="!!field.optional"
        @update:value="input(field.name, $event)"
      />

      <slot
        v-if="field.slot"
        :name="field.slot"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import AdaptiveFormGroup from '@/components/FormGroups/Block/AdaptiveBlockFormGroup.vue'
import BooleanFormGroup from '@/components/FormGroups/Block/BooleanBlockFormGroup.vue'
import CheckboxGroupBlockFormGroup from '@/components/FormGroups/Block/CheckboxGroupBlockFormGroup.vue'
import DateFormGroup from '@/components/FormGroups/Block/DateBlockFormGroup.vue'
import DateCalculationFormGroup from '@/components/FormGroups/Block/DateCalculationBlockFormGroup.vue'
import EmailFormGroup from '@/components/FormGroups/Block/EmailBlockFormGroup.vue'
import NumberBlockFormGroup from '@/components/FormGroups/Block/NumberBlockFormGroup.vue'
import SelectFormGroup from '@/components/FormGroups/Block/SelectBlockFormGroup.vue'
import StaticFormGroup from '@/components/FormGroups/Block/StaticBlockFormGroup.vue'
import TextFormGroup from '@/components/FormGroups/Block/TextBlockFormGroup.vue'
import TextareaFormGroup from '@/components/FormGroups/Block/TextareaBlockFormGroup.vue'
import TimeFormGroup from '@/components/FormGroups/Block/TimeBlockFormGroup.vue'

const props = withDefaults(
  defineProps<{
    fields: any[]
    value?: any
    disabled?: boolean
    errors?: any
    errorPrefix?: string
  }>(),
  {
    value: () => ({}),
    errors: () => ({}),
    errorPrefix: '',
  },
)
const emit = defineEmits<{
  'update:value': [any]
}>()

const getError = (name: string) => props.errors?.[`${props.errorPrefix}${name}`]?.[0] ?? null

const getValue = (value: any) => (typeof value === 'function' ? value() : value)

const input = (key: string, value: any) =>
  emit('update:value', {
    ...props.value,
    [key]: value,
  })

const shouldDisplay = (display: boolean | undefined) => typeof display === 'undefined' || display

const isDisabled = (field: any) =>
  field.disabled || props.disabled || (field.toggledBy && !props.value[field.toggledBy])
</script>
