import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Skatteverket/Configurations/SkatteverketConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/Skatteverket/Configurations/SkatteverketCreateConfiguration.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/Skatteverket/Configurations/SkatteverketListConfigurations.vue')
const ShowConfiguration = () =>
  import('@/pages/Applications/Skatteverket/Configurations/SkatteverketConfigurationDetails.vue')
const Setup = () => import('@/pages/Applications/Skatteverket/SkatteverketSetup.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  SKATTEVERKET_CONFIGURATIONS_LIST: 'SKATTEVERKET_CONFIGURATIONS_LIST',
  SKATTEVERKET_CONFIGURATIONS_CREATE: 'SKATTEVERKET_CONFIGURATIONS_CREATE',
  SKATTEVERKET_CONFIGURATIONS_DETAILS: 'SKATTEVERKET_CONFIGURATIONS_EDIT',
  SKATTEVERKET_SETUP: 'SKATTEVERKET_SETUP',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'skatteverket',
    component: Blank,
    children: [
      {
        path: 'configurations',
        component: Blank,
        meta: { subsection: 'configurations' },
        children: [
          {
            path: '',
            component: ConfigurationList,
            name: routeNames.SKATTEVERKET_CONFIGURATIONS_LIST,
          },
          {
            path: 'create',
            component: CreateConfiguration,
            name: routeNames.SKATTEVERKET_CONFIGURATIONS_CREATE,
          },
          {
            path: ':configurationId',
            component: ConfigurationContainer,
            props: true,
            children: [
              {
                path: '',
                component: ShowConfiguration,
                name: routeNames.SKATTEVERKET_CONFIGURATIONS_DETAILS,
              },
            ],
          },
        ],
      },
      {
        path: 'setup',
        component: Setup,
        meta: { subsection: 'setup' },
        name: routeNames.SKATTEVERKET_SETUP,
      },
    ],
  },
]

export default routes
