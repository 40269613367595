<template>
  <PortalModal
    :title="$t('modal.extendSession.title')"
    persistent
  >
    <div class="mt-2">
      {{
        $t('modal.extendSession.body', {
          time: `${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`,
        })
      }}
    </div>
    <template #footer>
      <div class="tw-flex tw-justify-end">
        <LuiButton
          :disabled="loading"
          emphasis="high_red"
          class="tw-mr-4"
          @click="logOutClickHandler"
        >
          {{ $t('modal.extendSession.logOutButton.label') }}
        </LuiButton>
        <LuiButton
          :loading="loading"
          @click="extendClickHandler"
        >
          {{ $t('modal.extendSession.extendButton.label') }}
        </LuiButton>
      </div>
    </template>
  </PortalModal>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import PortalModal from '@/components/Modals/Templates/PortalModal.vue'
import { useAuthStore } from '@/stores/auth'

const authStore = useAuthStore()
const { secondsUntilTokenExpires } = storeToRefs(authStore)
const { refreshToken, logOut } = authStore

const loading = ref(false)

const logOutClickHandler = async () => {
  loading.value = true
  await logOut(true)
  loading.value = false
}

const extendClickHandler = async () => {
  loading.value = true
  try {
    await refreshToken()
  } catch (error) {
    console.error(error)
  } finally {
    loading.value = false
  }
}

const remainingMinutes = computed(() => Math.floor(secondsUntilTokenExpires.value / 60))

const remainingSeconds = computed(() => secondsUntilTokenExpires.value % 60)
</script>
