import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Meetings/Configurations/MeetingsAppConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/Meetings/Configurations/MeetingsAppConfigurationCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Meetings/Configurations/MeetingsAppConfigurationsList.vue')
const MeetingFieldList = () => import('@/pages/Applications/Meetings/MeetingFields/MeetingsMeetingFieldsList.vue')
const EditMeetingFolder = () => import('@/pages/Applications/Meetings/MeetingFolders/MeetingFoldersEdit.vue')
const MeetingFolderList = () => import('@/pages/Applications/Meetings/MeetingFolders/MeetingsFoldersList.vue')
const CreateMeetingMap = () => import('@/pages/Applications/Meetings/MeetingMaps/MeetingsMapProducts.vue')
const EditMeetingProduct = () => import('@/pages/Applications/Meetings/MeetingProducts/MeetingsProductEdit.vue')
const MeetingProductList = () => import('@/pages/Applications/Meetings/MeetingProducts/MeetingsProductsList.vue')
const MeetingRoomList = () => import('@/pages/Applications/Meetings/MeetingRooms/MeetingsMeetingRoomsList.vue')
const MeetingStoreContainer = () => import('@/pages/Applications/Meetings/MeetingStores/MeetingsStoreContainer.vue')
const EditMeetingStore = () => import('@/pages/Applications/Meetings/MeetingStores/MeetingsStoreEdit.vue')
const MeetingStoreList = () => import('@/pages/Applications/Meetings/MeetingStores/MeetingsStoreList.vue')
const MeetingStoreMenu = () => import('@/pages/Applications/Meetings/MeetingStores/MeetingsStoreMenu.vue')
const MeetingCalendar = () => import('@/pages/Applications/Meetings/Meetings/MeetingsCalendar.vue')
const CreateMeeting = () => import('@/pages/Applications/Meetings/Meetings/MeetingsMeetingCreate.vue')
const EditMeeting = () => import('@/pages/Applications/Meetings/Meetings/MeetingsMeetingEdit.vue')
const MeetingList = () => import('@/pages/Applications/Meetings/Meetings/MeetingsMeetingsList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  MEETINGS_CONFIGURATIONS_LIST: 'MEETINGS_CONFIGURATIONS_LIST',
  MEETINGS_CONFIGURATIONS_CREATE: 'MEETINGS_CONFIGURATIONS_CREATE',
  MEETINGS_CONFIGURATIONS_SHOW: 'MEETINGS_CONFIGURATIONS_SHOW',
  MEETINGS_CONFIGURATIONS_STORES: 'MEETINGS_CONFIGURATIONS_STORES',
  MEETINGS_CONFIGURATIONS_STORES_SHOW: 'MEETINGS_CONFIGURATIONS_STORES_SHOW',
  MEETINGS_CONFIGURATIONS_STORES_MEETINGS: 'MEETINGS_CONFIGURATIONS_STORES_MEETINGS',
  MEETINGS_CONFIGURATIONS_STORES_MENU: 'MEETINGS_CONFIGURATIONS_STORES_MENU',
  MEETINGS_CONFIGURATIONS_STORES_MEETINGS_CREATE: 'MEETINGS_CONFIGURATIONS_STORES_MEETINGS_CREATE',
  MEETINGS_CONFIGURATIONS_STORES_MAP_CREATE: 'MAP_CONFIGURATIONS_STORES_MEETINGS_CREATE',
  MEETINGS_CONFIGURATIONS_STORES_CALENDAR: 'CALENDAR_CONFIGURATIONS_STORES_MEETINGS',
  MEETINGS_CONFIGURATIONS_STORES_FOLDER_LIST: 'MEETINGS_CONFIGURATIONS_STORES_FOLDER_LIST',
  MEETINGS_CONFIGURATIONS_STORES_FOLDER_EDIT: 'MEETINGS_CONFIGURATIONS_STORES_FOLDER_EDIT',
  MEETINGS_CONFIGURATIONS_STORES_PRODUCTS: 'MEETINGS_CONFIGURATIONS_STORES_PRODUCTS',
  MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT: 'MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT',
  MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT_REDIRECT: 'MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT_REDIRECT',
  MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT: 'MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT',
  MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT_REDIRECT: 'MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT_REDIRECT',
  MEETINGS_CONFIGURATIONS_STORES_ROOMS: 'MEETINGS_CONFIGURATIONS_STORES_ROOMS',
  MEETINGS_CONFIGURATIONS_STORES_FIELDS: 'MEETINGS_CONFIGURATIONS_STORES_FIELDS',
  MEETINGS_CONFIGURATIONS_STORES_EDIT: 'MEETINGS_CONFIGURATIONS_STORES_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'meetings/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.MEETINGS_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.MEETINGS_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.MEETINGS_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.MEETINGS_CONFIGURATIONS_STORES }),
          },
          {
            path: 'stores',
            name: routeNames.MEETINGS_CONFIGURATIONS_STORES,
            component: MeetingStoreList,
          },
          {
            path: 'stores/:meetingStoreId',
            component: MeetingStoreContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_SHOW,
                redirect: () => ({ name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETINGS }),
              },
              {
                path: 'meetings',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETINGS,
                component: MeetingList,
                meta: { subsection: 'meetings' },
              },
              {
                path: 'calendar',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_CALENDAR,
                component: MeetingCalendar,
                meta: { subsection: 'calendar' },
              },
              {
                path: 'meetings/create',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETINGS_CREATE,
                component: CreateMeeting,
                meta: { subsection: 'meetings' },
              },
              {
                path: 'meetings/:meetingId',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT_REDIRECT,
                redirect: (to) => ({
                  name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT,
                  params: {
                    meetingId: to.params.meetingId,
                  },
                }),
              },
              {
                path: 'meetings/:meetingId/edit',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MEETING_EDIT,
                component: EditMeeting,
                props: true,
                meta: { subsection: 'meetings' },
              },

              {
                path: 'maps/create',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MAP_CREATE,
                component: CreateMeetingMap,
                meta: { subsection: 'menu' },
              },
              {
                path: 'menu',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_MENU,
                component: MeetingStoreMenu,
                meta: { subsection: 'menu' },
              },
              {
                path: 'folders',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_FOLDER_LIST,
                component: MeetingFolderList,
                meta: { subsection: 'folders' },
              },
              {
                path: 'folders/:meetingFolderId',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_FOLDER_EDIT,
                component: EditMeetingFolder,
                meta: { subsection: 'folders' },
                props: true,
              },
              {
                path: 'products',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_PRODUCTS,
                component: MeetingProductList,
                meta: { subsection: 'products' },
              },
              {
                path: 'products/:meetingProductId',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT_REDIRECT,
                redirect: (to) => ({
                  name: routeNames.MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT,
                  params: { meetingProductId: to.params.meetingProductId },
                }),
              },
              {
                path: 'products/:meetingProductId/edit',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_PRODUCT_EDIT,
                component: EditMeetingProduct,
                meta: { subsection: 'products' },
                props: true,
              },
              {
                path: 'rooms',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_ROOMS,
                component: MeetingRoomList,
                meta: { subsection: 'rooms' },
              },
              {
                path: 'fields',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_FIELDS,
                component: MeetingFieldList,
                meta: { subsection: 'fields' },
              },
              {
                path: 'edit',
                name: routeNames.MEETINGS_CONFIGURATIONS_STORES_EDIT,
                component: EditMeetingStore,
                meta: { subsection: 'edit' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
