<template>
  <div>
    <ListFilter
      v-if="filters || $slots.inlineForm"
      :value="filterValues"
      :fields="Array.isArray(filters) ? filters : Object.values(filters || [])"
      :disabled="loading"
      @clear="onClear"
      @update:value="$emit('update:filter-values', $event)"
      @submit="onFilterSubmit"
    >
      <slot name="inlineForm" />
    </ListFilter>
    <FadeTransition>
      <LoadingSpinner
        v-if="showPreloader"
        class="tw-py-10"
      />
      <div v-else-if="error">
        <slot
          name="error"
          :error="error"
        >
          <div class="tw-bg-red-14 tw-text-danger tw-px-5 tw-py-4">
            {{ error }}
          </div>
        </slot>
      </div>
      <div v-else>
        <BasicTable
          v-if="computedItems.length"
          class="tw-mb-4"
          v-bind="{ columns, height, items: computedItems, rowIsClickable, sort: listSorting, noHeading, rowStyles }"
          @row-clicked="$emit('row-clicked', $event)"
          @table-heading-clicked="handleSort"
        >
          <template
            v-for="(_, name) in $slots"
            #[String(name)]="scopedProps"
          >
            <slot
              :name="name"
              :refresh-table="refreshTable"
              v-bind="scopedProps"
            />
          </template>
        </BasicTable>
        <div v-else>
          <slot name="empty">
            <div class="tw-text-center tw-p-10 tw-text-gray-7">
              {{ emptyMessage || $t('global.listEmptyState') }}
            </div>
          </slot>
        </div>
        <ResourcePager
          v-if="paginationResponse && paginationResponse.last_page > 1"
          :pagination="paginationResponse"
          @navigate="onPagedPagination"
        />
        <LoadMorePager
          v-else-if="loadMorePagination && paginationResponse && paginationResponse.next_cursor"
          :loading="showLoadMorePreloader"
          :next-cursor="paginationResponse.next_cursor"
          @load-more="onLoadMorePagination"
        />
        <CursorPager
          v-else-if="paginationResponse && (paginationResponse.prev_cursor || paginationResponse.next_cursor)"
          :prev-cursor="paginationResponse.prev_cursor"
          :next-cursor="paginationResponse.next_cursor"
          @cursor="onCursorPagination"
        />
      </div>
    </FadeTransition>
  </div>
</template>

<script setup lang="ts" generic="T = any">
import { type Ref, computed, nextTick, onMounted, ref } from 'vue'
import { provide } from 'vue'
import { watch } from 'vue'
import { type LocationQueryRaw, useRoute, useRouter } from 'vue-router'

import type { BaseApiPagedResponse } from '@/interfaces/BaseApi'
import type { BasicTableColumn, ListSorting } from '@/interfaces/Frontend/BasicTable'
import type { PortalFilters } from '@/interfaces/PortalFilters'

import type { PortalId } from '@/types/portal'

import ListFilter from '@/components/Forms/ListFilter.vue'
import LoadingSpinner from '@/components/Layout/LoadingSpinner.vue'
import BasicTable from '@/components/Lists/BasicTable.vue'
import type { Props as BasicTableProps } from '@/components/Lists/BasicTable.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'
import CursorPager from '@/components/Utilities/Pagination/CursorPager.vue'
import LoadMorePager from '@/components/Utilities/Pagination/LoadMorePager.vue'
import ResourcePager from '@/components/Utilities/Pagination/ResourcePager.vue'
import http from '@/http'

export type Props<T> = BasicTableProps<T> & {
  endpoint?: string | undefined
  fetchPromise?: ((filters: any) => Promise<BaseApiPagedResponse<T>>) | undefined
  filters?: PortalFilters
  filterValues?: any
  listSorting?: ListSorting
  loading?: boolean
  pageSize?: number
  syncFilterStateWithQueries?: boolean
  transformFiltersToApiQuery?: (filters: any) => any
  // //If the API only supports "load more" (one way cursoring). If the data is saved in a document database.
  loadMorePagination?: boolean
  merchantId?: PortalId | null
}

const props = withDefaults(defineProps<Props<T>>(), {
  columns: () => [],
  emptyMessage: undefined,
  endpoint: undefined,
  fetchPromise: undefined,
  filters: undefined,
  filterValues: () => ({}),
  height: 'default',
  items: () => [],
  listSorting: () => ({
    column: '',
    direction: 'desc',
  }),
  loading: false,
  pageSize: 25,
  rowIsClickable: false,
  syncFilterStateWithQueries: true,
  transformFiltersToApiQuery: (filters: any) => filters,
  merchantId: null,
})

provide('merchantId', props.merchantId)

const route = useRoute()
const router = useRouter()

const emit = defineEmits([
  'cursor',
  'page',
  'update:filter-values',
  'update:list-sorting',
  'loading',
  'row-clicked',
  'sort',
  'error',
])

const showPreloader = ref(!!props.endpoint || !!props.fetchPromise)
const fetchedItems: Ref<any[]> = ref([])
const paginationResponse: Ref<any | null> = ref(null)
const cursor: Ref<string | undefined> = ref(undefined)
const page: Ref<string | number | undefined> = ref(undefined)
const error: Ref<string | undefined> = ref(undefined)
const showLoadMorePreloader = ref(false)

watch(
  () => props.merchantId,
  async () => {
    await nextTick()
    resetStateAndFetchData()
  },
)

/**
 * If an endpoint is given returns fetchedItems, else returns items given by propItems.
 * @returns {[]|*[]}
 */
const computedItems = computed(() => {
  if (props.endpoint || props.fetchPromise) {
    return fetchedItems.value
  }
  return props.items
})

onMounted(() => {
  if (props.syncFilterStateWithQueries) {
    mapFilterQueryValuesToState()
  }
  nextTick(() => {
    if (props.endpoint || props.fetchPromise) {
      refreshTable()
    }
  })
})

const handleSort = (tableHeading: BasicTableColumn) => {
  if (!tableHeading.sort) {
    return
  }
  let newSort = { ...props.listSorting }
  if (props.listSorting.column === tableHeading.sort) {
    newSort = {
      column: tableHeading.sort,
      direction: props.listSorting.direction === 'asc' ? 'desc' : 'asc',
    }
  } else {
    newSort = {
      column: tableHeading.sort,
      direction: 'desc',
    }
  }
  emit('update:list-sorting', newSort)
  cursor.value = undefined
  page.value = undefined
  updateQueryKeysAndFetchData({
    ...newSort,
    page: null,
    cursor: null,
  })
}

const onClear = () => {
  resetFiltersAndFetchData()
}

/**
 * Takes all filters and pagination info from the query and emits them to the filterValues state.
 */
const mapFilterQueryValuesToState = () => {
  const rawFilters: Record<string, string | boolean> = http.unwrapApiFilterSyntax(route.query as Record<string, string>)
  /*
   * Explicitly transform boolean filters from the truthy value 'false' to the boolean false.
   */
  for (const key in rawFilters) {
    const value = rawFilters[key]
    if (value === 'false') {
      rawFilters[key] = false
    }
  }
  emit('update:filter-values', rawFilters)
  cursor.value = route.query.cursor as string
  page.value = route.query.page as string
}

/**
 * Fetches data from the API
 */
const refreshTable = async () => {
  emit('loading', true)
  showPreloader.value = true
  fetchedItems.value = await fetchExternalData()
  showPreloader.value = false
}

const fetchExternalData = async () => {
  error.value = undefined
  try {
    const response = props.endpoint
      ? (
          await http.get(props.endpoint!, {
            ...getPaginationPayload(),
            ...http.getSortPayload(props.listSorting),
            ...getFilterPayload(),
          })
        ).data
      : await props.fetchPromise!({
          ...getPaginationPayload(),
          ...http.getSortPayload(props.listSorting),
          ...getFilterPayload(),
        })
    paginationResponse.value = response.meta
    return response.data
  } catch (err: any) {
    error.value = err.response?.data?.message || err.message
    emit('error', error)
  } finally {
    emit('loading', false)
  }
}

/**
 * Gets the filter related payload to send to the api.
 * Wraps the keys in the filter[] syntax and transform the queries if a transform function is given.
 * If merchantId is given, adds merchant_id to the filters.
 */
const getFilterPayload = () => {
  const filters = { ...props.filterValues }
  if (props.merchantId) {
    filters.merchant_id = props.merchantId
  }
  const syntax = http.wrapKeysInApiFilterSyntax(props.transformFiltersToApiQuery(filters))

  return syntax
}

/**
 * Gets the pagination related payload to send to the API
 */
const getPaginationPayload = (): {
  cursor: string | undefined
  page: string | number | undefined
  page_size: number
} => {
  return {
    cursor: cursor.value,
    page: page.value,
    page_size: props.pageSize,
  }
}

const onLoadMorePagination = async (val: string) => {
  cursor.value = val
  showLoadMorePreloader.value = true
  fetchedItems.value.push(...(await fetchExternalData()))
  showLoadMorePreloader.value = false
}
const onCursorPagination = (val: string) => {
  cursor.value = val
  updateQueryKeysAndFetchData({ cursor: val })
  emit('cursor', val)
}

const onPagedPagination = (val: number) => {
  page.value = val
  updateQueryKeysAndFetchData({ page: val })
  emit('page', val)
}

/**
 * Updates specific query values (only the given keys)
 */
const updateQueryKeysAndFetchData = (query: LocationQueryRaw) => {
  setQueryAndFetchData({
    ...route.query,
    ...query,
  })
}

/**
 * Overrides the entire query with the given object and fetches the data.
 * @param query
 */
const setQueryAndFetchData = (query: LocationQueryRaw) => {
  if (props.syncFilterStateWithQueries) {
    router.replace({
      query,
    })
  }
  nextTick(() => {
    refreshTable()
  })
}

const resetFiltersAndFetchData = () => {
  emit('update:filter-values', {})
  setQueryAndFetchData({
    page: page.value || undefined,
    cursor: cursor.value || undefined,
  })
}

const resetStateAndFetchData = () => {
  cursor.value = undefined
  page.value = undefined
  resetFiltersAndFetchData()
}

const onFilterSubmit = () => {
  cursor.value = undefined
  page.value = undefined
  setQueryAndFetchData(http.wrapKeysInApiFilterSyntax(props.filterValues) as unknown as LocationQueryRaw)
}

defineExpose({
  resetStateAndFetchData,
  refreshTable,
  fetchedItems,
})
</script>
