<template>
  <div
    ref="button"
    class="menu-section"
    :class="{
      'menu-section--dropdown-open': isInflated && links && !navigationExpanded,
      'menu-section--section-active': active && !navigationExpanded,
    }"
  >
    <MenuButton
      ref="button"
      class="menu-section__button"
      :caret-direction="isInflated && menuType === 'overlay' ? 'up' : isInflated ? 'down' : undefined"
      :to="to"
      :has-children="links && links.length > 0"
      active-class="tw-bg-gray-white tw-text-gray-black hover:tw-text-white hover:tw-bg-gray-7 focus:tw-text-gray-black focus:tw-outline-gray-8"
      :icon="icon"
      :text="text"
      @click="$emit('click')"
    />
    <div v-if="links && links.length > 0">
      <FadeTransition v-if="!navigationExpanded">
        <!-- Menu on right side of nav -->
        <MenuDropdown
          v-if="isInflated"
          :button-element="button!"
          :label="text"
          :items="links"
          @close="$emit('close')"
        />
      </FadeTransition>
      <CollapseHeightTransition v-else-if="menuType === 'overlay'">
        <!-- Overlay type menu (used for buttons at the bottom of nav) -->
        <MenuOverlaySection
          v-if="isInflated"
          :button-element="button!"
          :links="links"
          @close="$emit('close')"
        />
      </CollapseHeightTransition>
      <CollapseHeightTransition v-else>
        <!-- Regular menu (expanded below button) -->
        <MenuCollapseSection
          v-if="isInflated"
          :links="links"
          @close="$emit('close')"
        />
      </CollapseHeightTransition>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LuiIconName } from '@loomispay/loomis-ui'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import type { LinkSectionLink } from '@/interfaces/Frontend/Navigation'

import MenuButton from '@/components/Layout/Navigation/MenuButton.vue'
import MenuCollapseSection from '@/components/Layout/Navigation/MenuCollapseSection.vue'
import MenuDropdown from '@/components/Layout/Navigation/MenuDropdown.vue'
import MenuOverlaySection from '@/components/Layout/Navigation/MenuOverlaySection.vue'
import CollapseHeightTransition from '@/components/Transitions/CollapseHeightTransition.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'
import { useNavigationStore } from '@/stores/navigation'

withDefaults(
  defineProps<{
    menuType?: 'collapse' | 'overlay'
    text: string
    /**
     * Decides if the dropdown (when present) should align top of this component or bottom.
     */
    bottomAlignDropdown?: boolean
    active?: boolean
    isInflated?: boolean
    href?: string
    to?: RouteLocationRaw
    icon?: LuiIconName | 'avatar'
    isSubMenu?: boolean
    links?: LinkSectionLink[]
  }>(),
  {
    menuType: 'collapse',
    bottomAlignDropdown: false,
    active: false,
    isInflated: false,
    href: undefined,
    to: undefined,
    icon: undefined,
    isSubMenu: false,
    links: undefined,
  },
)

defineEmits<{
  (event: 'close'): void
  (event: 'click'): void
}>()

const { expanded: navigationExpanded } = storeToRefs(useNavigationStore())

const button = ref<HTMLElement | null>(null)
</script>

<style lang="scss">
.menu-section {
  $self: &;
  font-size: 1rem;

  &__button {
    border: 4px solid transparent;
    transition:
      background-color 150ms ease-in-out,
      border-bottom-color 150ms ease-in-out;
  }

  &--dropdown-open {
    #{$self}__button {
      @apply tw-bg-gray-7;
    }
  }

  &--section-active {
    #{$self}__button {
      @apply tw-border-y-4 tw-border-b-gray-white tw-border-t-transparent;
    }
  }
}
</style>
