<template>
  <!-- Table height is set to (an arbitrary) tw-h-1 - otherwise content in cells can't be set to 100% height -->
  <table class="tw-w-full tw-h-1 tw-tabular-nums tw-table-fixed tw-text-md lg:tw-text-sm">
    <slot name="header">
      <thead
        v-if="columns && !noHeading"
        :class="headerClass"
      >
        <BasicTableRow
          class="lg:tw-table-row tw-border-b tw-border-b-opacity-black-7"
          :class="{ 'tw-hidden': !showHeaderOnMobile }"
          :height="height"
        >
          <template
            v-for="(column, index) in computedColumns"
            :key="`basic-table__th--${String(index)}`"
          >
            <BasicTableHeading
              v-if="!column.disabled"
              :class="[calculateHeaderPadding(), column.class, column.headerClass]"
              :sort="column.sort"
              :sort-active="column.sort !== undefined && sort?.column === column.sort"
              :sort-direction="sort?.direction"
              @click="emit('table-heading-clicked', column)"
            >
              <slot :name="column.headingSlot">
                <span class="tw-inline-flex tw-items-center"
                  >{{ column.text }}
                  <LuiIcon
                    v-if="column.tooltip"
                    v-tooltip="column.tooltip"
                    name="info"
                    class="tw-ml-1 tw-shrink-0"
                  />
                </span>
              </slot>
            </BasicTableHeading>
          </template>
        </BasicTableRow>
      </thead>
    </slot>
    <slot>
      <tbody
        v-if="!items?.length && (emptyMessage || $slots.empty || showEmptyMessage)"
        :id="bodyId"
      >
        <tr>
          <td :colspan="computedColumns?.length || 0">
            <slot name="empty">
              <div class="tw-text-center tw-p-10 tw-text-gray-7 tw-w-full tw-py-6">
                {{ emptyMessage || $t('global.listEmptyState') }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
      <tbody
        v-else
        :id="bodyId"
      >
        <BasicTableRow
          v-for="(item, index) in items"
          :key="`responsive-table--${keyFunction ? keyFunction(item) : index}`"
          :class="getRowClasses(item, index)"
          :style="getRowStyles(item, index)"
          :is-clickable="getRowIsClickable(item, index)"
          :is-expandable="getRowIsExpandable(item, index)"
          :height="height"
        >
          <slot
            :name="`row-${index}`"
            :item="item"
            :index="index"
          >
            <template
              v-for="(column, columnIndex) in computedColumns"
              :key="`responsive-table--row-${index}--cell-${String(columnIndex)}`"
            >
              <BasicTableCell
                v-if="!column.disabled"
                :inner-class="calculateCellPadding()"
                class="tw-group"
                :column="column"
                :table-item="item"
                @click="() => (column.disableClick ? null : emit('row-clicked', { item, index, columnIndex }))"
              >
                <slot
                  :name="column.slot"
                  :item="item"
                  :index="index"
                />
              </BasicTableCell>
            </template>
          </slot>
        </BasicTableRow>
      </tbody>
    </slot>
    <slot name="footer" />
  </table>
</template>

<script setup lang="ts" generic="T = any">
import { computed } from 'vue'

import type { BasicTableColumn, BasicTableHeight, ListSorting } from '@/interfaces/Frontend/BasicTable'

import BasicTableCell from '@/components/Lists/BasicTableCell.vue'
import BasicTableHeading from '@/components/Lists/BasicTableHeading.vue'
import BasicTableRow from '@/components/Lists/BasicTableRow.vue'
import { resolveFunctionOrValue } from '@/utils/string/resolveFunctionOrValue'

export type Props<T> = {
  sort?: ListSorting
  keyFunction?: (item: any) => string
  bodyId?: string
  columns?: BasicTableColumn<T>[] | Record<string, BasicTableColumn<T>>
  items?: T[]
  rowClasses?: string | ((item: any, index?: number) => string)
  rowStyles?: string | ((item: any, index?: number) => any)
  rowIsClickable?: boolean | ((item: any, index?: number) => boolean)
  rowIsExpandable?: boolean | ((item: any, index?: number) => boolean)
  noHeading?: boolean
  height?: BasicTableHeight
  emptyMessage?: string
  showEmptyMessage?: boolean
  headerClass?: string
  showHeaderOnMobile?: boolean
}

const props = defineProps<Props<T>>()

const emit = defineEmits<{
  'row-clicked': [
    {
      item: T
      index: number
      columnIndex?: number
    },
  ]
  'table-heading-clicked': [BasicTableColumn<T>]
}>()

// Always return an array of columns, event if the prop is a Record<string, BasicTableColumn>. We allow both because of legacy reasons.
const computedColumns = computed(() => {
  if (!props.columns) {
    return undefined
  }

  return Array.isArray(props.columns) ? props.columns : Object.values(props.columns)
})

/**
 * Calculates the padding that should be applied to each cell.
 */
const calculateCellPadding = (): string => {
  let classes = 'group-first-of-type:tw-pl-0 tw-px-2 '
  /*
   * If it's the first column, and item is either clickable or expandable add a left padding on desktop.
   * This is because desktop has a hover effect and if the cell value hits the edge of the row while being hovered
   * it looks weird.
   */
  if (props.rowIsClickable || props.rowIsExpandable) {
    classes += 'group-first-of-type:lg:tw-pl-2 '
  }
  if (props.rowIsExpandable) {
    /*
     * If it's the last column, and item is expandable add a right padding for the expand/collapse indicator.
     */
    classes += 'group-last-of-type:tw-pr-8 '
  } else {
    /*
     * If it's the last column and items is not expandable nor clickable, add a right padding of 0.
     */
    classes += 'group-last-of-type:tw-pr-0'
  }

  return classes
}
/**
 * Exactly the same as calculateCellPadding, but for the header (without the "group"-directive).
 */
const calculateHeaderPadding = () => {
  let classes = 'first-of-type:tw-pl-0 tw-px-2 '
  if (props.rowIsClickable || props.rowIsExpandable) {
    classes += 'first-of-type:lg:tw-pl-2 '
  }
  if (props.rowIsExpandable) {
    classes += 'last-of-type:tw-pr-8 '
  } else {
    classes += 'last-of-type:tw-pr-0'
  }

  return classes
}
/**
 * Gets the classes that should be applied to the row.
 * If the rowClasses prop is a function, it will be called with the row as the first argument.
 * If it's a string it will return the string.
 * @param row
 * @param index
 * @returns {String|*}
 */
const getRowClasses = (row: any, index: number) => {
  return resolveFunctionOrValue(props.rowClasses, row, index)
}
const getRowStyles = (row: any, index: number) => {
  return resolveFunctionOrValue(props.rowStyles, row, index)
}
const getRowIsClickable = (row: any, index: number) => {
  return resolveFunctionOrValue(props.rowIsClickable, row, index)
}
const getRowIsExpandable = (row: any, index: number) => {
  return resolveFunctionOrValue(props.rowIsExpandable, row, index)
}
</script>
