import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () => import('@/pages/Applications/Stock/Configurations/Container.vue')
const CreateConfiguration = () => import('@/pages/Applications/Stock/Configurations/Create.vue')
const EditConfiguration = () => import('@/pages/Applications/Stock/Configurations/Edit.vue')
const ConfigurationList = () => import('@/pages/Applications/Stock/Configurations/List.vue')
const ItemContainer = () => import('@/pages/Applications/Stock/Items/Container.vue')
const EditItem = () => import('@/pages/Applications/Stock/Items/Edit.vue')
const LocationItemContainer = () => import('@/pages/Applications/Stock/LocationItems/Container.vue')
const EditLocationItem = () => import('@/pages/Applications/Stock/LocationItems/Edit.vue')
const LocationContainer = () => import('@/pages/Applications/Stock/Locations/Container.vue')
const EditLocation = () => import('@/pages/Applications/Stock/Locations/Edit.vue')
const CreateStatusImport = () => import('@/pages/Applications/Stock/StatusImports/Create.vue')
const StatusContainer = () => import('@/pages/Applications/Stock/Statuses/Container.vue')
const CreateStatus = () => import('@/pages/Applications/Stock/Statuses/Create.vue')
const EditStatus = () => import('@/pages/Applications/Stock/Statuses/Edit.vue')
const TransferContainer = () => import('@/pages/Applications/Stock/Transfers/Container.vue')
const CreateTransfer = () => import('@/pages/Applications/Stock/Transfers/Create.vue')
const EditTransfer = () => import('@/pages/Applications/Stock/Transfers/Edit.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  STOCK_CONFIGURATIONS: 'STOCK_CONFIGURATIONS',
  STOCK_CONFIGURATIONS_CREATE: 'STOCK_CONFIGURATIONS_CREATE',
  STOCK_CONFIGURATIONS_EDIT: 'STOCK_CONFIGURATIONS_EDIT',
  STOCK_CONFIGURATIONS_SHOW: 'STOCK_CONFIGURATIONS_SHOW',
  STOCK_ITEMS_EDIT: 'STOCK_ITEMS_EDIT',
  STOCK_ITEMS_SHOW: 'STOCK_ITEMS_SHOW',
  STOCK_LOCATIONS_EDIT: 'STOCK_LOCATIONS_EDIT',
  STOCK_LOCATIONS_SHOW: 'STOCK_LOCATIONS_SHOW',
  STOCK_LOCATIONS_STATUSES_CREATE: 'STOCK_LOCATIONS_STATUSES_CREATE',
  STOCK_LOCATIONS_STATUSES_EDIT: 'STOCK_LOCATIONS_STATUSES_EDIT',
  STOCK_LOCATIONS_STATUSES_SHOW: 'STOCK_LOCATIONS_STATUSES_SHOW',
  STOCK_LOCATION_ITEMS_EDIT: 'STOCK_LOCATION_ITEMS_EDIT',
  STOCK_LOCATION_ITEMS_SHOW: 'STOCK_LOCATION_ITEMS_SHOW',
  STOCK_TRANSFERS_EDIT: 'STOCK_TRANSFERS_EDIT',
  STOCK_TRANSFERS_SHOW: 'STOCK_TRANSFERS_SHOW',
  STOCK_LOCATIONS_STATUS_IMPORTS_CREATE: 'STOCK_LOCATIONS_STATUS_IMPORTS_CREATE',
  STOCK_LOCATIONS_TRANSFERS_CREATE: 'STOCK_LOCATIONS_TRANSFERS_CREATE',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'stock/configurations',
    meta: { localeFilename: 'stock' },
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.STOCK_CONFIGURATIONS,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.STOCK_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.STOCK_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.STOCK_CONFIGURATIONS_EDIT }),
          },
          {
            path: 'edit',
            name: routeNames.STOCK_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
          },
          {
            path: 'items/:itemId',
            component: ItemContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.STOCK_ITEMS_SHOW,
                redirect: () => ({ name: routeNames.STOCK_ITEMS_EDIT }),
              },
              {
                path: 'edit',
                name: routeNames.STOCK_ITEMS_EDIT,
                component: EditItem,
              },
            ],
          },
          {
            path: 'locations/:locationId',
            component: LocationContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.STOCK_LOCATIONS_SHOW,
                redirect: () => ({ name: routeNames.STOCK_LOCATIONS_EDIT }),
              },
              {
                path: 'edit',
                name: routeNames.STOCK_LOCATIONS_EDIT,
                component: EditLocation,
              },
              {
                path: 'statuses/create',
                name: routeNames.STOCK_LOCATIONS_STATUSES_CREATE,
                component: CreateStatus,
              },
              {
                path: 'statuses/:statusId',
                component: StatusContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: routeNames.STOCK_LOCATIONS_STATUSES_SHOW,
                    redirect: () => ({ name: routeNames.STOCK_LOCATIONS_STATUSES_EDIT }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.STOCK_LOCATIONS_STATUSES_EDIT,
                    component: EditStatus,
                  },
                ],
              },
              {
                path: 'status-imports/create',
                name: routeNames.STOCK_LOCATIONS_STATUS_IMPORTS_CREATE,
                component: CreateStatusImport,
              },
              {
                path: 'transfers/create',
                name: routeNames.STOCK_LOCATIONS_TRANSFERS_CREATE,
                component: CreateTransfer,
              },
              {
                path: 'transfers/:transferId',
                component: TransferContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: routeNames.STOCK_TRANSFERS_SHOW,
                    redirect: () => ({ name: routeNames.STOCK_TRANSFERS_EDIT }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.STOCK_TRANSFERS_EDIT,
                    component: EditTransfer,
                  },
                ],
              },
              {
                path: 'location-items/:locationItemId',
                component: LocationItemContainer,
                props: true,
                children: [
                  {
                    path: '',
                    name: routeNames.STOCK_LOCATION_ITEMS_SHOW,
                    redirect: () => ({ name: routeNames.STOCK_LOCATION_ITEMS_EDIT }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.STOCK_LOCATION_ITEMS_EDIT,
                    component: EditLocationItem,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
