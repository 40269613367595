import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  WINBAG_STORES_LIST: 'WINBAG_STORES_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'winbag',
    component: () => import('@/pages/Applications/Winbag/WinbagStoresList.vue'),
    name: routeNames.WINBAG_STORES_LIST,
  },
]

export default routes
