import { type RouteRecordRaw } from 'vue-router'

import Blank from '@/pages/Blank.vue'
import { Section } from '@/router/shared/constants'

export const routeNames = {
  TABLES_RESTAURANT_LIST: 'TABLES_RESTAURANT_LIST',
  TABLES_RESTAURANT: 'TABLES_RESTAURANT',
  TABLES_SECTION: 'TABLES_SECTION',
  TABLES_SECTION_FLOOR_PLAN: 'TABLES_SECTION_FLOOR_PLAN',
  TABLES_SECTION_TABLES: 'TABLES_SECTION_TABLES',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'tables',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.TABLES_RESTAURANT_LIST,
        component: () => import('@/pages/Tables/TablesRestaurantList.vue'),
      },
      {
        path: ':restaurantId',
        name: routeNames.TABLES_RESTAURANT,
        props: true,
        component: () => import('@/pages/Tables/TablesRestaurant.vue'),
      },
      {
        path: ':restaurantId/sections/:sectionId',
        name: routeNames.TABLES_SECTION,
        props: true,
        component: () => import('@/pages/Tables/TablesSection.vue'),
        redirect: { name: routeNames.TABLES_SECTION_FLOOR_PLAN },
        children: [
          {
            path: 'floor-plan',
            name: routeNames.TABLES_SECTION_FLOOR_PLAN,
            props: true,
            component: () => import('@/pages/Tables/TablesSectionFloorPlan.vue'),
          },
          {
            path: 'tables',
            name: routeNames.TABLES_SECTION_TABLES,
            props: true,
            component: () => import('@/pages/Tables/TablesSectionTables.vue'),
          },
        ],
      },
    ],
  },
]

export default routes
