import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

const ConnectionContainer = () => import('@/pages/Administration/Connections/ConnectionContainer.vue')
const CreateConnectionSelectConnectionType = () =>
  import('@/pages/Administration/Connections/CreateConnectionSelectConnectionType.vue')
const CreateConnectionForm = () => import('@/pages/Administration/Connections/CreateConnectionForm.vue')
const EditConnection = () => import('@/pages/Administration/Connections/EditConnection.vue')
const ConnectionList = () => import('@/pages/Administration/Connections/ListConnections.vue')
const ConnectionPrintJobsList = () => import('@/pages/Administration/Connections/ConnectionPrintJobsList.vue')
const ReconfigureConnection = () => import('@/pages/Administration/Connections/ReconfigureConnection.vue')
const OrderExportList = () => import('@/pages/Administration/Connections/ConnectionOrderExportList.vue')
const ProductMappingList = () => import('@/pages/Administration/Connections/ConnectionProductMappingsList.vue')
const CreateTerminal = () => import('@/pages/Stores/Terminals/TerminalCreate.vue')
const EditTerminal = () => import('@/pages/Stores/Terminals/TerminalEdit.vue')
const TerminalList = () => import('@/pages/Stores/Terminals/TerminalList.vue')

const CreateReceipt = () => import('@/pages/Stores/Receipts/ReceiptsCreate.vue')
const EditReceipt = () => import('@/pages/Stores/Receipts/ReceiptsEdit.vue')
const ReceiptList = () => import('@/pages/Stores/Receipts/ReceiptsList.vue')

const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ADMINISTRATION_WAITERS_LIST: 'ADMINISTRATION_WAITERS_LIST',
  ADMINISTRATION_WAITER_CREATE: 'ADMINISTRATION_WAITER_CREATE',
  ADMINISTRATION_WAITER_EDIT: 'ADMINISTRATION_WAITER_EDIT',
  ADMINISTRATION_WAITER_EDIT_REDIRECT: 'ADMINISTRATION_WAITER_EDIT_REDIRECT',

  ADMINISTRATION_CONNECTIONS_LIST: 'ADMINISTRATION_CONNECTIONS_LIST',
  ADMINISTRATION_CONNECTION_EDIT: 'ADMINISTRATION_CONNECTION_EDIT',
  ADMINISTRATION_CONNECTION_SELECT_TYPE: 'ADMINISTRATION_CONNECTION_SELECT_TYPE',
  ADMINISTRATION_CONNECTION_CREATE: 'ADMINISTRATION_CONNECTION_CREATE',
  ADMINISTRATION_CONNECTION_PRODUCT_MAPPINGS: 'ADMINISTRATION_CONNECTION_PRODUCT_MAPPINGS',
  ADMINISTRATION_CONNECTION_ORDER_EXPORT: 'ADMINISTRATION_CONNECTION_ORDER_EXPORT',
  ADMINISTRATION_CONNECTION_RECONFIGURE: 'ADMINISTRATION_CONNECTION_RECONFIGURE',
  ADMINISTRATION_CONNECTION_PRINT_JOBS: 'ADMINISTRATION_CONNECTION_PRINT_JOBS',

  ADMINISTRATION_TERMINALS_LIST: 'ADMINISTRATION_TERMINALS_LIST',
  ADMINISTRATION_TERMINAL_CREATE: 'ADMINISTRATION_TERMINAL_CREATE',
  ADMINISTRATION_TERMINAL_EDIT: 'ADMINISTRATION_TERMINAL_EDIT',

  ADMINISTRATION_IMAGES_LIST: 'ADMINISTRATION_IMAGES_LIST',

  ADMINISTRATION_CASH_BOXES_LIST: 'ADMINISTRATION_CASH_BOXES_LIST',
  ADMINISTRATION_CASH_BOX_CREATE: 'ADMINISTRATION_CASH_BOX_CREATE',
  ADMINISTRATION_CASH_BOX_EDIT: 'ADMINISTRATION_CASH_BOX_EDIT',
  ADMINISTRATION_CASH_BOX_EDIT_REDIRECT: 'ADMINISTRATION_CASH_BOX_EDIT_REDIRECT',

  ADMINISTRATION_DISCOUNT_BUTTONS_LIST: 'ADMINISTRATION_DISCOUNT_BUTTONS_LIST',
  ADMINISTRATION_DISCOUNT_BUTTON_CREATE: 'ADMINISTRATION_DISCOUNT_BUTTON_CREATE',
  ADMINISTRATION_DISCOUNT_BUTTON_EDIT: 'ADMINISTRATION_DISCOUNT_BUTTON_EDIT',
  ADMINISTRATION_DISCOUNT_BUTTON_EDIT_REDIRECT: 'ADMINISTRATION_DISCOUNT_BUTTON_EDIT_REDIRECT',

  ADMINISTRATION_RECEIPTS_LIST: 'ADMINISTRATION_RECEIPTS_LIST',
  ADMINISTRATION_RECEIPT_CREATE: 'ADMINISTRATION_RECEIPT_CREATE',
  ADMINISTRATION_RECEIPT_EDIT: 'ADMINISTRATION_RECEIPT_EDIT',
  ADMINISTRATION_RECEIPT_EDIT_REDIRECT: 'ADMINISTRATION_RECEIPT_EDIT_REDIRECT',

  ADMINISTRATION_STORES_LIST: 'ADMINISTRATION_STORES_LIST',
  ADMINISTRATION_STORE_CREATE: 'ADMINISTRATION_STORE_CREATE',
  ADMINISTRATION_STORE_EDIT: 'ADMINISTRATION_STORE_EDIT',
  ADMINISTRATION_STORE_EDIT_REDIRECT: 'ADMINISTRATION_STORE_EDIT_REDIRECT',

  RESERVATIONS_STORE_LIST: 'ADMINISTRATION_RESERVATIONS_STORE_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'terminals',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        component: TerminalList,
        name: routeNames.ADMINISTRATION_TERMINALS_LIST,
      },
      {
        path: 'create',
        component: CreateTerminal,
        name: routeNames.ADMINISTRATION_TERMINAL_CREATE,
      },
      {
        path: ':terminalId/edit',
        component: EditTerminal,
        props: true,
        name: routeNames.ADMINISTRATION_TERMINAL_EDIT,
      },
    ],
  },

  {
    path: 'images',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_IMAGES_LIST,
        component: () => import('@/pages/Administration/Images/List.vue'),
      },
    ],
  },

  {
    path: 'cash-drawers',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_CASH_BOXES_LIST,
        component: () => import('@/pages/Stores/CashDrawers/CashDrawersList.vue'),
      },
      {
        path: 'create',
        name: routeNames.ADMINISTRATION_CASH_BOX_CREATE,
        component: () => import('@/pages/Stores/CashDrawers/CashDrawersCreate.vue'),
      },
      {
        path: ':cashDrawerId',
        name: routeNames.ADMINISTRATION_CASH_BOX_EDIT,
        component: () => import('@/pages/Stores/CashDrawers/CashDrawersEdit.vue'),
        props: true,
      },
      {
        path: ':cashDrawerId/edit',
        name: routeNames.ADMINISTRATION_CASH_BOX_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.ADMINISTRATION_CASH_BOX_EDIT }),
      },
    ],
  },

  {
    path: 'discount-buttons',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_DISCOUNT_BUTTONS_LIST,
        component: () => import('@/pages/Stores/DiscountButtons/DiscountButtonsList.vue'),
      },
      {
        path: 'create',
        name: routeNames.ADMINISTRATION_DISCOUNT_BUTTON_CREATE,
        component: () => import('@/pages/Stores/DiscountButtons/DiscountButtonsCreate.vue'),
      },
      {
        path: ':discountButtonId',
        name: routeNames.ADMINISTRATION_DISCOUNT_BUTTON_EDIT,
        component: () => import('@/pages/Stores/DiscountButtons/DiscountButtonsEdit.vue'),
        props: true,
      },
      {
        path: ':discountButtonId/edit',
        name: routeNames.ADMINISTRATION_DISCOUNT_BUTTON_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.ADMINISTRATION_DISCOUNT_BUTTON_EDIT }),
      },
    ],
  },

  {
    path: 'staff',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_WAITERS_LIST,
        component: () => import('@/pages/Stores/Waiters/WaiterList.vue'),
      },
      {
        path: 'create',
        name: routeNames.ADMINISTRATION_WAITER_CREATE,
        component: () => import('@/pages/Stores/Waiters/WaiterCreate.vue'),
      },
      {
        name: routeNames.ADMINISTRATION_WAITER_EDIT,
        path: ':waiterId',
        component: () => import('@/pages/Stores/Waiters/WaiterEdit.vue'),
        props: true,
      },
      {
        path: ':waiterId/edit',
        name: routeNames.ADMINISTRATION_WAITER_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.ADMINISTRATION_WAITER_EDIT }),
      },
    ],
  },

  {
    path: 'receipts',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_RECEIPTS_LIST,
        component: ReceiptList,
      },
      {
        path: 'create',
        name: routeNames.ADMINISTRATION_RECEIPT_CREATE,
        component: CreateReceipt,
        props: true,
      },
      {
        path: ':receiptId',
        name: routeNames.ADMINISTRATION_RECEIPT_EDIT,
        component: EditReceipt,
        props: true,
      },
      {
        path: ':receiptId/edit',
        name: routeNames.ADMINISTRATION_RECEIPT_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.ADMINISTRATION_RECEIPT_EDIT }),
      },
    ],
  },
  {
    path: 'connections',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_CONNECTIONS_LIST,
        component: ConnectionList,
      },
      {
        path: 'create',
        component: CreateConnectionSelectConnectionType,
        name: routeNames.ADMINISTRATION_CONNECTION_SELECT_TYPE,
      },
      {
        path: 'create/:connectionDriverId',
        component: CreateConnectionForm,
        props: true,
        name: routeNames.ADMINISTRATION_CONNECTION_CREATE,
      },
      {
        path: ':connectionId',
        component: ConnectionContainer,
        props: true,
        children: [
          {
            path: '',
            redirect: () => ({ name: routeNames.ADMINISTRATION_CONNECTION_EDIT }),
          },
          {
            path: 'edit',
            name: routeNames.ADMINISTRATION_CONNECTION_EDIT,
            component: EditConnection,
            meta: { subsection: 'edit' },
          },
          {
            path: 'product-mappings',
            name: routeNames.ADMINISTRATION_CONNECTION_PRODUCT_MAPPINGS,
            component: ProductMappingList,
            meta: { subsection: 'product-mappings' },
          },
          {
            path: 'order-export',
            name: routeNames.ADMINISTRATION_CONNECTION_ORDER_EXPORT,
            component: OrderExportList,
            meta: { subsection: 'order-export' },
          },
          {
            path: 'print-jobs',
            component: ConnectionPrintJobsList,
            name: routeNames.ADMINISTRATION_CONNECTION_PRINT_JOBS,
            meta: { subsection: 'print-jobs' },
          },
          {
            path: 'reconfigure',
            name: routeNames.ADMINISTRATION_CONNECTION_RECONFIGURE,
            component: ReconfigureConnection,
            meta: { subsection: 'reconfigure' },
          },
        ],
      },
    ],
  },

  {
    path: 'locations',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.ADMINISTRATION_STORES_LIST,
        component: () => import('@/pages/Stores/Stores/StoreList.vue'),
      },

      {
        path: 'create',
        name: routeNames.ADMINISTRATION_STORE_CREATE,
        component: () => import('@/pages/Stores/Stores/StoreCreate.vue'),
      },
      {
        path: ':storeId',
        name: routeNames.ADMINISTRATION_STORE_EDIT,
        component: () => import('@/pages/Stores/Stores/StoreEdit.vue'),
        props: true,
      },
      {
        path: ':storeId/edit',
        name: routeNames.ADMINISTRATION_STORE_EDIT_REDIRECT,
        redirect: () => ({ name: routeNames.ADMINISTRATION_STORE_EDIT }),
      },
    ],
  },
]

export default routes
