import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  ACCOUNTS_CONFIGURATIONS_LIST: 'ACCOUNTS_CONFIGURATIONS_LIST',
  ACCOUNTS_CONFIGURATION_CREATE: 'ACCOUNTS_CONFIGURATION_CREATE',
  ACCOUNTS_CONFIGURATION_SHOW: 'ACCOUNTS_CONFIGURATION_SHOW',
  ACCOUNTS_ACCOUNT_LIST: 'ACCOUNTS_ACCOUNT_LIST',
  ACCOUNTS_ACCOUNT_CREATE: 'ACCOUNTS_ACCOUNT_CREATE',
  ACCOUNTS_ACCOUNT_IMPORT: 'ACCOUNTS_ACCOUNT_IMPORT',
  ACCOUNTS_EDIT_ACCOUNT: 'ACCOUNTS_EDIT_ACCOUNT',
  ACCOUNTS_EDIT_ACCOUNT_GROUP: 'ACCOUNTS_EDIT_ACCOUNT_GROUP',
  ACCOUNTS_ACCOUNT_GROUP_LIST: 'ACCOUNTS_ACCOUNT_GROUP_LIST',
  ACCOUNTS_ACCOUNT_GROUP_CREATE: 'ACCOUNTS_ACCOUNT_GROUP_CREATE',
  ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_LIST: 'ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_LIST',
  ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_EDIT: 'ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_EDIT',
  ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_CREATE: 'ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_CREATE',
  ACCOUNTS_LOCATION_EDIT: 'ACCOUNTS_LOCATION_EDIT',
  ACCOUNTS_ORDERS_LIST: 'ACCOUNTS_ORDERS_LIST',
  ACCOUNTS_ORDER_LINES_LIST: 'ACCOUNTS_ORDER_LINES_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'accounts/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.ACCOUNTS_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/Accounts/Configurations/AccountsConfigurationList.vue'),
      },
      {
        path: 'create',
        name: routeNames.ACCOUNTS_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/Accounts/Configurations/AccountsConfigurationCreate.vue'),
      },
      {
        path: ':configurationId',
        name: routeNames.ACCOUNTS_CONFIGURATION_SHOW,
        component: () => import('@/pages/Applications/Accounts/Configurations/Edit/AccountsConfigurationEdit.vue'),
        props: true,
        children: [
          {
            path: 'accounts',
            name: routeNames.ACCOUNTS_ACCOUNT_LIST,
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsAccountList.vue'),
            meta: { subsection: 'accounts' },
          },
          {
            path: 'accounts/create',
            name: routeNames.ACCOUNTS_ACCOUNT_CREATE,
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsCreateAccount.vue'),
            meta: { subsection: 'accounts' },
          },
          {
            path: 'accounts/import',
            name: routeNames.ACCOUNTS_ACCOUNT_IMPORT,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsImportAccounts.vue'),
            meta: { subsection: 'accounts' },
          },
          {
            name: routeNames.ACCOUNTS_EDIT_ACCOUNT,
            path: 'accounts/:accountId/edit',
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsEditAccount.vue'),
            meta: { subsection: 'accounts' },
            props: true,
          },
          {
            path: 'account-groups',
            name: routeNames.ACCOUNTS_ACCOUNT_GROUP_LIST,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsAccountGroupList.vue'),
            meta: { subsection: 'account-groups' },
          },
          {
            path: 'account-groups/create',
            name: routeNames.ACCOUNTS_ACCOUNT_GROUP_CREATE,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsCreateAccountGroup.vue'),
            meta: { subsection: 'account-groups' },
          },
          {
            name: routeNames.ACCOUNTS_EDIT_ACCOUNT_GROUP,
            path: 'account-groups/:accountGroupId/edit',
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsEditAccountGroup.vue'),
            meta: { subsection: 'account-groups' },
            props: true,
          },
          {
            path: 'locations/:locationId/edit',
            name: routeNames.ACCOUNTS_LOCATION_EDIT,
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsEditLocation.vue'),
            meta: { subsection: 'account-groups' },
            props: true,
          },
          {
            path: 'orders',
            name: routeNames.ACCOUNTS_ORDERS_LIST,
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsOrderList.vue'),
            meta: { subsection: 'orders' },
          },
          {
            path: 'order-lines',
            name: routeNames.ACCOUNTS_ORDER_LINES_LIST,
            component: () => import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsOrderLineList.vue'),
            meta: { subsection: 'order-lines' },
          },
          {
            path: 'scheduled-account-imports',
            name: routeNames.ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_LIST,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsScheduledAccountImportList.vue'),
            meta: { subsection: 'scheduled-account-imports' },
          },
          {
            path: 'scheduled-account-imports/create',
            name: routeNames.ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_CREATE,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsCreateScheduledAccountImport.vue'),
            meta: { subsection: 'scheduled-account-imports' },
          },
          {
            path: 'scheduled-account-imports/:scheduledAccountImportId/edit',
            name: routeNames.ACCOUNTS_SCHEDULED_ACCOUNT_IMPORT_EDIT,
            component: () =>
              import('@/pages/Applications/Accounts/Configurations/Edit/Tabs/AccountsEditScheduledAccountImport.vue'),
            meta: { subsection: 'scheduled-account-imports' },
            props: true,
          },
        ],
      },
    ],
  },
]

export default routes
