import { computed, onMounted } from 'vue'
import type { Ref } from 'vue'

import type { FormGroupProps } from '@/interfaces/Frontend/FormGroup'

export const useFormGroup = (props: FormGroupProps, inputElementRef?: Ref<HTMLElement | null>) => {
  onMounted(() => {
    if (props.focusOnMounted) {
      inputElementRef?.value?.focus && inputElementRef?.value?.focus()
    }
  })

  const hasHelpText = computed(() => !!props.helpText)
  const hasError = computed(() => !!props.error)

  return {
    hasHelpText,
    hasError,
  }
}
