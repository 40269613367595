import type { RouteRecordRaw } from 'vue-router'

const BundleMappingList = () => import('@/pages/Applications/Nexudus/BundleMappings/NexudusBundleMappingsList.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/Nexudus/Configurations/NexudusConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Nexudus/Configurations/NexudusCreateConfiguration.vue')
const EditConfiguration = () => import('@/pages/Applications/Nexudus/Configurations/NexudusConfigurationEdit.vue')
const ConfigurationList = () => import('@/pages/Applications/Nexudus/Configurations/NexudusConfigurationsList.vue')
const FinancialAccountMappingList = () =>
  import('@/pages/Applications/Nexudus/FinancialAccountMappings/NexudusFinancialAccountMappingsList.vue')
const ProductMappingList = () => import('@/pages/Applications/Nexudus/ProductMappings/NexudusProductMappingsList.vue')
const TransferContainer = () => import('@/pages/Applications/Nexudus/Transfers/NexudusTransferContainer.vue')
const EditTransfer = () => import('@/pages/Applications/Nexudus/Transfers/NexudusTransferDetails.vue')
const TransferList = () => import('@/pages/Applications/Nexudus/Transfers/NexudusTransferList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  NEXUDUS_CONFIGURATIONS_LIST: 'NEXUDUS_CONFIGURATIONS_LIST',
  NEXUDUS_CONFIGURATIONS_CREATE: 'NEXUDUS_CONFIGURATIONS_CREATE',
  NEXUDUS_CONFIGURATIONS_SHOW: 'NEXUDUS_CONFIGURATIONS_SHOW',
  NEXUDUS_CONFIGURATIONS_EDIT: 'NEXUDUS_CONFIGURATIONS_EDIT',
  NEXUDUS_CONFIGURATIONS_TRANSFERS: 'NEXUDUS_CONFIGURATIONS_TRANSFERS',
  NEXUDUS_CONFIGURATIONS_TRANSFERS_SHOW: 'NEXUDUS_CONFIGURATIONS_TRANSFERS_SHOW',
  NEXUDUS_CONFIGURATIONS_TRANSFERS_EDIT: 'NEXUDUS_CONFIGURATIONS_TRANSFERS_EDIT',
  NEXUDUS_CONFIGURATIONS_BUNDLE_MAPPINGS: 'NEXUDUS_CONFIGURATIONS_BUNDLE_MAPPINGS',
  NEXUDUS_CONFIGURATIONS_PRODUCT_MAPPINGS: 'NEXUDUS_CONFIGURATIONS_PRODUCT_MAPPINGS',
  NEXUDUS_CONFIGURATIONS_FINANCIAL_ACCOUNT_MAPPINGS: 'NEXUDUS_CONFIGURATIONS_FINANCIAL_ACCOUNT_MAPPINGS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'nexudus/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.NEXUDUS_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.NEXUDUS_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.NEXUDUS_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.NEXUDUS_CONFIGURATIONS_TRANSFERS }),
          },
          {
            path: 'transfers',
            name: routeNames.NEXUDUS_CONFIGURATIONS_TRANSFERS,
            component: TransferList,
            meta: { subsection: 'transfers' },
          },
          {
            path: 'product-mappings',
            name: routeNames.NEXUDUS_CONFIGURATIONS_PRODUCT_MAPPINGS,
            component: ProductMappingList,
            meta: { subsection: 'product-mappings' },
          },
          {
            path: 'bundle-mappings',
            name: routeNames.NEXUDUS_CONFIGURATIONS_BUNDLE_MAPPINGS,
            component: BundleMappingList,
            meta: { subsection: 'bundle-mappings' },
          },
          {
            path: 'financial-account-mappings',
            name: routeNames.NEXUDUS_CONFIGURATIONS_FINANCIAL_ACCOUNT_MAPPINGS,
            component: FinancialAccountMappingList,
            meta: { subsection: 'financial-account-mappings' },
          },
          {
            path: 'edit',
            name: routeNames.NEXUDUS_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            meta: { subsection: 'edit' },
          },
          {
            path: 'transfers/:transferId',
            component: TransferContainer,
            props: true,
            meta: { subsection: 'transfers' },
            children: [
              {
                path: '',
                name: routeNames.NEXUDUS_CONFIGURATIONS_TRANSFERS_SHOW,
                redirect: () => ({ name: routeNames.NEXUDUS_CONFIGURATIONS_TRANSFERS_EDIT }),
              },
              {
                path: 'edit',
                name: routeNames.NEXUDUS_CONFIGURATIONS_TRANSFERS_EDIT,
                component: EditTransfer,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
