<template>
  <Multiselect
    ref="multiselect"
    :class="{ 'multiselect--has-error': hasError }"
    multiple
  >
    <template
      v-for="(_, slot) in $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope || {}"
      />
    </template>
  </Multiselect>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'
import type { ComponentExposed } from 'vue-component-type-helpers'
import { Multiselect } from 'vue-multiselect'

const multiselect = ref<ComponentExposed<typeof Multiselect> | null>(null)

defineProps<{
  hasError?: boolean
}>()

onBeforeUnmount(() => {
  /*
  In some very specific cases (input focused and back button on browser clicked without blur) the $refs.search (in vue-multiselect.esm.js) was null.
  The library only checks against undefined values before performing blur(), so it produced an error:

  This 'deactivate' hack below helps until the library checks for nullish values as well.
  */
  multiselect.value?.deactivate()
})
</script>

<style>
fieldset[disabled] .multiselect {
  pointer-events: none;
}

.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 38px;
  height: 36px;
  display: block;
}

.multiselect__spinner::before,
.multiselect__spinner::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.multiselect__spinner::before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}

.multiselect__spinner::after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}

.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: 16px;
  touch-action: manipulation;
}

.multiselect {
  @apply tw-rounded tw-box-border tw-outline-2 tw-flex tw-items-center tw-bg-gray-white tw-w-full tw-cursor-pointer tw-border tw-border-solid tw-border-opacity-black-7 tw-text-gray-1 tw-text-sm tw-relative tw-min-h-[2.5rem];
}

.multiselect * {
  box-sizing: border-box;
}

.multiselect:focus {
  outline: none;
}

.multiselect--disabled {
  @apply tw-bg-gray-15 tw-pointer-events-none tw-opacity-50 tw-border-gray-15;
}

.multiselect--active {
  @apply tw-border-gray-1 tw-outline tw-outline-1 tw-outline-gray-1;
  z-index: 9999;
}

.multiselect--has-error:not(.multiselect--active) {
  @apply tw-border-red-8;
}

.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.multiselect--active .multiselect__select {
  transform: rotateZ(180deg);
}

.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.multiselect__input,
.multiselect__single {
  @apply tw-text-sm tw-my-0.5 tw-p-0;
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  width: calc(100%);
  transition: border 0.1s ease;
  box-sizing: border-box;
  vertical-align: top;
}

.multiselect__input::placeholder {
  color: #35495e;
}

.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}

.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}

.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
}

.multiselect__single {
  @apply tw-truncate;
}

.multiselect__tags-wrap {
  @apply tw-inline-flex tw-items-center tw-w-full tw-flex-wrap tw-gap-2;
}

.multiselect__tags {
  @apply tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-sm tw-overflow-x-hidden tw-w-full tw-h-full tw-pl-3 tw-py-1 tw-pr-8;
}

.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  line-height: 1;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;

  @apply tw-bg-gray-13 tw-text-gray-1 tw-mb-0;
}

.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: initial;
  width: 22px;
  text-align: center;
  line-height: 22px;
  transition: all 0.2s ease;
  border-radius: 5px;
}

.multiselect__tag-icon::after {
  content: '×';
  @apply tw-text-gray-1;
  font-size: 14px;
}

/* // Remove these lines to avoid green closing button
  //.multiselect__tag-icon:focus,
  //.multiselect__tag-icon:hover {
  //  background: #369a6e;
  //} */

.multiselect__tag-icon:focus::after,
.multiselect__tag-icon:hover::after {
  color: white;
}

.multiselect__current {
  line-height: 16px;
  min-height: 40px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  padding: 8px 12px 0;
  padding-right: 30px;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.multiselect__select {
  @apply tw-cursor-pointer tw-block tw-absolute tw-h-8 tw-w-8 tw-min-h-0 tw-top-0.5 tw-right-1 tw-origin-center tw-transform-gpu tw-transition-transform tw-duration-200;
}

.multiselect__select::before {
  content: '';
  @apply tw-absolute tw-w-4 tw-h-4 tw-top-1/2 tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2;
  background: url('data:image/svg+xml;utf8,<svg fill="none" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"> <path clip-rule="evenodd" d="m14.8257 6.27123c.2644.31883.2203.79165-.0985 1.05608l-4.79871 3.97989c-1.1084.9193-2.75015.9193-3.85855 0l-4.79871-3.97989c-.318827-.26443-.362926-.73725-.0985-1.05608.26443-.31883.73725-.36293 1.05608-.0985l4.7987 3.97997c.55309.4587 1.39032.4587 1.9434 0l4.79869-3.97997c.3188-.26443.7917-.22033 1.0561.0985z" fill="currentColor" fill-rule="evenodd"/></svg>')
    no-repeat right;
  z-index: 1;
}

.multiselect__placeholder {
  @apply tw-inline-block tw-w-full tw-m-0 tw-text-gray-8 tw-truncate tw-text-md lg:tw-text-sm;
}

.multiselect--active .multiselect__placeholder {
  display: none;
}

.multiselect__content-wrapper {
  max-width: calc(100vw - 2rem);
  top: calc(100% + 0.25rem);

  @apply tw-absolute tw-block tw-bg-white tw-max-h-60 tw-min-w-full tw-shadow-1 tw-left-0 tw-rounded-t tw-rounded-b-lg tw-p-2 tw-z-50 tw-overflow-auto;
}

.multiselect__content {
  @apply tw-align-top tw-inline-block tw-list-none tw-min-w-full tw-max-w-full tw-p-0 tw-m-0;
}

.multiselect--above .multiselect__content-wrapper {
  top: initial;
  bottom: calc(100% + 0.25rem);
  @apply tw-rounded-b tw-rounded-t-lg;
}

.multiselect__content::-webkit-scrollbar {
  display: none;
}

.multiselect__element {
  display: block;
}

.multiselect__option {
  @apply tw-truncate tw-text-gray-1 tw-text-sm tw-p-3 tw-min-h-[2.5rem] tw-leading-4 tw-block tw-normal-case tw-align-middle tw-relative tw-cursor-pointer tw-whitespace-nowrap tw-no-underline tw-rounded;
}

.multiselect__option::after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}

.multiselect__option--highlight {
  @apply tw-bg-gray-13 tw-text-gray-black;
}

.multiselect__option--selected::after {
  top: 0;
  right: 1rem;
  content: '';
  position: absolute;
  width: 24px;
  height: 100%;
  display: inline-block;
  background-size: 1rem 1rem;
  background: url('data:image/svg+xml;utf8,<svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m19.7421 6.18401c.3126.27177.3457.74549.074 1.05808l-8.5347 9.81681c-1.0936 1.2579-3.04588 1.2615-4.14408.0076l-2.95145-3.3699c-.27291-.3116-.24154-.7854.07006-1.0583.31159-.2729.78543-.2416 1.05834.07l2.95145 3.3699c.49918.5699 1.38657.5683 1.88368-.0034l8.5347-9.81687c.2717-.31259.7455-.34569 1.058-.07392z" fill="currentColor" fill-rule="evenodd"/></svg>')
    no-repeat right;
}

.multiselect__option--selected.multiselect__option--highlight {
  @apply tw-bg-gray-13 tw-text-gray-black;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  content: '';
  background: url('data:image/svg+xml;utf8,<svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m19.7421 6.18401c.3126.27177.3457.74549.074 1.05808l-8.5347 9.81681c-1.0936 1.2579-3.04588 1.2615-4.14408.0076l-2.95145-3.3699c-.27291-.3116-.24154-.7854.07006-1.0583.31159-.2729.78543-.2416 1.05834.07l2.95145 3.3699c.49918.5699 1.38657.5683 1.88368-.0034l8.5347-9.81687c.2717-.31259.7455-.34569 1.058-.07392z" fill="currentColor" fill-rule="evenodd"/></svg>')
    no-repeat right;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  @apply tw-opacity-50;
}

.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  cursor: text;
  pointer-events: none;
}

.multiselect__option--group {
  background: #ededed;
  color: #35495e;
}

.multiselect__option--group.multiselect__option--highlight {
  background: #35495e;
  color: #fff;
}

.multiselect__option--group.multiselect__option--highlight::after {
  background: #35495e;
}

.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}

.multiselect__option--group-selected.multiselect__option--highlight {
  background: #ff6a6a;
  color: #fff;
}

.multiselect__option--group-selected.multiselect__option--highlight::after {
  background: #ff6a6a;
  content: attr(data-deselect);
  color: #fff;
}

.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}

*[dir='rtl'] .multiselect {
  text-align: right;
}

*[dir='rtl'] .multiselect__select {
  right: auto;
  left: 1px;
}

*[dir='rtl'] .multiselect__tags {
  padding: 8px 8px 0 40px;
}

*[dir='rtl'] .multiselect__content {
  text-align: right;
}

*[dir='rtl'] .multiselect__option::after {
  right: auto;
  left: 0;
}

*[dir='rtl'] .multiselect__clear {
  right: auto;
  left: 12px;
}

*[dir='rtl'] .multiselect__spinner {
  right: auto;
  left: 1px;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(2turn);
  }
}
</style>
