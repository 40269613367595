import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Pickup/Configurations/PickupConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Pickup/Configurations/PickupConfigurationCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Pickup/Configurations/PickupConfigurationsList.vue')
const ShowConfiguration = () => import('@/pages/Applications/Pickup/Configurations/PickupConfigurationDetails.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  PICKUP_CONFIGURATIONS_LIST: 'PICKUP_CONFIGURATIONS_LIST',
  PICKUP_CONFIGURATION_CREATE: 'PICKUP_CONFIGURATION_CREATE',
  PICKUP_CONFIGURATION_SHOW: 'PICKUP_CONFIGURATION_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'pickup/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.PICKUP_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.PICKUP_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.PICKUP_CONFIGURATION_SHOW,
            component: ShowConfiguration,
          },
        ],
      },
    ],
  },
]

export default routes
