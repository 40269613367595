<template>
  <nav>
    <ul class="tw-flex tw-justify-between tw-items-center tw-w-full tw-gap-x-5">
      <li>
        <button
          :disabled="!hasPreviousPage"
          class="tw-flex tw-items-center tw-bg-none tw-border-none tw-p-1 disabled:tw-opacity-30"
          @click.prevent="first"
        >
          <LuiIcon
            class="tw-mr-1"
            name="double-chevron-left"
            :height="16"
            :width="16"
          />
          <span class="tw-hidden sm:tw-inline">{{ $t('global.first') }}</span>
        </button>
      </li>

      <li class="tw-mr-auto">
        <button
          :disabled="!hasPreviousPage"
          class="tw-flex tw-items-center tw-bg-none tw-border-none tw-p-1 disabled:tw-opacity-30"
          @click.prevent="previous"
        >
          <LuiIcon
            class="tw-mr-1"
            name="chevron-left"
            :height="16"
            :width="16"
          />
          <span class="tw-hidden sm:tw-inline">{{ $t('global.previous') }}</span>
        </button>
      </li>

      <li class="tw-p-1 tw-shrink-0">{{ currentPage }} / {{ lastPage }}</li>

      <li class="tw-ml-auto">
        <button
          :disabled="!hasNextPage"
          class="tw-flex tw-items-center tw-bg-none tw-border-none tw-p-1 disabled:tw-opacity-30"
          @click.prevent="next"
        >
          <span class="tw-hidden sm:tw-inline">{{ $t('global.next') }}</span>
          <LuiIcon
            class="tw-ml-1"
            name="chevron-right"
            :height="16"
            :width="16"
          />
        </button>
      </li>

      <li>
        <button
          :disabled="!hasNextPage"
          class="tw-flex tw-items-center tw-bg-none tw-border-none tw-p-1 disabled:tw-opacity-30"
          @click.prevent="last"
        >
          <span class="tw-hidden sm:tw-inline">{{ $t('global.last') }}</span>
          <LuiIcon
            class="tw-ml-1"
            name="double-chevron-right"
            :height="16"
            :width="16"
          />
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: { currentPage: {}, lastPage: {} },
  emits: ['navigate'],

  computed: {
    hasPreviousPage() {
      return this.currentPage !== 1
    },

    hasNextPage() {
      return this.currentPage !== this.lastPage
    },
  },

  methods: {
    first() {
      if (this.hasPreviousPage) {
        this.$emit('navigate', 1)
      }
    },

    previous() {
      if (this.hasPreviousPage) {
        this.$emit('navigate', this.currentPage - 1)
      }
    },

    next() {
      if (this.hasNextPage) {
        this.$emit('navigate', this.currentPage + 1)
      }
    },

    last() {
      if (this.hasNextPage) {
        this.$emit('navigate', this.lastPage)
      }
    },
  },
}
</script>
