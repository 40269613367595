import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  PLANDAY_CONFIGURATIONS_LIST: 'PLANDAY_CONFIGURATIONS_LIST',
  PLANDAY_CONFIGURATION_CREATE: 'PLANDAY_CONFIGURATION_CREATE',
  PLANDAY_CONFIGURATION_SHOW: 'PLANDAY_CONFIGURATION_SHOW',
  PLANDAY_CONFIGURATION_EDIT: 'PLANDAY_CONFIGURATION_EDIT',
  PLANDAY_CONFIGURATIONS_TRANSFERS: 'PLANDAY_CONFIGURATIONS_TRANSFERS',
  PLANDAY_CONFIGURATION_STORE_MAPPINGS: 'PLANDAY_CONFIGURATION_STORE_MAPPINGS',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'planday/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.PLANDAY_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/Planday/Configurations/PlandayConfigurationsList.vue'),
      },
      {
        path: 'create',
        name: routeNames.PLANDAY_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/Planday/Configurations/PlandayConfigurationCreate.vue'),
      },
      {
        path: ':configurationId',
        component: () => import('@/pages/Applications/Planday/Configurations/PlandayConfigurationContainer.vue'),
        props: true,
        children: [
          {
            path: '',
            name: routeNames.PLANDAY_CONFIGURATION_SHOW,
            redirect: () => ({ name: routeNames.PLANDAY_CONFIGURATIONS_TRANSFERS }),
          },
          {
            path: 'edit',
            name: routeNames.PLANDAY_CONFIGURATION_EDIT,
            component: () => import('@/pages/Applications/Planday/Configurations/PlandayConfigurationEdit.vue'),
            meta: { subsection: 'edit' },
          },
          {
            path: 'store-mappings',
            name: routeNames.PLANDAY_CONFIGURATION_STORE_MAPPINGS,
            component: () => import('@/pages/Applications/Planday/StoreMappings/PlandayStoreMappingsList.vue'),
            meta: { subsection: 'store-mappings' },
          },
          {
            path: 'transfers',
            name: routeNames.PLANDAY_CONFIGURATIONS_TRANSFERS,
            component: () => import('@/pages/Applications/Planday/Transfers/PlandayTransfersList.vue'),
            meta: { subsection: 'transfers' },
          },
        ],
      },
    ],
  },
]

export default routes
