<template>
  <PortalModal
    :title="$t('genericError.heading')"
    @close="$emit('close')"
  >
    <template #body-and-footer>
      <div>
        <p
          v-if="errorMessage"
          class="tw-font-lg tw-whitespace-pre-wrap tw-mt-1.5"
        >
          {{ errorMessage }}
        </p>
        <i18n-t
          keypath="genericError.message"
          class="tw-font-lg tw-whitespace-pre-wrap tw-mt-1.5"
          scope="global"
        >
          <template #link>
            <UILink
              class="tw-underline"
              to="https://help.loomispay.com"
              >{{ $t('genericError.message.supportLinkText') }}
            </UILink>
          </template>
        </i18n-t>
      </div>

      <div class="tw-mt-6">
        <div class="tw-flex tw-justify-end">
          <LuiButton
            class="tw-w-fit tw-mx-2"
            emphasis="secondary"
            @click="reloadHandler"
          >
            {{ $t('genericErrorModal.buttons.reloadPage') }}
          </LuiButton>

          <LuiButton @click="$emit('close')">
            {{ $t('genericErrorModal.buttons.abort') }}
          </LuiButton>
        </div>
      </div>
    </template>
  </PortalModal>
</template>

<script setup lang="ts">
import PortalModal from '@/components/Modals/Templates/PortalModal.vue'
import UILink from '@/components/UI/UILink.vue'

defineProps<{
  errorMessage?: string
}>()

defineEmits<{
  close: []
}>()

const reloadHandler = () => {
  window.location.reload()
}
</script>
