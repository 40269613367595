import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useEnterEmailToDownloadModalStore = defineStore('enter-email-to-download-modal-store', () => {
  const downloadFunction = ref<null | ((email: string) => Promise<void>)>(null)

  const showEnterEmailToDownloadModal = (inputDownloadFunction: (email: string) => Promise<void>) => {
    downloadFunction.value = inputDownloadFunction
  }

  return {
    downloadFunction,
    showEnterEmailToDownloadModal,
  }
})
