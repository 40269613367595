import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/es'
import 'dayjs/locale/sv'
import 'dayjs/locale/da'


import { i18n } from '@/i18n'
import { currencyFormatter, numberFormatter } from '@/utils/string/formatterFactory'

const currentLocale = i18n.global?.locale?.value?.substring(0, 2) || 'en'

dayjs.locale(currentLocale)

/**
 * Returns a localized numeric value
 *
 * @param {Number} val
 * @param fractionDigits
 * @param alternativeZeroValue
 */
export const formatNumber = (val: number, fractionDigits = 2, alternativeZeroValue: string | null = null) => {
  const formatter = numberFormatter(fractionDigits)
  return !val ? alternativeZeroValue || formatter.format(0) : formatter.format(val)
}

/**
 * Returns a localized amount with currency.
 *
 * @param {Number} val
 * @param currency
 * @param alternativeZeroValue
 */
export const formatCurrency = (val: number, currency: string, alternativeZeroValue?: string) => {
  const formatter = currencyFormatter(currency)
  return !val ? alternativeZeroValue || formatter.format(0) : formatter.format(val)
}

export const formatDate = (value: string): string => {
  if (!value) {
    return ''
  }
  return dayjs(value).format('DD MMM YYYY')
}

export const formatDatetime = (value: string) => {
  if (!value) {
    return ''
  }

  return dayjs(value).format('DD MMM YYYY, HH:mm')
}

export const formatTime = (value: string) => {
  if (!value) {
    return ''
  }

  return dayjs(value).format('HH:mm')
}

export const formatFullDatetime = (value: string) => {
  if (!value) {
    return ''
  }

  return dayjs(value).format('DD MMM YYYY, HH:mm:ss')
}

export const localizeWeekdayIndex = (value: string | number) => {
  switch (String(value)) {
    case '1':
      return i18n.global.t('global.weekdayNames.monday')
    case '2':
      return i18n.global.t('global.weekdayNames.tuesday')
    case '3':
      return i18n.global.t('global.weekdayNames.wednesday')
    case '4':
      return i18n.global.t('global.weekdayNames.thursday')
    case '5':
      return i18n.global.t('global.weekdayNames.friday')
    case '6':
      return i18n.global.t('global.weekdayNames.saturday')
    case '7':
      return i18n.global.t('global.weekdayNames.sunday')
    default:
      return ''
  }
}
