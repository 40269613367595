<template>
  <div :class="{ 'tw-flex tw-flex-col': layout === 'column' }">
    <label
      v-for="(option, index) in options"
      :key="index"
      class="tw-font-normal tw-mr-4 tw-whitespace-nowrap tw-leading-7"
    >
      <input
        type="checkbox"
        :name="name"
        :value="option.value"
        :checked="(value || []).indexOf(option.value) > -1"
        :disabled="!!disabled"
        class="checkbox"
        @click.stop="clickHandler(option.value)"
      />
      <span class="tw-ml-3">{{ option.label }}</span>
    </label>
  </div>
</template>

<script setup lang="ts">
export interface CheckboxWidgetOption {
  label: string
  value: string | number
}

export type CheckboxWidgetLayoutProp = 'column' | 'row'

const props = withDefaults(
  defineProps<{
    name?: string
    value?: (string | number)[]
    options: CheckboxWidgetOption[]
    disabled?: boolean
    layout?: 'column' | 'row'
  }>(),
  {
    name: undefined,
    value: undefined,
    layout: 'row',
  },
)

const emit = defineEmits<{
  'update:value': [(string | number)[]]
}>()

const clickHandler = (checkboxValue: string | number) => {
  let newValue = props.value ? [...props.value] : []
  // Toggle the value in the array
  newValue = newValue.includes(checkboxValue)
    ? newValue.filter((item) => item !== checkboxValue)
    : [...newValue, checkboxValue]
  emit('update:value', newValue)
}
</script>

<style lang="scss" scoped>
// Styling grabbed from tailwind forms
.checkbox {
  print-color-adjust: exact;
  --tw-shadow: 0 0 #0000;

  @apply tw-bg-white tw-border-gray-8 tw-border tw-rounded tw-text-gray-black focus:tw-ring-gray-10 focus:tw-ring-2 tw-w-5 tw-h-5 tw-appearance-none tw-p-0 tw-inline-block tw-bg-origin-border tw-align-middle tw-select-none tw-shrink-0;

  &:checked {
    @apply tw-bg-center tw-bg-no-repeat;
    background-size: 100% 100%;
    background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
  }
}
</style>
