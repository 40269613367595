<template>
  <UIAlert
    v-for="message in visibleMessages"
    :key="message.id"
    :message="message"
    class="tw-w-full tw-flex tw-justify-between tw-items-center tw-rounded-none"
    :level="message.attributes.level"
  >
    <div>
      {{ message.attributes.content }}
      <UILink
        v-if="message.attributes.button_text && message.attributes.button_url"
        class="tw-underline"
        :to="message.attributes.button_url"
        :target="message.attributes.button_target"
        @click="message.attributes.button_event && sendEvent(message.attributes.button_event)"
      >
        {{ message.attributes.button_text }}
      </UILink>
    </div>
    <button @click="discardMessage(message)">
      <LuiIcon name="close" />
    </button>
  </UIAlert>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import UIAlert from '@/components/UI/UIAlert.vue'
import UILink from '@/components/UI/UILink.vue'
import { sendEvent } from '@/shared/ProductAnalyticsService'
import { useMessagesStore } from '@/stores/messages-store'

//TODO: Implement clearing of discarded inactive messages, create messages Pinia store and use useStorage for reactive sync with localStorage https://vueuse.org/core/usestorage/
const { visibleMessages } = storeToRefs(useMessagesStore())
const { loadMessages, discardMessage } = useMessagesStore()

loadMessages()
</script>
