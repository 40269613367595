import type { NavigationGuard } from 'vue-router'

import { SUPPORT_LOCALES, i18n, loadLanguageAsync } from '@/i18n'

export const localeMiddleware: NavigationGuard = async (to, from, next) => {
  if (to.meta.localeFilename) {
    const selectedLocale = i18n.global.locale
    await loadLanguageAsync(selectedLocale.value as SUPPORT_LOCALES, to.meta.localeFilename as string)
  }
  next()
}
