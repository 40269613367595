import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () => import('@/pages/Applications/Invoicing/Configurations/Container.vue')
const CreateConfiguration = () => import('@/pages/Applications/Invoicing/Configurations/Create.vue')
const EditConfiguration = () => import('@/pages/Applications/Invoicing/Configurations/Edit.vue')
const ConfigurationList = () => import('@/pages/Applications/Invoicing/Configurations/List.vue')
const CreateInvoiceRecipient = () => import('@/pages/Applications/Invoicing/InvoiceRecipients/Create.vue')
const EditInvoiceRecipient = () => import('@/pages/Applications/Invoicing/InvoiceRecipients/Edit.vue')
const InvoiceRecipientList = () => import('@/pages/Applications/Invoicing/InvoiceRecipients/List.vue')
const EditInvoice = () => import('@/pages/Applications/Invoicing/Invoices/Edit.vue')
const InvoiceList = () => import('@/pages/Applications/Invoicing/Invoices/List.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  INVOICING_CONFIGURATIONS_LIST: 'INVOICING_CONFIGURATIONS_LIST',
  INVOICING_CONFIGURATIONS_CREATE: 'INVOICING_CONFIGURATIONS_CREATE',
  INVOICING_CONFIGURATIONS_EDIT: 'INVOICING_CONFIGURATIONS_EDIT',
  INVOICING_CONFIGURATIONS_SHOW: 'INVOICING_CONFIGURATIONS_SHOW',
  INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS: 'INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS',
  INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_CREATE: 'INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_CREATE',
  INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_EDIT: 'INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_EDIT',
  INVOICING_CONFIGURATIONS_INVOICES_LIST: 'INVOICING_CONFIGURATIONS_INVOICES_LIST',
  INVOICING_CONFIGURATIONS_INVOICE_EDIT: 'INVOICING_CONFIGURATIONS_INVOICE_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'invoicing/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.INVOICING_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.INVOICING_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.INVOICING_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS }),
          },
          {
            path: 'invoice-recipients',
            name: routeNames.INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS,
            component: InvoiceRecipientList,
            meta: { subsection: 'invoice-recipients' },
          },
          {
            path: 'invoice-recipients/create',
            name: routeNames.INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_CREATE,
            component: CreateInvoiceRecipient,
            meta: { subsection: 'invoice-recipients' },
          },
          {
            path: 'invoice-recipients/:invoiceRecipientId/edit',
            name: routeNames.INVOICING_CONFIGURATIONS_INVOICE_RECIPIENTS_EDIT,
            component: EditInvoiceRecipient,
            meta: { subsection: 'invoice-recipients' },
            props: true,
          },
          {
            path: 'invoices',
            name: routeNames.INVOICING_CONFIGURATIONS_INVOICES_LIST,
            component: InvoiceList,
            meta: { subsection: 'invoices' },
          },
          {
            path: 'invoices/:invoiceId/edit',
            component: EditInvoice,
            name: routeNames.INVOICING_CONFIGURATIONS_INVOICE_EDIT,
            meta: { subsection: 'invoices' },
            props: true,
          },
          {
            path: 'edit',
            name: routeNames.INVOICING_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            meta: { subsection: 'edit' },
          },
        ],
      },
    ],
  },
]

export default routes
