<template>
  <div
    class="tw-text-center"
    :style="{ 'margin-top': margin, 'margin-bottom': margin }"
  >
    <LuiLoadingSpinner
      :width="formattedSize"
      :height="formattedSize"
    />
  </div>
</template>

<script lang="ts" setup>
import { LuiLoadingSpinner } from '@loomispay/loomis-ui'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large'
    margin?: string
  }>(),
  {
    size: 'medium',
    margin: '0px',
  },
)

const formattedSize = computed(() => {
  if (props.size === 'small') {
    return undefined
  }
  if (props.size === 'medium') {
    return 32
  }
  return 64
})
</script>
