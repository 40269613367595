import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Survey/Configurations/SurveyConfigurationsContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Survey/Configurations/SurveyConfigurationsCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Survey/Configurations/SurveyConfigurationsList.vue')
const CreateQuestion = () => import('@/pages/Applications/Survey/Questions/SurveyQuestionsCreate.vue')
const EditQuestion = () => import('@/pages/Applications/Survey/Questions/SurveyQuestionsEdit.vue')
const EditResponse = () => import('@/pages/Applications/Survey/Responses/SurveyResponsesEdit.vue')
const SurveyContainer = () => import('@/pages/Applications/Survey/Surveys/SurveysContainer.vue')
const CreateSurvey = () => import('@/pages/Applications/Survey/Surveys/SurveysCreate.vue')
const EditSurvey = () => import('@/pages/Applications/Survey/Surveys/SurveysEdit.vue')
const SurveyList = () => import('@/pages/Applications/Survey/Surveys/SurveysList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  SURVEY_CONFIGURATIONS_LIST: 'SURVEY_CONFIGURATIONS_LIST',
  SURVEY_CONFIGURATION_CREATE: 'SURVEY_CONFIGURATION_CREATE',
  SURVEY_CONFIGURATION_SHOW: 'SURVEY_CONFIGURATION_SHOW',
  SURVEY_CONFIGURATIONS_SURVEYS: 'SURVEY_CONFIGURATIONS_SURVEYS',
  SURVEY_CONFIGURATIONS_SURVEYS_CREATE: 'SURVEY_CONFIGURATIONS_SURVEYS_CREATE',
  SURVEY_CONFIGURATIONS_SURVEYS_EDIT: 'SURVEY_CONFIGURATIONS_SURVEYS_EDIT',
  SURVEY_CONFIGURATIONS_SURVEYS_SHOW: 'SURVEY_CONFIGURATIONS_SURVEYS_SHOW',
  SURVEY_QUESTION_EDIT: 'SURVEY_QUESTION_EDIT',
  SURVEY_RESPONSE_EDIT: 'SURVEY_RESPONSE_EDIT',
  SURVEY_CONFIGURATIONS_SURVEYS_QUESTION_CREATE: 'SURVEY_CONFIGURATIONS_SURVEYS_QUESTION_CREATE',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'survey/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.SURVEY_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.SURVEY_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.SURVEY_CONFIGURATION_SHOW,
            redirect: () => ({ name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS }),
          },
          {
            path: 'surveys',
            name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS,
            component: SurveyList,
          },
          {
            path: 'surveys/create',
            name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS_CREATE,
            component: CreateSurvey,
          },
          {
            path: 'surveys/:surveyId',
            component: SurveyContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS_SHOW,
                redirect: () => ({ name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS_EDIT }),
              },
              {
                path: 'edit',
                name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS_EDIT,
                component: EditSurvey,
              },
              {
                path: 'questions/create',
                name: routeNames.SURVEY_CONFIGURATIONS_SURVEYS_QUESTION_CREATE,
                component: CreateQuestion,
              },
              {
                path: 'questions/:questionId/edit',
                name: routeNames.SURVEY_QUESTION_EDIT,
                component: EditQuestion,
                props: true,
              },
              {
                path: 'responses/:responseId/edit',
                name: routeNames.SURVEY_RESPONSE_EDIT,
                component: EditResponse,
                props: true,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
