import type { RouteRecordRaw } from 'vue-router'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Tipping/Configurations/TippingConfigurationContainer.vue')
const CreateConfiguration = () => import('@/pages/Applications/Tipping/Configurations/TippingConfigurationCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Tipping/Configurations/TippingConfigurationList.vue')
const ShowConfiguration = () => import('@/pages/Applications/Tipping/Configurations/TippingConfigurationDetails.vue')
const TippingStoreContainer = () => import('@/pages/Applications/Tipping/TippingStores/TippingStoreContainer.vue')
const ShowTippingStore = () => import('@/pages/Applications/Tipping/TippingStores/TippingStoreDetails.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  TIPPING_CONFIGURATIONS_LIST: 'TIPPING_CONFIGURATIONS_LIST',
  TIPPING_CONFIGURATION_CREATE: 'TIPPING_CONFIGURATION_CREATE',
  TIPPING_CONFIGURATION_SHOW: 'TIPPING_CONFIGURATION_SHOW',
  TIPPING_STORE_SHOW: 'TIPPING_STORE_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'tipping/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.TIPPING_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.TIPPING_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.TIPPING_CONFIGURATION_SHOW,
            component: ShowConfiguration,
          },
          {
            path: 'tipping-stores/:tippingStoreId',
            component: TippingStoreContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.TIPPING_STORE_SHOW,
                component: ShowTippingStore,
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
