import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  COMPLIMENTA_CONFIGURATIONS_LIST: 'COMPLIMENTA_CONFIGURATIONS_LIST',
  COMPLIMENTA_CONFIGURATION_EDIT: 'COMPLIMENTA_CONFIGURATION_EDIT',
  COMPLIMENTA_CONFIGURATION_CREATE: 'COMPLIMENTA_CONFIGURATION_CREATE',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'complimenta/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.COMPLIMENTA_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/Complimenta/Configurations/ComplimentaConfigurationsList.vue'),
      },
      {
        path: 'create',
        name: routeNames.COMPLIMENTA_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/Complimenta/Configurations/ComplimentaConfigurationCreate.vue'),
      },
      {
        path: ':configurationId',
        name: routeNames.COMPLIMENTA_CONFIGURATION_EDIT,
        component: () => import('@/pages/Applications/Complimenta/Configurations/ComplimentaConfigurationDetails.vue'),
        props: true,
      },
    ],
  },
]

export default routes
