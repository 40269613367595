import { defineStore } from 'pinia'
import tailwindConfig from 'tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'

interface State {
  config: ReturnType<typeof resolveConfig> //Tailwind doesn't export type, so we have to use ReturnType
}

export const useTailwindStore = defineStore('tailwind', {
  state: (): State => ({
    config: resolveConfig(tailwindConfig),
  }),
  getters: {
    /**
     * Returns the color for the given color name and number.
     * getColor('green', 1) returns `green-1`
     * @param state
     */
    getColor:
      (state: State) =>
      (colorName: string, shade: number): string => {
        const colors = state.config!.theme!.colors as unknown as Record<string, Record<string, string>>
        if (colors[colorName]) {
          return colors[colorName][shade] as string
        }
        throw new Error(`Color ${colorName} with shade ${shade} not found`)
      },
    screens: (state: State) => {
      if (!state.config.theme?.screens) {
        return {}
      }
      const screens: Record<string, number> = {}
      Object.entries(state.config.theme.screens).forEach(([key, value]) => {
        screens[key] = parseInt(`${value}`, 10)
      })
      return screens
    },
  },
})
