import type { RouteRecordRaw } from 'vue-router'

const AccountMappingList = () =>
  import('@/pages/Applications/Accounting/AccountMappings/AccountingAccountMappingsList.vue')
const AccountingStoreContainer = () =>
  import('@/pages/Applications/Accounting/AccountingStores/AccountingStoreContainer.vue')
const EditAccountingStore = () => import('@/pages/Applications/Accounting/AccountingStores/AccountingStoreEdit.vue')
const AccountingStoresList = () => import('@/pages/Applications/Accounting/AccountingStores/AccountingStoresList.vue')
const AccountList = () => import('@/pages/Applications/Accounting/Accounts/AccountingAccountsList.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/Accounting/Configurations/AccountingConfigurationContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/Accounting/Configurations/AccountingConfigurationCreate.vue')
const EditConfiguration = () => 
  import('@/pages/Applications/Accounting/Configurations/AccountingConfigurationEdit.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/Accounting/Configurations/AccountingConfigurationsList.vue')
const CostCentersList = () =>
  import('@/pages/Applications/Accounting/CostCenters/CostCentersList.vue')
const ExportedTradingDayList = () =>
  import('@/pages/Applications/Accounting/ExportedTradingDays/AccountingExportedTradingDaysList.vue')
const ShowExportedTradingDay = () =>
  import('@/pages/Applications/Accounting/ExportedTradingDays/AccountingExportedTradingDay.vue')
const JournalContainer = () => import('@/pages/Applications/Accounting/Journals/AccountingJournalContainer.vue')
const JournalList = () => import('@/pages/Applications/Accounting/Journals/AccountingJournalList.vue')
const ShowJournal = () => import('@/pages/Applications/Accounting/Journals/AccountingJournalPage.vue')
const CreateVoucherExport = () =>
  import('@/pages/Applications/Accounting/VoucherExports/AccountingVoucherExportsCreate.vue')
const ShowVoucher = () => import('@/pages/Applications/Accounting/Vouchers/AccountingVoucherPage.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ACCOUNTING_CONFIGURATION_LIST: 'ACCOUNTING_CONFIGURATION_LIST',
  ACCOUNTING_CONFIGURATIONS_CREATE: 'ACCOUNTING_CONFIGURATIONS_CREATE',
  ACCOUNTING_CONFIGURATIONS_EDIT: 'ACCOUNTING_CONFIGURATIONS_EDIT',
  ACCOUNTING_CONFIGURATIONS_SHOW: 'ACCOUNTING_CONFIGURATIONS_SHOW',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES: 'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS:
    'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS_SHOW:
    'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS_SHOW',
  ACCOUNTING_CONFIGURATIONS_JOURNALS: 'ACCOUNTING_CONFIGURATIONS_JOURNALS',
  ACCOUNTING_CONFIGURATIONS_JOURNAL: 'ACCOUNTING_CONFIGURATIONS_JOURNAL',
  ACCOUNTING_CONFIGURATIONS_JOURNAL_SHOW: 'ACCOUNTING_CONFIGURATIONS_JOURNAL_SHOW',
  ACCOUNTING_CONFIGURATIONS_JOURNALS_EXPORT: 'ACCOUNTING_CONFIGURATIONS_JOURNALS_EXPORT',
  ACCOUNTING_CONFIGURATIONS_JOURNALS_VOUCHER_SHOW: 'ACCOUNTING_CONFIGURATIONS_JOURNALS_VOUCHER_SHOW',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTS: 'ACCOUNTING_CONFIGURATIONS_ACCOUNTS',
  ACCOUNTING_CONFIGURATIONS_COST_CENTERS: 'ACCOUNTING_CONFIGURATIONS_COST_CENTERS',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_SHOW: 'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_SHOW',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_ACCOUNT_MAPPINGS:
    'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_ACCOUNT_MAPPINGS',
  ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EDIT: 'ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'accounting/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.ACCOUNTING_CONFIGURATION_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.ACCOUNTING_CONFIGURATIONS_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_SHOW,
            redirect: { name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES },
          },
          {
            path: 'edit',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_EDIT,
            component: EditConfiguration,
            props: true,
            meta: { subsection: 'edit' },
          },
          {
            path: 'journals',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_JOURNALS,
            component: JournalList,
            meta: { subsection: 'journals' },
          },
          {
            path: 'journals/:journalId',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_JOURNAL,
            component: JournalContainer,
            meta: { subsection: 'journals' },
            props: true,
            children: [
              {
                path: '',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_JOURNAL_SHOW,
                component: ShowJournal,
              },
              {
                path: 'export',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_JOURNALS_EXPORT,
                component: CreateVoucherExport,
              },
              {
                path: 'vouchers/:accountingYearId/:voucherId',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_JOURNALS_VOUCHER_SHOW,
                component: ShowVoucher,
                meta: { subsection: 'vouchers' },
                props: true,
              },
            ],
          },
          {
            path: 'accounts',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTS,
            component: AccountList,
            meta: { subsection: 'accounts' },
          },
          {
            path: 'accounting-stores',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES,
            component: AccountingStoresList,
            meta: { subsection: 'accounting-stores' },
          },
          {
            path: 'accounting-stores/:accountingStoreId',
            component: AccountingStoreContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_SHOW,
                redirect: {
                  name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS,
                },
              },
              {
                path: 'account-mappings',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_ACCOUNT_MAPPINGS,
                component: AccountMappingList,
                meta: { subsection: 'account-mappings' },
              },
              {
                path: 'edit',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EDIT,
                component: EditAccountingStore,
                meta: { subsection: 'edit' },
              },
              {
                path: 'exported-trading-days',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS,
                component: ExportedTradingDayList,
                meta: { subsection: 'exported-trading-days' },
              },
              {
                path: 'exported-trading-days/:exportedTradingDayId',
                name: routeNames.ACCOUNTING_CONFIGURATIONS_ACCOUNTING_STORES_EXPORTED_TRADING_DAYS_SHOW,
                component: ShowExportedTradingDay,
                meta: { subsection: 'exported-trading-days' },
                props: true,
              },
            ],
          },
          {
            path: 'cost-centers',
            name: routeNames.ACCOUNTING_CONFIGURATIONS_COST_CENTERS,
            component: CostCentersList,
            meta: { subsection: 'cost-centers' },
          },
        ],
      },
    ],
  },
]

export default routes
