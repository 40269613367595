import { type RouteRecordRaw } from 'vue-router'

import Blank from '@/pages/Blank.vue'
import { Section } from '@/router/shared/constants'

export const routeNames = {
  RESERVATIONS_STORE_LIST: 'ADMINISTRATION_RESERVATIONS_STORE_LIST',
  RESERVATIONS_STORE: 'ADMINISTRATION_RESERVATIONS_STORE',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'table-booking',
    component: Blank,
    meta: {
      section: Section.ADMINISTRATION,
    },
    children: [
      {
        path: '',
        name: routeNames.RESERVATIONS_STORE_LIST,
        component: () => import('@/pages/Reservations/ReservationsStoreList.vue'),
      },
      {
        path: 'connections/:connectionId/stores/:storeId',
        name: routeNames.RESERVATIONS_STORE,
        props: true,
        component: () => import('@/pages/Reservations/ReservationsStore.vue'),
      },
    ],
  },
]

export default routes
