import type { RouteRecordRaw } from 'vue-router'

const OAuthCallback = () => import('@/pages/OAuthCallbacks/OAuthCallback.vue')

export const routeNames = {
  OAUTH_CALLBACK: 'OAUTH_CALLBACK',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'oauth-callbacks',
    children: [
      {
        path: ':connectionDriverId',
        name: routeNames.OAUTH_CALLBACK,
        props: true,
        component: OAuthCallback,
      },
    ],
  },
]

export default routes
