import type { AxiosPromise } from 'axios'

import type { BaseApiPagedResponse, BaseApiResponse } from '@/interfaces/BaseApi'
import type {
  PortalForeignMerchantCurrency,
  default as PortalMerchant,
  PortalMerchantAttributes,
  PortalMerchantProductValidator,
  PortalMerchantProductValidatorAttributes,
} from '@/interfaces/PortalMerchant'

import type { BaseApiPagedPromise, BaseApiPromise } from '@/types/api'
import type { PortalId } from '@/types/portal'

import http from '@/http'

const BASE_ENDPOINT = '/api/v2/merchants'

export const fetchMerchant = (merchantId: PortalId): BaseApiPromise<PortalMerchant> =>
  http.get<BaseApiResponse<PortalMerchant>>(`${BASE_ENDPOINT}/${merchantId}`)

export const fetchMerchants = (): BaseApiPagedPromise<PortalMerchant> =>
  http.get<BaseApiPagedResponse<PortalMerchant>>(BASE_ENDPOINT, { page_size: 100 })

export const createMerchant = (attributes: Partial<PortalMerchantAttributes>) =>
  http.post<BaseApiResponse<PortalMerchant>>(BASE_ENDPOINT, attributes)

export const updateMerchant = (
  merchantId: PortalId,
  attributes: PortalMerchantAttributes,
): BaseApiPromise<PortalMerchant> =>
  http.put<BaseApiResponse<PortalMerchant>>(`${BASE_ENDPOINT}/${merchantId}`, attributes)

export const deleteMerchant = (merchantId: PortalId): BaseApiPromise<PortalMerchant> =>
  http.delete(`${BASE_ENDPOINT}/${merchantId}`)

export const fetchForeignCurrenciesForMerchant = (merchantId: PortalId): Promise<PortalForeignMerchantCurrency[]> =>
  http.getList<PortalForeignMerchantCurrency>(`/api/v2/merchant-currencies`, {
    'filter[merchant_id]': merchantId,
    'filter[with_inactive]': 0,
  })

export const fetchMerchantProductValidators = (merchantId: PortalId): BaseApiPromise<PortalMerchantProductValidator> =>
  http.get<BaseApiResponse<PortalMerchantProductValidator>>(`${BASE_ENDPOINT}/${merchantId}/product-validator`)

export const updateMerchantProductValidators = (
  merchantId: PortalId,
  attributes: PortalMerchantProductValidatorAttributes,
): BaseApiPromise<PortalMerchantProductValidator> =>
  http.put<BaseApiResponse<PortalMerchantProductValidator>>(
    `${BASE_ENDPOINT}/${merchantId}/product-validator`,
    attributes,
  )

export const addPackageToMerchant = (merchantId: PortalId, packageId: PortalId): AxiosPromise<void> =>
  http.post<void, void>(`${BASE_ENDPOINT}/${merchantId}/packages`, {
    package_id: packageId,
  })

export const removePackageFromMerchant = (merchantId: PortalId, packageId: PortalId): AxiosPromise<void> =>
  http.delete<void>(`${BASE_ENDPOINT}/${merchantId}/packages/${packageId}`)

export const addExternalOrderProviderToMerchant = (merchantId: PortalId, orderProviderId: PortalId) =>
  http.post<BaseApiResponse<PortalMerchant>>(`${BASE_ENDPOINT}/${merchantId}/external-order-providers`, {
    external_order_provider_id: orderProviderId,
  })

export const removeExternalOrderProviderFromMerchant = (merchantId: PortalId, orderProviderId: PortalId) =>
  http.delete<BaseApiResponse<PortalMerchant>>(
    `${BASE_ENDPOINT}/${merchantId}/external-order-providers/${orderProviderId}`,
  )
