import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  EMPLOYEES_CONFIGURATIONS_LIST: 'EMPLOYEES_CONFIGURATIONS_LIST',
  EMPLOYEES_CONFIGURATION_CREATE: 'EMPLOYEES_CONFIGURATION_CREATE',
  EMPLOYEES_CONFIGURATION_SHOW: 'EMPLOYEES_CONFIGURATION_SHOW',
  EMPLOYEES_CONFIGURATION_COMPANY_LIST: 'EMPLOYEES_CONFIGURATION_COMPANY_LIST',
  EMPLOYEES_CONFIGURATION_COMPANY_CREATE: 'EMPLOYEES_CONFIGURATION_COMPANY_CREATE',
  EMPLOYEES_CONFIGURATION_COMPANY_EDIT: 'EMPLOYEES_CONFIGURATION_COMPANY_EDIT',
  EMPLOYEE_CONFIGURATION_SALES: 'EMPLOYEE_CONFIGURATION_SALES',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_LIST: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_LIST',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_CREATE: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_CREATE',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_EDIT: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_EDIT',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_LIST: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_LIST',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_EDIT: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_EDIT',
  EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_CREATE: 'EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_CREATE',
  EMPLOYEE_CONFIGURATION_EMPLOYEES_IMPORT: 'EMPLOYEE_CONFIGURATION_EMPLOYEES_IMPORT',
  EMPLOYEE_CONFIGURATION_ORDERS_LIST: 'EMPLOYEE_CONFIGURATION_ORDERS_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'employees/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.EMPLOYEES_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/Employees/Configurations/List.vue'),
      },
      {
        path: 'create',
        name: routeNames.EMPLOYEES_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/Employees/Configurations/Create.vue'),
      },
      {
        path: ':configurationId',
        name: routeNames.EMPLOYEES_CONFIGURATION_SHOW,
        component: () => import('@/pages/Applications/Employees/Configurations/Edit/index.vue'),
        props: true,
        children: [
          {
            path: 'companies',
            name: routeNames.EMPLOYEES_CONFIGURATION_COMPANY_LIST,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/CompanyList.vue'),
            meta: { subsection: 'companies' },
          },
          {
            path: 'companies/create',
            name: routeNames.EMPLOYEES_CONFIGURATION_COMPANY_CREATE,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/CreateCompany.vue'),
            meta: { subsection: 'companies' },
          },
          {
            path: 'companies/:companyId/edit',
            name: routeNames.EMPLOYEES_CONFIGURATION_COMPANY_EDIT,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/EditCompany.vue'),
            meta: { subsection: 'companies' },
            props: true,
          },
          {
            path: 'employees',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_LIST,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/EmployeeList.vue'),
            meta: { subsection: 'employees' },
          },
          {
            path: 'employees/create',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_CREATE,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/CreateEmployee.vue'),
            meta: { subsection: 'employees' },
          },
          {
            path: 'employees/:employeeId/edit',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_EDIT,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/EditEmployee.vue'),
            meta: { subsection: 'employees' },
            props: true,
          },
          {
            path: 'employee-stores',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_LIST,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/EmployeeStoreList.vue'),
            meta: { subsection: 'employee-stores' },
          },
          {
            path: 'employee-stores/create',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_CREATE,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/CreateEmployeeStore.vue'),
            meta: { subsection: 'employee-stores' },
          },
          {
            path: 'employee-stores/:employeeStoreId/edit',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEE_STORE_EDIT,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/EditEmployeeStore.vue'),
            meta: { subsection: 'employee-stores' },
            props: true,
          },
          {
            path: 'employees/import',
            name: routeNames.EMPLOYEE_CONFIGURATION_EMPLOYEES_IMPORT,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/ImportEmployees.vue'),
            meta: { subsection: 'employees' },
          },
          {
            path: 'sales',
            name: routeNames.EMPLOYEE_CONFIGURATION_SALES,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/OrderLineList.vue'),
            meta: { subsection: 'sales' },
          },
          {
            path: 'orders',
            name: routeNames.EMPLOYEE_CONFIGURATION_ORDERS_LIST,
            component: () => import('@/pages/Applications/Employees/Configurations/Edit/Tabs/OrderList.vue'),
            meta: { subsection: 'orders' },
          },
        ],
      },
    ],
  },
]

export default routes
