import { LuiToastPosition, useLuiToast } from '@loomispay/loomis-ui'

const { luiSuccessToast, luiDangerToast, luiWarningToast } = useLuiToast()

export default {
  success(message: string) {
    luiSuccessToast(message, { position: LuiToastPosition.TOP_RIGHT })
  },

  warning(message: string) {
    luiWarningToast(message, { position: LuiToastPosition.TOP_RIGHT })
  },

  error(message: string) {
    luiDangerToast(message, { position: LuiToastPosition.TOP_RIGHT })
  },
}
