<template>
  <PortalModal
    v-if="confirmModalContext"
    persistent
  >
    <div class="mt-2">
      {{ confirmModalContext?.text }}
    </div>
    <template #footer>
      <div class="tw-flex tw-justify-end">
        <LuiButton
          v-if="!confirmModalContext?.isAlert"
          class="tw-mr-4"
          emphasis="low"
          @click="cancelHandler"
        >
          {{ $t('global.confirm.cancel') }}
        </LuiButton>
        <LuiButton
          icon="check"
          @click="confirmHandler"
        >
          {{ $t('global.confirm.continue') }}
        </LuiButton>
      </div>
    </template>
  </PortalModal>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { onBeforeUnmount, onMounted } from 'vue'

import PortalModal from '@/components/Modals/Templates/PortalModal.vue'
import { useConfirmModalStore } from '@/stores/confirm-modal-store'

export interface ConfirmModalContext {
  text: string
  confirmCallback: () => void
  cancelCallback?: () => void
  isAlert?: boolean
}

const { confirmModalContext } = storeToRefs(useConfirmModalStore())
const { closeConfirmModal } = useConfirmModalStore()

const cancelHandler = () => {
  confirmModalContext.value?.cancelCallback?.()
  closeConfirmModal()
}

const confirmHandler = () => {
  confirmModalContext.value?.confirmCallback()
  closeConfirmModal()
}

const cancelOnEscape = (event: KeyboardEvent) => {
  if (event.key === 'Escape' && !confirmModalContext.value?.isAlert) {
    cancelHandler()
  }
}

onMounted(() => {
  window.addEventListener('keydown', cancelOnEscape)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', cancelOnEscape)
})
</script>
