export const PortalPermissionNames = {
  ADMIN: 'Admin',
  CREATE_ACCEPTED_ORDERS: 'create-accepted-orders',
  CREATE_ACCOUNT_CARDS_ACCOUNT_EXPORTS: 'create-account-cards-account-exports',
  CREATE_ACCOUNT_CARDS_ACCOUNT_GROUPS: 'create-account-cards-account-groups',
  CREATE_ACCOUNT_CARDS_ACCOUNT_LOCATIONS: 'create-account-cards-account-locations',
  CREATE_ACCOUNT_CARDS_ACCOUNT_LOGIN_SCHEDULES: 'create-account-cards-account-login-schedules',
  CREATE_ACCOUNT_CARDS_ACCOUNT_MANAGERS: 'create-account-cards-account-managers',
  CREATE_ACCOUNT_CARDS_ACCOUNTS: 'create-account-cards-accounts',
  CREATE_ACCOUNT_CARDS_CONFIGURATIONS: 'create-account-cards-configurations',
  CREATE_ACCOUNT_CARDS_DEPOSITS: 'create-account-cards-deposits',
  CREATE_ACCOUNT_CARDS_DISCOUNT_GROUP_SCHEDULES: 'create-account-cards-discount-group-schedules',
  CREATE_ACCOUNT_CARDS_DISCOUNT_GROUPS: 'create-account-cards-discount-groups',
  CREATE_ACCOUNT_CARDS_PRODUCT_RESTRICTIONS: 'create-account-cards-product-restrictions',
  CREATE_ACCOUNT_CARDS_WHITELISTED_PRODUCT_GROUPS: 'create-account-cards-whitelisted-product-groups',
  CREATE_ACCOUNT_CARDS_WITHDRAWALS: 'create-account-cards-withdrawals',
  CREATE_ACCOUNT_CHECKS: 'create-account-checks',
  CREATE_ACCOUNT_GROUP_EMAIL_DOMAINS: 'create-account-group-email-domains',
  CREATE_ACCOUNT_GROUP_LOCATIONS: 'create-account-group-locations',
  CREATE_ACCOUNT_GROUP_PRODUCT_FOLDER_SCHEDULES: 'create-account-group-product-folder-schedules',
  CREATE_ACCOUNT_GROUPS: 'create-account-groups',
  CREATE_ACCOUNT_LOGIN_SCHEDULES: 'create-account-login-schedules',
  CREATE_ACCOUNT_VERIFICATIONS: 'create-account-verifications',
  CREATE_ACCOUNTING_ACCOUNTS: 'create-accounting-accounts',
  CREATE_ACCOUNTING_CONFIGURATIONS: 'create-accounting-configurations',
  CREATE_ACCOUNTING_COST_CENTERS: 'create-accounting-cost-centers',
  CREATE_ACCOUNTING_JOURNALS: 'create-accounting-journals',
  CREATE_ACCOUNTING_STORES: 'create-accounting-stores',
  CREATE_ACCOUNTS: 'create-accounts',
  CREATE_ACCOUNTS_CONFIGURATIONS: 'create-accounts-configurations',
  CREATE_ALEX_ANDERSEN_OLUND_CONFIGURATIONS: 'create-alex-andersen-olund-configurations',
  CREATE_ALEX_ANDERSEN_OLUND_STORES: 'create-alex-andersen-olund-stores',
  CREATE_APPROVED_MEETINGS: 'create-approved-meetings',
  CREATE_AUTHORIZATIONS: 'create-authorizations',
  CREATE_BARCODES: 'create-barcodes',
  CREATE_BUMP_SCREEN_RELOADS: 'create-bump-screen-reloads',
  CREATE_BUMP_SCREENS: 'create-bump-screens',
  CREATE_BUMP_SCREENS_CONFIGURATIONS: 'create-bump-screens-configurations',
  CREATE_BUNDLES: 'create-bundles',
  CREATE_CANCELLATIONS: 'create-cancellations',
  CREATE_CASH_BOX_OPENINGS: 'create-cash-box-openings',
  CREATE_CASH_BOXES: 'create-cash-boxes',
  CREATE_CASH_COUNTS: 'create-cash-counts',
  CREATE_CASH_TRANSACTIONS: 'create-cash-transactions',
  CREATE_CASH_TRANSFERS: 'create-cash-transfers',
  CREATE_CATALOGUE_SYNCS: 'create-catalogue-syncs',
  CREATE_CITY_OF_COPENHAGEN_BANK_DEPOSITS: 'create-city-of-copenhagen-bank-deposits',
  CREATE_CITY_OF_COPENHAGEN_CONFIGURATIONS: 'create-city-of-copenhagen-configurations',
  CREATE_CITY_OF_COPENHAGEN_PAYMENT_METHODS: 'create-city-of-copenhagen-payment-methods',
  CREATE_CITY_OF_COPENHAGEN_STORES: 'create-city-of-copenhagen-stores',
  CREATE_CLIENTS: 'create-clients',
  CREATE_CLOSED_CASH_COUNTS: 'create-closed-cash-counts',
  CREATE_CLOSED_TRADING_DAYS: 'create-closed-trading-days',
  CREATE_COMPANIES: 'create-companies',
  CREATE_COMPANY_PRODUCT_FOLDER_SCHEDULES: 'create-company-product-folder-schedules',
  CREATE_COMPLIMENTA_CONFIGURATIONS: 'create-complimenta-configurations',
  CREATE_COMPLIMENTA_ORDERS: 'create-complimenta-orders',
  CREATE_COMPLIMENTA_PAID_INVOICES: 'create-complimenta-paid-invoices',
  CREATE_COMPLIMENTA_STORES: 'create-complimenta-stores',
  CREATE_CONNECTIONS: 'create-connections',
  CREATE_CREDIT_VOUCHER_MAILS: 'create-credit-voucher-mails',
  CREATE_CREDIT_VOUCHERS: 'create-credit-vouchers',
  CREATE_CUSTOMER_APP_CONFIGURATIONS: 'create-customer-app-configurations',
  CREATE_CUSTOMER_APP_MENU_CATEGORIES: 'create-customer-app-menu-categories',
  CREATE_CUSTOMER_APP_MENU_ITEMS: 'create-customer-app-menu-items',
  CREATE_CUSTOMER_APP_MENUS: 'create-customer-app-menus',
  CREATE_CUSTOMER_APP_PAYMENT_SESSIONS: 'create-customer-app-payment-sessions',
  CREATE_CUSTOMER_APP_QR_CODES: 'create-customer-app-qr-codes',
  CREATE_CUSTOMER_APP_SHOP_ORDERS: 'create-customer-app-shop-orders',
  CREATE_CUSTOMER_APP_SHOPS: 'create-customer-app-shops',
  CREATE_DISCOUNT_BUTTONS: 'create-discount-buttons',
  CREATE_ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULES: 'create-economic-account-group-invoice-schedules',
  CREATE_ECONOMIC_ACCOUNT_GROUP_MAPPINGS: 'create-economic-account-group-mappings',
  CREATE_ECONOMIC_CONFIGURATIONS: 'create-economic-configurations',
  CREATE_ECONOMIC_MODIFIER_MAPPINGS: 'create-economic-modifier-mappings',
  CREATE_ECONOMIC_PRODUCT_GROUP_MAPPINGS: 'create-economic-product-group-mappings',
  CREATE_ECONOMIC_PRODUCT_MAPPINGS: 'create-economic-product-mappings',
  CREATE_ECONOMIC_PRODUCT_SYNCS: 'create-economic-product-syncs',
  CREATE_ECONOMIC_SOURCE_MAPPINGS: 'create-economic-source-mappings',
  CREATE_ECONOMIC_STORE_MAPPINGS: 'create-economic-store-mappings',
  CREATE_ECONOMIC_TRANSFERS: 'create-economic-transfers',
  CREATE_ELAVON_CONFIGURATIONS: 'create-elavon-configurations',
  CREATE_ELAVON_SETTLEMENTS: 'create-elavon-settlements',
  CREATE_ELAVON_STORES: 'create-elavon-stores',
  CREATE_EMPLOYEE_STORES: 'create-employee-stores',
  CREATE_EMPLOYEES: 'create-employees',
  CREATE_EMPLOYEES_CONFIGURATIONS: 'create-employees-configurations',
  CREATE_EXPORTED_TRADING_DAYS: 'create-exported-trading-days',
  CREATE_EXTERNAL_ORDER_PROVIDERS: 'create-external-order-providers',
  CREATE_FAVORITE_PRODUCT_FOLDERS: 'create-favorite-product-folders',
  CREATE_FINANCE_ACCOUNTS: 'create-finance-accounts',
  CREATE_FINISHED_BUMP_SCREEN_TASKS: 'create-finished-bump-screen-tasks',
  CREATE_FINISHED_MEETINGS: 'create-finished-meetings',
  CREATE_FINISHED_ORDERS: 'create-finished-orders',
  CREATE_FINISHED_PREPARATION_TASKS: 'create-finished-preparation-tasks',
  CREATE_IMAGES: 'create-images',
  CREATE_IMPORTS: 'create-imports',
  CREATE_INVOICES_CLOSED_ORDER_INVOICES: 'create-invoices-closed-order-invoices',
  CREATE_INVOICES_CONFIGURATIONS: 'create-invoices-configurations',
  CREATE_INVOICES_INVOICE_RECIPIENTS: 'create-invoices-invoice-recipients',
  CREATE_INVOICING_CONFIGURATIONS: 'create-invoicing-configurations',
  CREATE_INVOICING_INVOICE_RECIPIENTS: 'create-invoicing-invoice-recipients',
  CREATE_ISS_MENU_PLAN_CONFIGURATIONS: 'create-iss-menu-plan-configurations',
  CREATE_ISS_MENU_PLAN_STORES: 'create-iss-menu-plan-stores',
  CREATE_MEETING_FIELDS: 'create-meeting-fields',
  CREATE_MEETING_FOLDERS: 'create-meeting-folders',
  CREATE_MEETING_MENU_ITEMS: 'create-meeting-menu-items',
  CREATE_MEETING_MILESTONE_LINES: 'create-meeting-milestone-lines',
  CREATE_MEETING_MILESTONES: 'create-meeting-milestones',
  CREATE_MEETING_PRODUCTS: 'create-meeting-products',
  CREATE_MEETING_ROOM_GROUPS: 'create-meeting-room-groups',
  CREATE_MEETING_ROOMS: 'create-meeting-rooms',
  CREATE_MEETING_STORES: 'create-meeting-stores',
  CREATE_MEETINGS: 'create-meetings',
  CREATE_MEETINGS_CONFIGURATIONS: 'create-meetings-configurations',
  CREATE_MERCHANT_CURRENCIES: 'create-merchant-currencies',
  CREATE_MERCHANTS: 'create-merchants',
  CREATE_MOBILE_PAY_CONFIGURATIONS: 'create-mobile-pay-configurations',
  CREATE_MOBILE_PAY_ORGANIZATIONS: 'create-mobile-pay-organizations',
  CREATE_MOBILE_PAY_STORE_MAPPINGS: 'create-mobile-pay-store-mappings',
  CREATE_MOBILE_PAY_TERMINAL_MAPPINGS: 'create-mobile-pay-terminal-mappings',
  CREATE_MOBILE_PAY_TOKENS: 'create-mobile-pay-tokens',
  CREATE_MODIFIER_GROUPS: 'create-modifier-groups',
  CREATE_MODIFIERS: 'create-modifiers',
  CREATE_NEXUDUS_BUNDLE_MAPPINGS: 'create-nexudus-bundle-mappings',
  CREATE_NEXUDUS_CONFIGURATIONS: 'create-nexudus-configurations',
  CREATE_NEXUDUS_FINANCIAL_ACCOUNT_MAPPINGS: 'create-nexudus-financial-account-mappings',
  CREATE_NEXUDUS_PRODUCT_MAPPINGS: 'create-nexudus-product-mappings',
  CREATE_NOTICES: 'create-notices',
  CREATE_ONBOARDING_PROFILES: 'create-onboarding-profiles',
  CREATE_ORDER_LINE_NOTES: 'create-order-line-notes',
  CREATE_ORDER_RECEIPTS: 'create-order-receipts',
  CREATE_PACKAGES: 'create-packages',
  CREATE_PAY_WITH_CASH_CONFIGURATIONS: 'create-pay-with-cash-configurations',
  CREATE_PAYMENT_ACCOUNTS: 'create-payment-accounts',
  CREATE_PAYMENT_METHOD_ACCOUNTS: 'create-payment-method-accounts',
  CREATE_PAYMENTS: 'create-payments',
  CREATE_PICKED_UP_ORDERS: 'create-picked-up-orders',
  CREATE_PICKUP_CONFIGURATIONS: 'create-pickup-configurations',
  CREATE_PICKUP_STORES: 'create-pickup-stores',
  CREATE_PLANDAY_CONFIGURATIONS: 'create-planday-configurations',
  CREATE_PLANDAY_STORE_MAPPINGS: 'create-planday-store-mappings',
  CREATE_PLANDAY_TRANSFERS: 'create-planday-transfers',
  CREATE_PREPARATION_CONFIGURATIONS: 'create-preparation-configurations',
  CREATE_PREPARATION_COURSES: 'create-preparation-courses',
  CREATE_PREPARATION_LOCATIONS: 'create-preparation-locations',
  CREATE_PREPARATION_RESPONSIBILITIES: 'create-preparation-responsibilities',
  CREATE_PREPARATION_STATIONS: 'create-preparation-stations',
  CREATE_PRICE_GROUPS: 'create-price-groups',
  CREATE_PRINT_JOBS: 'create-print-jobs',
  CREATE_PRINTERS: 'create-printers',
  CREATE_PRINTING_CONFIGURATIONS: 'create-printing-configurations',
  CREATE_PRODUCT_FOLDER_SCHEDULES: 'create-product-folder-schedules',
  CREATE_PRODUCT_FOLDERS: 'create-product-folders',
  CREATE_PRODUCT_GROUPS: 'create-product-groups',
  CREATE_PRODUCT_MAPPINGS: 'create-product-mappings',
  CREATE_PRODUCT_RESTRICTIONS: 'create-product-restrictions',
  CREATE_PRODUCTS: 'create-products',
  CREATE_READY_ORDERS: 'create-ready-orders',
  CREATE_RECEIPTS: 'create-receipts',
  CREATE_REPORTS: 'create-reports',
  CREATE_RESTAURANTS: 'create-restaurants',
  CREATE_RETAIL_CONFIGURATIONS: 'create-retail-configurations',
  CREATE_RETAIL_FOLDERS: 'create-retail-folders',
  CREATE_RETAIL_MENU_ITEMS: 'create-retail-menu-items',
  CREATE_RETAIL_ORDERS: 'create-retail-orders',
  CREATE_RETAIL_PAYMENT_SESSIONS: 'create-retail-payment-sessions',
  CREATE_RETAIL_PRODUCTS: 'create-retail-products',
  CREATE_RETAIL_STORES: 'create-retail-stores',
  CREATE_RETRIED_COMPLIMENTA_PAID_INVOICES: 'create-retried-complimenta-paid-invoices',
  CREATE_RETRIED_PRINT_JOBS: 'create-retried-print-jobs',
  CREATE_RETRIED_STORED_REPORT_JOBS: 'create-retried-stored-report-jobs',
  CREATE_RETURN_ORDERS: 'create-return-orders',
  CREATE_ROLES: 'create-roles',
  CREATE_SCHEDULED_ACCOUNT_IMPORTS: 'create-scheduled-account-imports',
  CREATE_SECTIONS: 'create-sections',
  CREATE_SELF_SERVICE_BUTTONS: 'create-self-service-buttons',
  CREATE_SELF_SERVICE_CONFIGURATIONS: 'create-self-service-configurations',
  CREATE_SELF_SERVICE_TERMINALS: 'create-self-service-terminals',
  CREATE_SKATTEVERKET_CONFIGURATIONS: 'create-skatteverket-configurations',
  CREATE_SKATTEVERKET_RECEIPTS: 'create-skatteverket-receipts',
  CREATE_SKATTEVERKET_REGISTERED_TERMINALS: 'create-skatteverket-registered-terminals',
  CREATE_SKATTEVERKET_STATUS_CHECKS: 'create-skatteverket-status-checks',
  CREATE_SPECIAL_DAYS: 'create-special-days',
  CREATE_STARTED_PREPARATION_ITEMS: 'create-started-preparation-items',
  CREATE_STOCK_CONFIGURATIONS: 'create-stock-configurations',
  CREATE_STOCK_ITEMS: 'create-stock-items',
  CREATE_STOCK_LOCATION_ITEMS: 'create-stock-location-items',
  CREATE_STOCK_LOCATIONS: 'create-stock-locations',
  CREATE_STOCK_PRODUCT_IMPORTS: 'create-stock-product-imports',
  CREATE_STOCK_STATUS_IMPORTS: 'create-stock-status-imports',
  CREATE_STOCK_STATUSES: 'create-stock-statuses',
  CREATE_STOCK_TRANSFERS: 'create-stock-transfers',
  CREATE_STORE_MAPPINGS: 'create-store-mappings',
  CREATE_STORED_ACCOUNT_EXPORT_REPORTS: 'create-stored-account-export-reports',
  CREATE_STORED_ACCOUNTING_VOUCHERS_REPORTS: 'create-stored-accounting-vouchers-reports',
  CREATE_STORED_BUNDLE_SALES_REPORTS: 'create-stored-bundle-sales-reports',
  CREATE_STORED_CASH_FLOW_REPORTS: 'create-stored-cash-flow-reports',
  CREATE_STORED_CONTAINER_SALES_REPORTS: 'create-stored-container-sales-reports',
  CREATE_STORED_CUSTOM_REPORTS: 'create-stored-custom-reports',
  CREATE_STORED_DANISH_TAX_REPORTS: 'create-stored-danish-tax-reports',
  CREATE_STORED_DASHBOARD_REPORTS: 'create-stored-dashboard-reports',
  CREATE_STORED_DISCOUNT_LEDGER_REPORTS: 'create-stored-discount-ledger-reports',
  CREATE_STORED_DISCOUNTS_REPORTS: 'create-stored-discounts-reports',
  CREATE_STORED_ELAVON_SETTLEMENT_REPORTS: 'create-stored-elavon-settlement-reports',
  CREATE_STORED_ELAVON_TRANSACTIONS_REPORTS: 'create-stored-elavon-transactions-reports',
  CREATE_STORED_FINANCE_ACCOUNT_SALES_REPORTS: 'create-stored-finance-account-sales-reports',
  CREATE_STORED_FINANCIAL_REPORT_REPORTS: 'create-stored-financial-report-reports',
  CREATE_STORED_G69_BANK_DEPOSITS_REPORTS: 'create-stored-g69-bank-deposits-reports',
  CREATE_STORED_G69_REPORTS: 'create-stored-g69-reports',
  CREATE_STORED_ISS_CASH_REGISTER_REPORTS: 'create-stored-iss-cash-register-reports',
  CREATE_STORED_LALANDIA_REPORTS: 'create-stored-lalandia-reports',
  CREATE_STORED_LALANDIA_SUMMARY_REPORTS: 'create-stored-lalandia-summary-reports',
  CREATE_STORED_LEDGER_REPORTS: 'create-stored-ledger-reports',
  CREATE_STORED_MEETING_MILESTONE_LINES_REPORTS: 'create-stored-meeting-milestone-lines-reports',
  CREATE_STORED_MEETING_MILESTONES_REPORTS: 'create-stored-meeting-milestones-reports',
  CREATE_STORED_MEETING_OVERVIEW_REPORTS: 'create-stored-meeting-overview-reports',
  CREATE_STORED_MEETING_RECEIPT_REPORTS: 'create-stored-meeting-receipt-reports',
  CREATE_STORED_MERCHANT_PORTAL_SALES_REPORTS: 'create-stored-merchant-portal-sales-reports',
  CREATE_STORED_MODIFIER_SALES_REPORTS: 'create-stored-modifier-sales-reports',
  CREATE_STORED_NEXUDUS_BOOKING_PRODUCT_SUMMARY_REPORTS: 'create-stored-nexudus-booking-product-summary-reports',
  CREATE_STORED_ORDER_DETAILS_REPORTS: 'create-stored-order-details-reports',
  CREATE_STORED_ORDER_LINES_REPORTS: 'create-stored-order-lines-reports',
  CREATE_STORED_ORDERS_REPORTS: 'create-stored-orders-reports',
  CREATE_STORED_PAYOUT_SUMMARY_REPORTS: 'create-stored-payout-summary-reports',
  CREATE_STORED_PRODUCT_GROUP_SALES_REPORTS: 'create-stored-product-group-sales-reports',
  CREATE_STORED_PRODUCT_LINES_REPORTS: 'create-stored-product-lines-reports',
  CREATE_STORED_PRODUCT_SALES_REPORTS: 'create-stored-product-sales-reports',
  CREATE_STORED_PRODUCTS_REPORTS: 'create-stored-products-reports',
  CREATE_STORED_REPORT_RECIPIENTS: 'create-stored-report-recipients',
  CREATE_STORED_REPORT_SCHEDULES: 'create-stored-report-schedules',
  CREATE_STORED_REPORTS: 'create-stored-reports',
  CREATE_STORED_RETURN_ITEM_SALES_REPORTS: 'create-stored-return-item-sales-reports',
  CREATE_STORED_REVENUE_SUMMARY_REPORTS: 'create-stored-revenue-summary-reports',
  CREATE_STORED_SALARY_REPORT_1_REPORTS: 'create-stored-salary-report-1-reports',
  CREATE_STORED_SALARY_REPORT_2_REPORTS: 'create-stored-salary-report-2-reports',
  CREATE_STORED_SALARY_REPORT_3_REPORTS: 'create-stored-salary-report-3-reports',
  CREATE_STORED_SALARY_REPORT_BILLUND_REPORTS: 'create-stored-salary-report-billund-reports',
  CREATE_STORED_SALES_BY_ACCOUNT_REPORTS: 'create-stored-sales-by-account-reports',
  CREATE_STORED_SALES_BY_ACCOUNT_SUMMARY_REPORTS: 'create-stored-sales-by-account-summary-reports',
  CREATE_STORED_SALES_BY_EMPLOYEE_REPORTS: 'create-stored-sales-by-employee-reports',
  CREATE_STORED_SALES_BY_EMPLOYEE_SUMMARY_REPORTS: 'create-stored-sales-by-employee-summary-reports',
  CREATE_STORED_SEVEN_ELEVEN_ORDERS_REPORTS: 'create-stored-seven-eleven-orders-reports',
  CREATE_STORED_SEVEN_ELEVEN_TRADING_DAYS_REPORTS: 'create-stored-seven-eleven-trading-days-reports',
  CREATE_STORED_SIMPLE_SWEDISH_TAX_REPORTS: 'create-stored-simple-swedish-tax-reports',
  CREATE_STORED_STOCK_INVENTORY_REPORTS: 'create-stored-stock-inventory-reports',
  CREATE_STORED_STOCK_STATUS_RECEIPT_REPORTS: 'create-stored-stock-status-receipt-reports',
  CREATE_STORED_STOCK_TRANSFER_RECEIPT_REPORTS: 'create-stored-stock-transfer-receipt-reports',
  CREATE_STORED_SUMMARIZED_INVOICES_REPORTS: 'create-stored-summarized-invoices-reports',
  CREATE_STORED_SUMMARY_REPORTS: 'create-stored-summary-reports',
  CREATE_STORED_SURVEY_RESULTS_REPORTS: 'create-stored-survey-results-reports',
  CREATE_STORED_TAKEAWAY_MENU_REPORTS: 'create-stored-takeaway-menu-reports',
  CREATE_STORED_TAKEAWAY_RECEIPTS_REPORTS: 'create-stored-takeaway-receipts-reports',
  CREATE_STORED_TERMINALS_REPORTS: 'create-stored-terminals-reports',
  CREATE_STORED_TRADING_DAY_REPORTS: 'create-stored-trading-day-reports',
  CREATE_STORED_TRANSACTIONS_REPORTS: 'create-stored-transactions-reports',
  CREATE_STORED_WAITER_SALES_REPORTS: 'create-stored-waiter-sales-reports',
  CREATE_STORED_WEIGHT_REPORTS: 'create-stored-weight-reports',
  CREATE_STORES: 'create-stores',
  CREATE_SURVEY_CONFIGURATIONS: 'create-survey-configurations',
  CREATE_SURVEY_QUESTIONS: 'create-survey-questions',
  CREATE_SURVEY_RESPONSES: 'create-survey-responses',
  CREATE_SURVEYS: 'create-surveys',
  CREATE_SWISH_CONFIGURATIONS: 'create-swish-configurations',
  CREATE_SWISH_STORES: 'create-swish-stores',
  CREATE_TABLES: 'create-tables',
  CREATE_TABLES_CONFIGURATIONS: 'create-tables-configurations',
  CREATE_TAKEAWAY_CONFIGURATIONS: 'create-takeaway-configurations',
  CREATE_TAKEAWAY_MENU_ITEMS: 'create-takeaway-menu-items',
  CREATE_TAKEAWAY_ORDER_FIELD_GROUPS: 'create-takeaway-order-field-groups',
  CREATE_TAKEAWAY_ORDER_FIELDS: 'create-takeaway-order-fields',
  CREATE_TAKEAWAY_ORDERS: 'create-takeaway-orders',
  CREATE_TAKEAWAY_PAYMENT_SESSIONS: 'create-takeaway-payment-sessions',
  CREATE_TAKEAWAY_STORES: 'create-takeaway-stores',
  CREATE_TERMINAL_EVENTS: 'create-terminal-events',
  CREATE_TERMINAL_START_SCREENS: 'create-terminal-start-screens',
  CREATE_TERMINAL_TYPES: 'create-terminal-types',
  CREATE_TERMINALS: 'create-terminals',
  CREATE_TIPPING_CONFIGURATIONS: 'create-tipping-configurations',
  CREATE_TIPPING_RATES: 'create-tipping-rates',
  CREATE_TIPPING_STORES: 'create-tipping-stores',
  CREATE_TIPPING_TERMINALS: 'create-tipping-terminals',
  CREATE_TRANSACTION_SUMMARIES: 'create-transaction-summaries',
  CREATE_UNFINISHED_BUMP_SCREEN_TASKS: 'create-unfinished-bump-screen-tasks',
  CREATE_UNFINISHED_ORDERS: 'create-unfinished-orders',
  CREATE_USERS: 'create-users',
  CREATE_USURPED_ACCOUNTS: 'create-usurped-accounts',
  CREATE_VAT_RATE_ACCOUNTS: 'create-vat-rate-accounts',
  CREATE_VERIFIED_ACCOUNTS: 'create-verified-accounts',
  CREATE_WAITER_LOGINS: 'create-waiter-logins',
  CREATE_WAITERS: 'create-waiters',
  CREATE_WEB_CONFIGURATIONS: 'create-web-configurations',
  CREATE_WEB_STORE_ACCOUNT_GROUPS: 'create-web-store-account-groups',
  CREATE_WEB_STORE_PAGES: 'create-web-store-pages',
  CREATE_WEB_STORES: 'create-web-stores',
  CREATE_WELCOME_NOTIFICATIONS: 'create-welcome-notifications',
  CREATE_WINBAG_SYNC: 'create-winbag-sync',
  DELETE_ACCOUNT_CARDS_ACCOUNT_GROUPS: 'delete-account-cards-account-groups',
  DELETE_ACCOUNT_CARDS_ACCOUNT_LOCATIONS: 'delete-account-cards-account-locations',
  DELETE_ACCOUNT_CARDS_ACCOUNT_LOGIN_SCHEDULES: 'delete-account-cards-account-login-schedules',
  DELETE_ACCOUNT_CARDS_ACCOUNT_MANAGERS: 'delete-account-cards-account-managers',
  DELETE_ACCOUNT_CARDS_ACCOUNTS: 'delete-account-cards-accounts',
  DELETE_ACCOUNT_CARDS_CONFIGURATIONS: 'delete-account-cards-configurations',
  DELETE_ACCOUNT_CARDS_DISCOUNT_GROUP_SCHEDULES: 'delete-account-cards-discount-group-schedules',
  DELETE_ACCOUNT_CARDS_DISCOUNT_GROUPS: 'delete-account-cards-discount-groups',
  DELETE_ACCOUNT_CARDS_PRODUCT_RESTRICTIONS: 'delete-account-cards-product-restrictions',
  DELETE_ACCOUNT_CARDS_WHITELISTED_PRODUCT_GROUPS: 'delete-account-cards-whitelisted-product-groups',
  DELETE_ACCOUNT_GROUP_EMAIL_DOMAINS: 'delete-account-group-email-domains',
  DELETE_ACCOUNT_GROUP_LOCATIONS: 'delete-account-group-locations',
  DELETE_ACCOUNT_GROUP_PRODUCT_FOLDER_SCHEDULES: 'delete-account-group-product-folder-schedules',
  DELETE_ACCOUNT_GROUPS: 'delete-account-groups',
  DELETE_ACCOUNT_LOGIN_SCHEDULES: 'delete-account-login-schedules',
  DELETE_ACCOUNTING_ACCOUNTS: 'delete-accounting-accounts',
  DELETE_ACCOUNTING_CONFIGURATIONS: 'delete-accounting-configurations',
  DELETE_ACCOUNTING_COST_CENTERS: 'delete-accounting-cost-centers',
  DELETE_ACCOUNTING_JOURNALS: 'delete-accounting-journals',
  DELETE_ACCOUNTING_STORES: 'delete-accounting-stores',
  DELETE_ACCOUNTING_VOUCHERS: 'delete-accounting-vouchers',
  DELETE_ACCOUNTS: 'delete-accounts',
  DELETE_ACCOUNTS_CONFIGURATIONS: 'delete-accounts-configurations',
  DELETE_ALEX_ANDERSEN_OLUND_CONFIGURATIONS: 'delete-alex-andersen-olund-configurations',
  DELETE_ALEX_ANDERSEN_OLUND_STORES: 'delete-alex-andersen-olund-stores',
  DELETE_BUMP_SCREENS: 'delete-bump-screens',
  DELETE_BUMP_SCREENS_CONFIGURATIONS: 'delete-bump-screens-configurations',
  DELETE_BUNDLES: 'delete-bundles',
  DELETE_CASH_BOXES: 'delete-cash-boxes',
  DELETE_CASH_TRANSFERS: 'delete-cash-transfers',
  DELETE_CITY_OF_COPENHAGEN_BANK_DEPOSITS: 'delete-city-of-copenhagen-bank-deposits',
  DELETE_CITY_OF_COPENHAGEN_CONFIGURATIONS: 'delete-city-of-copenhagen-configurations',
  DELETE_CITY_OF_COPENHAGEN_PAYMENT_METHODS: 'delete-city-of-copenhagen-payment-methods',
  DELETE_CITY_OF_COPENHAGEN_STORES: 'delete-city-of-copenhagen-stores',
  DELETE_CLIENTS: 'delete-clients',
  DELETE_COMPANIES: 'delete-companies',
  DELETE_COMPANY_PRODUCT_FOLDER_SCHEDULES: 'delete-company-product-folder-schedules',
  DELETE_COMPLIMENTA_CONFIGURATIONS: 'delete-complimenta-configurations',
  DELETE_COMPLIMENTA_STORES: 'delete-complimenta-stores',
  DELETE_CONNECTIONS: 'delete-connections',
  DELETE_CREDIT_VOUCHERS: 'delete-credit-vouchers',
  DELETE_CUSTOMER_APP_CONFIGURATIONS: 'delete-customer-app-configurations',
  DELETE_CUSTOMER_APP_MENU_CATEGORIES: 'delete-customer-app-menu-categories',
  DELETE_CUSTOMER_APP_MENU_ITEMS: 'delete-customer-app-menu-items',
  DELETE_CUSTOMER_APP_MENUS: 'delete-customer-app-menus',
  DELETE_CUSTOMER_APP_QR_CODES: 'delete-customer-app-qr-codes',
  DELETE_CUSTOMER_APP_SHOPS: 'delete-customer-app-shops',
  DELETE_CUSTOMER_APP_TABLE_SECTIONS: 'delete-customer-app-table-sections',
  DELETE_DISCOUNT_BUTTONS: 'delete-discount-buttons',
  DELETE_ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULES: 'delete-economic-account-group-invoice-schedules',
  DELETE_ECONOMIC_ACCOUNT_GROUP_MAPPINGS: 'delete-economic-account-group-mappings',
  DELETE_ECONOMIC_CONFIGURATIONS: 'delete-economic-configurations',
  DELETE_ECONOMIC_MODIFIER_MAPPINGS: 'delete-economic-modifier-mappings',
  DELETE_ECONOMIC_PRODUCT_GROUP_MAPPINGS: 'delete-economic-product-group-mappings',
  DELETE_ECONOMIC_PRODUCT_MAPPINGS: 'delete-economic-product-mappings',
  DELETE_ECONOMIC_SOURCE_MAPPINGS: 'delete-economic-source-mappings',
  DELETE_ECONOMIC_STORE_MAPPINGS: 'delete-economic-store-mappings',
  DELETE_ELAVON_CONFIGURATIONS: 'delete-elavon-configurations',
  DELETE_ELAVON_STORES: 'delete-elavon-stores',
  DELETE_EMPLOYEE_STORES: 'delete-employee-stores',
  DELETE_EMPLOYEES: 'delete-employees',
  DELETE_EMPLOYEES_CONFIGURATIONS: 'delete-employees-configurations',
  DELETE_EXTERNAL_ORDER_PROVIDERS: 'delete-external-order-providers',
  DELETE_FAVORITE_PRODUCT_FOLDERS: 'delete-favorite-product-folders',
  DELETE_FINANCE_ACCOUNTS: 'delete-finance-accounts',
  DELETE_IMAGES: 'delete-images',
  DELETE_INVOICES_CONFIGURATIONS: 'delete-invoices-configurations',
  DELETE_INVOICING_CONFIGURATIONS: 'delete-invoicing-configurations',
  DELETE_INVOICING_INVOICE_RECIPIENTS: 'delete-invoicing-invoice-recipients',
  DELETE_ISS_MENU_PLAN_CONFIGURATIONS: 'delete-iss-menu-plan-configurations',
  DELETE_ISS_MENU_PLAN_STORES: 'delete-iss-menu-plan-stores',
  DELETE_MEETING_FIELDS: 'delete-meeting-fields',
  DELETE_MEETING_FOLDERS: 'delete-meeting-folders',
  DELETE_MEETING_MENU_ITEMS: 'delete-meeting-menu-items',
  DELETE_MEETING_MILESTONE_LINES: 'delete-meeting-milestone-lines',
  DELETE_MEETING_MILESTONES: 'delete-meeting-milestones',
  DELETE_MEETING_PRODUCTS: 'delete-meeting-products',
  DELETE_MEETING_ROOM_GROUPS: 'delete-meeting-room-groups',
  DELETE_MEETING_ROOMS: 'delete-meeting-rooms',
  DELETE_MEETING_STORES: 'delete-meeting-stores',
  DELETE_MEETINGS: 'delete-meetings',
  DELETE_MEETINGS_CONFIGURATIONS: 'delete-meetings-configurations',
  DELETE_MERCHANT_CURRENCIES: 'delete-merchant-currencies',
  DELETE_MERCHANTS: 'delete-merchants',
  DELETE_MOBILE_PAY_CONFIGURATIONS: 'delete-mobile-pay-configurations',
  DELETE_MOBILE_PAY_ORGANIZATIONS: 'delete-mobile-pay-organizations',
  DELETE_MOBILE_PAY_STORE_MAPPINGS: 'delete-mobile-pay-store-mappings',
  DELETE_MOBILE_PAY_TERMINAL_MAPPINGS: 'delete-mobile-pay-terminal-mappings',
  DELETE_MODIFIER_GROUPS: 'delete-modifier-groups',
  DELETE_MODIFIERS: 'delete-modifiers',
  DELETE_NEXUDUS_BUNDLE_MAPPINGS: 'delete-nexudus-bundle-mappings',
  DELETE_NEXUDUS_CONFIGURATIONS: 'delete-nexudus-configurations',
  DELETE_NEXUDUS_FINANCIAL_ACCOUNT_MAPPINGS: 'delete-nexudus-financial-account-mappings',
  DELETE_NEXUDUS_PRODUCT_MAPPINGS: 'delete-nexudus-product-mappings',
  DELETE_NOTICES: 'delete-notices',
  DELETE_ONBOARDING_PROFILES: 'delete-onboarding-profiles',
  DELETE_ORDER_LINE_NOTES: 'delete-order-line-notes',
  DELETE_ORDERS: 'delete-orders',
  DELETE_PACKAGES: 'delete-packages',
  DELETE_PAY_WITH_CASH_CONFIGURATIONS: 'delete-pay-with-cash-configurations',
  DELETE_PAYMENT_ACCOUNTS: 'delete-payment-accounts',
  DELETE_PAYMENT_METHOD_ACCOUNTS: 'delete-payment-method-accounts',
  DELETE_PAYMENTS: 'delete-payments',
  DELETE_PICKUP_CONFIGURATIONS: 'delete-pickup-configurations',
  DELETE_PICKUP_STORES: 'delete-pickup-stores',
  DELETE_PLANDAY_CONFIGURATIONS: 'delete-planday-configurations',
  DELETE_PLANDAY_STORE_MAPPINGS: 'delete-planday-store-mappings',
  DELETE_PREPARATION_CONFIGURATIONS: 'delete-preparation-configurations',
  DELETE_PREPARATION_COURSES: 'delete-preparation-courses',
  DELETE_PREPARATION_LOCATIONS: 'delete-preparation-locations',
  DELETE_PREPARATION_RESPONSIBILITIES: 'delete-preparation-responsibilities',
  DELETE_PREPARATION_STATIONS: 'delete-preparation-stations',
  DELETE_PRICE_GROUPS: 'delete-price-groups',
  DELETE_PRINT_JOBS: 'delete-print-jobs',
  DELETE_PRINTERS: 'delete-printers',
  DELETE_PRINTING_CONFIGURATIONS: 'delete-printing-configurations',
  DELETE_PRODUCT_FOLDER_SCHEDULES: 'delete-product-folder-schedules',
  DELETE_PRODUCT_FOLDERS: 'delete-product-folders',
  DELETE_PRODUCT_GROUPS: 'delete-product-groups',
  DELETE_PRODUCT_MAPPINGS: 'delete-product-mappings',
  DELETE_PRODUCT_RESTRICTIONS: 'delete-product-restrictions',
  DELETE_PRODUCTS: 'delete-products',
  DELETE_RECEIPTS: 'delete-receipts',
  DELETE_RESTAURANTS: 'delete-restaurants',
  DELETE_RETAIL_CONFIGURATIONS: 'delete-retail-configurations',
  DELETE_RETAIL_FOLDERS: 'delete-retail-folders',
  DELETE_RETAIL_MENU_ITEMS: 'delete-retail-menu-items',
  DELETE_RETAIL_PRODUCTS: 'delete-retail-products',
  DELETE_RETAIL_STORES: 'delete-retail-stores',
  DELETE_RETURN_ITEMS: 'delete-return-items',
  DELETE_RETURN_ORDERS: 'delete-return-orders',
  DELETE_ROLES: 'delete-roles',
  DELETE_SCHEDULED_ACCOUNT_IMPORTS: 'delete-scheduled-account-imports',
  DELETE_SECTIONS: 'delete-sections',
  DELETE_SELF_SERVICE_BUTTONS: 'delete-self-service-buttons',
  DELETE_SELF_SERVICE_CONFIGURATIONS: 'delete-self-service-configurations',
  DELETE_SELF_SERVICE_TERMINALS: 'delete-self-service-terminals',
  DELETE_SKATTEVERKET_CONFIGURATIONS: 'delete-skatteverket-configurations',
  DELETE_SKATTEVERKET_ONBOARDING_GUIDE: 'delete-skatteverket-onboarding-guide',
  DELETE_SKATTEVERKET_REGISTERED_TERMINALS: 'delete-skatteverket-registered-terminals',
  DELETE_SPECIAL_DAYS: 'delete-special-days',
  DELETE_STOCK_CONFIGURATIONS: 'delete-stock-configurations',
  DELETE_STOCK_ITEMS: 'delete-stock-items',
  DELETE_STOCK_LOCATION_ITEMS: 'delete-stock-location-items',
  DELETE_STOCK_LOCATIONS: 'delete-stock-locations',
  DELETE_STOCK_STATUSES: 'delete-stock-statuses',
  DELETE_STOCK_TRANSFERS: 'delete-stock-transfers',
  DELETE_STORE_MAPPINGS: 'delete-store-mappings',
  DELETE_STORED_REPORT_RECIPIENTS: 'delete-stored-report-recipients',
  DELETE_STORED_REPORT_SCHEDULES: 'delete-stored-report-schedules',
  DELETE_STORED_REPORTS: 'delete-stored-reports',
  DELETE_STORES: 'delete-stores',
  DELETE_SURVEY_CONFIGURATIONS: 'delete-survey-configurations',
  DELETE_SURVEY_QUESTIONS: 'delete-survey-questions',
  DELETE_SURVEY_RESPONSES: 'delete-survey-responses',
  DELETE_SURVEYS: 'delete-surveys',
  DELETE_SWISH_CONFIGURATIONS: 'delete-swish-configurations',
  DELETE_SWISH_STORES: 'delete-swish-stores',
  DELETE_TABLES: 'delete-tables',
  DELETE_TABLES_CONFIGURATIONS: 'delete-tables-configurations',
  DELETE_TAKEAWAY_CONFIGURATIONS: 'delete-takeaway-configurations',
  DELETE_TAKEAWAY_MENU_ITEMS: 'delete-takeaway-menu-items',
  DELETE_TAKEAWAY_ORDER_FIELD_GROUPS: 'delete-takeaway-order-field-groups',
  DELETE_TAKEAWAY_ORDER_FIELDS: 'delete-takeaway-order-fields',
  DELETE_TAKEAWAY_STORES: 'delete-takeaway-stores',
  DELETE_TERMINAL_START_SCREENS: 'delete-terminal-start-screens',
  DELETE_TERMINAL_TYPES: 'delete-terminal-types',
  DELETE_TERMINALS: 'delete-terminals',
  DELETE_TIPPING_CONFIGURATIONS: 'delete-tipping-configurations',
  DELETE_TIPPING_RATES: 'delete-tipping-rates',
  DELETE_TIPPING_STORES: 'delete-tipping-stores',
  DELETE_TIPPING_TERMINALS: 'delete-tipping-terminals',
  DELETE_USERS: 'delete-users',
  DELETE_VAT_RATE_ACCOUNTS: 'delete-vat-rate-accounts',
  DELETE_WAITERS: 'delete-waiters',
  DELETE_WEB_CONFIGURATIONS: 'delete-web-configurations',
  DELETE_WEB_STORE_ACCOUNT_GROUPS: 'delete-web-store-account-groups',
  DELETE_WEB_STORE_PAGES: 'delete-web-store-pages',
  DELETE_WEB_STORES: 'delete-web-stores',
  LIST_ACCOUNT_CARDS_ACCOUNT_CARDS: 'list-account-cards-account-cards',
  LIST_CASH_TRANSACTIONS: 'list-cash-transactions',
  LIST_STOCK_CONFIGURATIONS: 'list-stock-configurations',
  SHOW_ACCOUNT_CARDS: 'show-account-cards',
  SHOW_ACCOUNT_CARDS_ACCOUNT_DRIVERS: 'show-account-cards-account-drivers',
  SHOW_ACCOUNT_CARDS_ACCOUNT_EVENTS: 'show-account-cards-account-events',
  SHOW_ACCOUNT_CARDS_ACCOUNT_EXPORTS: 'show-account-cards-account-exports',
  SHOW_ACCOUNT_CARDS_ACCOUNT_GROUPS: 'show-account-cards-account-groups',
  SHOW_ACCOUNT_CARDS_ACCOUNT_LOCATIONS: 'show-account-cards-account-locations',
  SHOW_ACCOUNT_CARDS_ACCOUNT_LOGIN_HANDLERS: 'show-account-cards-account-login-handlers',
  SHOW_ACCOUNT_CARDS_ACCOUNT_LOGIN_SCHEDULES: 'show-account-cards-account-login-schedules',
  SHOW_ACCOUNT_CARDS_ACCOUNT_MANAGERS: 'show-account-cards-account-managers',
  SHOW_ACCOUNT_CARDS_ACCOUNTS: 'show-account-cards-accounts',
  SHOW_ACCOUNT_CARDS_ATTRIBUTE_OPTIONS: 'show-account-cards-attribute-options',
  SHOW_ACCOUNT_CARDS_CONFIGURATIONS: 'show-account-cards-configurations',
  SHOW_ACCOUNT_CARDS_DISCOUNT_GROUP_SCHEDULES: 'show-account-cards-discount-group-schedules',
  SHOW_ACCOUNT_CARDS_DISCOUNT_GROUPS: 'show-account-cards-discount-groups',
  SHOW_ACCOUNT_CARDS_PRODUCT_RESTRICTIONS: 'show-account-cards-product-restrictions',
  SHOW_ACCOUNT_CARDS_WHITELISTED_PRODUCT_GROUPS: 'show-account-cards-whitelisted-product-groups',
  SHOW_ACCOUNT_GROUP_EMAIL_DOMAINS: 'show-account-group-email-domains',
  SHOW_ACCOUNT_GROUP_LOCATIONS: 'show-account-group-locations',
  SHOW_ACCOUNT_GROUP_PRODUCT_FOLDER_SCHEDULES: 'show-account-group-product-folder-schedules',
  SHOW_ACCOUNT_GROUPS: 'show-account-groups',
  SHOW_ACCOUNT_IMPORTS: 'show-account-imports',
  SHOW_ACCOUNT_LOGIN_HANDLERS: 'show-account-login-handlers',
  SHOW_ACCOUNT_LOGIN_SCHEDULES: 'show-account-login-schedules',
  SHOW_ACCOUNTING_ACCOUNTS: 'show-accounting-accounts',
  SHOW_ACCOUNTING_CONFIGURATIONS: 'show-accounting-configurations',
  SHOW_ACCOUNTING_COST_CENTERS: 'show-accounting-cost-centers',
  SHOW_ACCOUNTING_JOURNALS: 'show-accounting-journals',
  SHOW_ACCOUNTING_SOURCES: 'show-accounting-sources',
  SHOW_ACCOUNTING_STORES: 'show-accounting-stores',
  SHOW_ACCOUNTS: 'show-accounts',
  SHOW_ACCOUNTS_CONFIGURATIONS: 'show-accounts-configurations',
  SHOW_ALEX_ANDERSEN_OLUND_CONFIGURATIONS: 'show-alex-andersen-olund-configurations',
  SHOW_ALEX_ANDERSEN_OLUND_STORES: 'show-alex-andersen-olund-stores',
  SHOW_APP_STORES: 'show-app-stores',
  SHOW_AUTHORIZATIONS: 'show-authorizations',
  SHOW_BUMP_SCREEN_TASKS: 'show-bump-screen-tasks',
  SHOW_BUMP_SCREENS: 'show-bump-screens',
  SHOW_BUMP_SCREENS_CONFIGURATIONS: 'show-bump-screens-configurations',
  SHOW_BUNDLES: 'show-bundles',
  SHOW_BUNDLES_REPORT: 'show-bundles-report',
  SHOW_CASH_BALANCES: 'show-cash-balances',
  SHOW_CASH_BOXES: 'show-cash-boxes',
  SHOW_CASH_CAPABILITIES: 'show-cash-capabilities',
  SHOW_CASH_COUNTS: 'show-cash-counts',
  SHOW_CASH_TRANSACTIONS: 'show-cash-transactions',
  SHOW_CASH_TRANSFERS: 'show-cash-transfers',
  SHOW_CITY_OF_COPENHAGEN_BANK_DEPOSITS: 'show-city-of-copenhagen-bank-deposits',
  SHOW_CITY_OF_COPENHAGEN_CONFIGURATIONS: 'show-city-of-copenhagen-configurations',
  SHOW_CITY_OF_COPENHAGEN_PAYMENT_METHODS: 'show-city-of-copenhagen-payment-methods',
  SHOW_CITY_OF_COPENHAGEN_PRODUCTS: 'show-city-of-copenhagen-products',
  SHOW_CITY_OF_COPENHAGEN_STORES: 'show-city-of-copenhagen-stores',
  SHOW_CLIENTS: 'show-clients',
  SHOW_COMPANIES: 'show-companies',
  SHOW_COMPANY_PRODUCT_FOLDER_SCHEDULES: 'show-company-product-folder-schedules',
  SHOW_COMPLIMENTA_CONFIGURATIONS: 'show-complimenta-configurations',
  SHOW_COMPLIMENTA_PAID_INVOICES: 'show-complimenta-paid-invoices',
  SHOW_COMPLIMENTA_STORES: 'show-complimenta-stores',
  SHOW_CONNECTION_DRIVERS: 'show-connection-drivers',
  SHOW_CONNECTION_TYPES: 'show-connection-types',
  SHOW_CONNECTIONS: 'show-connections',
  SHOW_CREDIT_VOUCHERS: 'show-credit-vouchers',
  SHOW_CURRENCIES: 'show-currencies',
  SHOW_CUSTOMER_APP_CONFIGURATIONS: 'show-customer-app-configurations',
  SHOW_CUSTOMER_APP_MENU_CATEGORIES: 'show-customer-app-menu-categories',
  SHOW_CUSTOMER_APP_MENU_ITEMS: 'show-customer-app-menu-items',
  SHOW_CUSTOMER_APP_MENUS: 'show-customer-app-menus',
  SHOW_CUSTOMER_APP_QR_CODES: 'show-customer-app-qr-codes',
  SHOW_CUSTOMER_APP_SHOP_ORDERS: 'show-customer-app-shop-orders',
  SHOW_CUSTOMER_APP_SHOPS: 'show-customer-app-shops',
  SHOW_CUSTOMER_APP_TABLE_SECTIONS: 'show-customer-app-table-sections',
  SHOW_DISCOUNT_BUTTONS: 'show-discount-buttons',
  SHOW_ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULES: 'show-economic-account-group-invoice-schedules',
  SHOW_ECONOMIC_ACCOUNT_GROUP_MAPPINGS: 'show-economic-account-group-mappings',
  SHOW_ECONOMIC_CONFIGURATIONS: 'show-economic-configurations',
  SHOW_ECONOMIC_MODIFIER_MAPPINGS: 'show-economic-modifier-mappings',
  SHOW_ECONOMIC_PRODUCT_GROUP_MAPPINGS: 'show-economic-product-group-mappings',
  SHOW_ECONOMIC_PRODUCT_MAPPINGS: 'show-economic-product-mappings',
  SHOW_ECONOMIC_SOURCE_MAPPINGS: 'show-economic-source-mappings',
  SHOW_ECONOMIC_STORE_MAPPINGS: 'show-economic-store-mappings',
  SHOW_ECONOMIC_TRANSFERS: 'show-economic-transfers',
  SHOW_ELAVON_BATCHES: 'show-elavon-batches',
  SHOW_ELAVON_CONFIGURATIONS: 'show-elavon-configurations',
  SHOW_ELAVON_PAYMENTS: 'show-elavon-payments',
  SHOW_ELAVON_SETTLEMENTS: 'show-elavon-settlements',
  SHOW_ELAVON_STORES: 'show-elavon-stores',
  SHOW_ELAVON_TRANSACTIONS: 'show-elavon-transactions',
  SHOW_EMPLOYEE_CARDS: 'show-employee-cards',
  SHOW_EMPLOYEE_STORES: 'show-employee-stores',
  SHOW_EMPLOYEES: 'show-employees',
  SHOW_EMPLOYEES_CONFIGURATIONS: 'show-employees-configurations',
  SHOW_EXPORTED_ORDERS: 'show-exported-orders',
  SHOW_EXPORTED_RETURN_ORDERS: 'show-exported-return-orders',
  SHOW_EXPORTED_TRADING_DAYS: 'show-exported-trading-days',
  SHOW_EXTERNAL_ORDER_PROVIDERS: 'show-external-order-providers',
  SHOW_EXTERNAL_PRODUCTS: 'show-external-products',
  SHOW_FAVORITE_PRODUCT_FOLDERS: 'show-favorite-product-folders',
  SHOW_FINANCE_ACCOUNTS: 'show-finance-accounts',
  SHOW_FINANCE_ACCOUNTS_REPORT: 'show-finance-accounts-report',
  SHOW_IMAGES: 'show-images',
  SHOW_IMPORTS: 'show-imports',
  SHOW_INVOICES_CONFIGURATIONS: 'show-invoices-configurations',
  SHOW_INVOICES_INVOICE_RECIPIENTS: 'show-invoices-invoice-recipients',
  SHOW_INVOICES_ORDER_INVOICES: 'show-invoices-order-invoices',
  SHOW_INVOICING_CONFIGURATIONS: 'show-invoicing-configurations',
  SHOW_INVOICING_INVOICE_RECIPIENTS: 'show-invoicing-invoice-recipients',
  SHOW_INVOICING_INVOICES: 'show-invoicing-invoices',
  SHOW_ISS_MENU_PLAN_CONFIGURATIONS: 'show-iss-menu-plan-configurations',
  SHOW_ISS_MENU_PLAN_MENUS: 'show-iss-menu-plan-menus',
  SHOW_ISS_MENU_PLAN_STORES: 'show-iss-menu-plan-stores',
  SHOW_LOG_LINES: 'show-log-lines',
  SHOW_MEETING_FIELDS: 'show-meeting-fields',
  SHOW_MEETING_FOLDERS: 'show-meeting-folders',
  SHOW_MEETING_MENU_ITEMS: 'show-meeting-menu-items',
  SHOW_MEETING_MILESTONE_LINES: 'show-meeting-milestone-lines',
  SHOW_MEETING_MILESTONES: 'show-meeting-milestones',
  SHOW_MEETING_PRODUCTS: 'show-meeting-products',
  SHOW_MEETING_ROOM_GROUPS: 'show-meeting-room-groups',
  SHOW_MEETING_ROOMS: 'show-meeting-rooms',
  SHOW_MEETING_STORES: 'show-meeting-stores',
  SHOW_MEETING_WIDGETS: 'show-meeting-widgets',
  SHOW_MEETINGS: 'show-meetings',
  SHOW_MEETINGS_CONFIGURATIONS: 'show-meetings-configurations',
  SHOW_MERCHANT_CURRENCIES: 'show-merchant-currencies',
  SHOW_MERCHANT_INVOICES: 'show-merchant-invoices',
  SHOW_MERCHANTS: 'show-merchants',
  SHOW_MOBILE_PAY_CONFIGURATIONS: 'show-mobile-pay-configurations',
  SHOW_MOBILE_PAY_ORGANIZATIONS: 'show-mobile-pay-organizations',
  SHOW_MOBILE_PAY_STORE_MAPPINGS: 'show-mobile-pay-store-mappings',
  SHOW_MOBILE_PAY_TERMINAL_MAPPINGS: 'show-mobile-pay-terminal-mappings',
  SHOW_MODIFIER_GROUPS: 'show-modifier-groups',
  SHOW_MODIFIERS: 'show-modifiers',
  SHOW_MODIFIERS_REPORT: 'show-modifiers-report',
  SHOW_NEXUDUS_BUNDLE_MAPPINGS: 'show-nexudus-bundle-mappings',
  SHOW_NEXUDUS_CONFIGURATIONS: 'show-nexudus-configurations',
  SHOW_NEXUDUS_FINANCIAL_ACCOUNT_MAPPINGS: 'show-nexudus-financial-account-mappings',
  SHOW_NEXUDUS_PRODUCT_MAPPINGS: 'show-nexudus-product-mappings',
  SHOW_NEXUDUS_TRANSFERS: 'show-nexudus-transfers',
  SHOW_NOTICES: 'show-notices',
  SHOW_ONBOARDING_PROFILES: 'show-onboarding-profiles',
  SHOW_ORDER_LINE_NOTES: 'show-order-line-notes',
  SHOW_ORDER_RECEIPTS: 'show-order-receipts',
  SHOW_ORDERS: 'show-orders',
  SHOW_PACKAGES: 'show-packages',
  SHOW_PAY_WITH_CASH_CONFIGURATIONS: 'show-pay-with-cash-configurations',
  SHOW_PAYMENT_ACCOUNTS: 'show-payment-accounts',
  SHOW_PAYMENT_METHOD_ACCOUNTS: 'show-payment-method-accounts',
  SHOW_PAYMENTS: 'show-payments',
  SHOW_PAYOUT_SUMMARIES: 'show-payout-summaries',
  SHOW_PERMISSIONS: 'show-permissions',
  SHOW_PICKUP_CONFIGURATIONS: 'show-pickup-configurations',
  SHOW_PICKUP_ORDERS: 'show-pickup-orders',
  SHOW_PICKUP_STORES: 'show-pickup-stores',
  SHOW_PLANDAY_CONFIGURATIONS: 'show-planday-configurations',
  SHOW_PLANDAY_STORE_MAPPINGS: 'show-planday-store-mappings',
  SHOW_PLANDAY_TRANSFERS: 'show-planday-transfers',
  SHOW_PREPARATION_CONFIGURATIONS: 'show-preparation-configurations',
  SHOW_PREPARATION_COURSES: 'show-preparation-courses',
  SHOW_PREPARATION_ITEMS: 'show-preparation-items',
  SHOW_PREPARATION_LOCATIONS: 'show-preparation-locations',
  SHOW_PREPARATION_RESPONSIBILITIES: 'show-preparation-responsibilities',
  SHOW_PREPARATION_SERVINGS: 'show-preparation-servings',
  SHOW_PREPARATION_STATIONS: 'show-preparation-stations',
  SHOW_PREPARATION_TASKS: 'show-preparation-tasks',
  SHOW_PRICE_GROUPS: 'show-price-groups',
  SHOW_PRINT_JOBS: 'show-print-jobs',
  SHOW_PRINTER_MODELS: 'show-printer-models',
  SHOW_PRINTERS: 'show-printers',
  SHOW_PRINTING_CONFIGURATIONS: 'show-printing-configurations',
  SHOW_PRODUCT_FOLDER_SCHEDULES: 'show-product-folder-schedules',
  SHOW_PRODUCT_FOLDERS: 'show-product-folders',
  SHOW_PRODUCT_GROUPS: 'show-product-groups',
  SHOW_PRODUCT_GROUPS_REPORT: 'show-product-groups-report',
  SHOW_PRODUCT_IMPORTS: 'show-product-imports',
  SHOW_PRODUCT_LINES: 'show-product-lines',
  SHOW_PRODUCT_MAPPINGS: 'show-product-mappings',
  SHOW_PRODUCT_RESTRICTIONS: 'show-product-restrictions',
  SHOW_PRODUCT_VALIDATORS: 'show-product-validators',
  SHOW_PRODUCTS: 'show-products',
  SHOW_PRODUCTS_REPORT: 'show-products-report',
  SHOW_RECEIPTS: 'show-receipts',
  SHOW_RECIPIENT_TYPES: 'show-recipient-types',
  SHOW_REPORT_CATEGORIES: 'show-report-categories',
  SHOW_REPORT_COLUMN_GROUPS: 'show-report-column-groups',
  SHOW_RESTAURANTS: 'show-restaurants',
  SHOW_RETAIL_CONFIGURATIONS: 'show-retail-configurations',
  SHOW_RETAIL_FOLDERS: 'show-retail-folders',
  SHOW_RETAIL_MENU_ITEMS: 'show-retail-menu-items',
  SHOW_RETAIL_PRODUCTS: 'show-retail-products',
  SHOW_RETAIL_STORES: 'show-retail-stores',
  SHOW_RETURN_ITEMS: 'show-return-items',
  SHOW_RETURN_ITEMS_REPORT: 'show-return-items-report',
  SHOW_RETURN_ORDERS: 'show-return-orders',
  SHOW_ROLES: 'show-roles',
  SHOW_SCHEDULED_ACCOUNT_IMPORTS: 'show-scheduled-account-imports',
  SHOW_SECTIONS: 'show-sections',
  SHOW_SELF_SERVICE_BUTTONS: 'show-self-service-buttons',
  SHOW_SELF_SERVICE_CONFIGURATIONS: 'show-self-service-configurations',
  SHOW_SELF_SERVICE_TERMINALS: 'show-self-service-terminals',
  SHOW_SKATTEVERKET_CONFIGURATIONS: 'show-skatteverket-configurations',
  SHOW_SKATTEVERKET_ONBOARDING_GUIDE: 'show-skatteverket-onboarding-guide',
  SHOW_SKATTEVERKET_ONBOARDING_MAIL: 'show-skatteverket-onboarding-mail',
  SHOW_SKATTEVERKET_RECEIPT_TRANSFERS: 'show-skatteverket-receipt-transfers',
  SHOW_SKATTEVERKET_REGISTERED_TERMINALS: 'show-skatteverket-registered-terminals',
  SHOW_SOURCE_GROUPS: 'show-source-groups',
  SHOW_SPECIAL_DAYS: 'show-special-days',
  SHOW_STOCK_CONFIGURATIONS: 'show-stock-configurations',
  SHOW_STOCK_ITEMS: 'show-stock-items',
  SHOW_STOCK_LOCATION_ITEM_STATUSES: 'show-stock-location-item-statuses',
  SHOW_STOCK_LOCATION_ITEMS: 'show-stock-location-items',
  SHOW_STOCK_LOCATIONS: 'show-stock-locations',
  SHOW_STOCK_STATUSES: 'show-stock-statuses',
  SHOW_STOCK_TRANSFERS: 'show-stock-transfers',
  SHOW_STORE_MAPPINGS: 'show-store-mappings',
  SHOW_STORED_REPORT_JOBS: 'show-stored-report-jobs',
  SHOW_STORED_REPORT_RECIPIENTS: 'show-stored-report-recipients',
  SHOW_STORED_REPORT_SCHEDULES: 'show-stored-report-schedules',
  SHOW_STORED_REPORTS: 'show-stored-reports',
  SHOW_STORES: 'show-stores',
  SHOW_SURVEY_CONFIGURATIONS: 'show-survey-configurations',
  SHOW_SURVEY_QUESTIONS: 'show-survey-questions',
  SHOW_SURVEY_RESPONSES: 'show-survey-responses',
  SHOW_SURVEY_WIDGETS: 'show-survey-widgets',
  SHOW_SURVEYS: 'show-surveys',
  SHOW_SWISH_CONFIGURATIONS: 'show-swish-configurations',
  SHOW_SWISH_STORES: 'show-swish-stores',
  SHOW_TABLES: 'show-tables',
  SHOW_TABLES_CONFIGURATIONS: 'show-tables-configurations',
  SHOW_TAKEAWAY_CONFIGURATIONS: 'show-takeaway-configurations',
  SHOW_TAKEAWAY_MENU_ITEMS: 'show-takeaway-menu-items',
  SHOW_TAKEAWAY_ORDER_FIELD_GROUPS: 'show-takeaway-order-field-groups',
  SHOW_TAKEAWAY_ORDER_FIELDS: 'show-takeaway-order-fields',
  SHOW_TAKEAWAY_STORES: 'show-takeaway-stores',
  SHOW_TAKEAWAY_WIDGETS: 'show-takeaway-widgets',
  SHOW_TERMINAL_EVENTS: 'show-terminal-events',
  SHOW_TERMINAL_SETUPS: 'show-terminal-setups',
  SHOW_TERMINAL_START_SCREENS: 'show-terminal-start-screens',
  SHOW_TERMINAL_TYPES: 'show-terminal-types',
  SHOW_TERMINALS: 'show-terminals',
  SHOW_TIPPING_CONFIGURATIONS: 'show-tipping-configurations',
  SHOW_TIPPING_RATES: 'show-tipping-rates',
  SHOW_TIPPING_STORES: 'show-tipping-stores',
  SHOW_TIPPING_TERMINALS: 'show-tipping-terminals',
  SHOW_TRADING_DAYS: 'show-trading-days',
  SHOW_TRANSACTIONS: 'show-transactions',
  SHOW_USERS: 'show-users',
  SHOW_VAT_RATE_ACCOUNTS: 'show-vat-rate-accounts',
  SHOW_WAITER_LOGINS: 'show-waiter-logins',
  SHOW_WAITER_PERMISSIONS: 'show-waiter-permissions',
  SHOW_WAITERS: 'show-waiters',
  SHOW_WAITERS_REPORT: 'show-waiters-report',
  SHOW_WEB_CONFIGURATIONS: 'show-web-configurations',
  SHOW_WEB_EMAIL_DOMAINS: 'show-web-email-domains',
  SHOW_WEB_STORE_ACCOUNT_FIELDS: 'show-web-store-account-fields',
  SHOW_WEB_STORE_ACCOUNT_GROUPS: 'show-web-store-account-groups',
  SHOW_WEB_STORE_PAGES: 'show-web-store-pages',
  SHOW_WEB_STORES: 'show-web-stores',
  SHOW_WEIGHT_REPORT: 'show-weight-report',
  UPDATE_ACCOUNT_CARDS_ACCOUNT_GROUPS: 'update-account-cards-account-groups',
  UPDATE_ACCOUNT_CARDS_ACCOUNT_LOCATIONS: 'update-account-cards-account-locations',
  UPDATE_ACCOUNT_CARDS_ACCOUNT_LOGIN_SCHEDULES: 'update-account-cards-account-login-schedules',
  UPDATE_ACCOUNT_CARDS_ACCOUNT_MANAGERS: 'update-account-cards-account-managers',
  UPDATE_ACCOUNT_CARDS_ACCOUNTS: 'update-account-cards-accounts',
  UPDATE_ACCOUNT_CARDS_CONFIGURATIONS: 'update-account-cards-configurations',
  UPDATE_ACCOUNT_CARDS_DISCOUNT_GROUP_SCHEDULES: 'update-account-cards-discount-group-schedules',
  UPDATE_ACCOUNT_CARDS_DISCOUNT_GROUPS: 'update-account-cards-discount-groups',
  UPDATE_ACCOUNT_CARDS_PRODUCT_RESTRICTIONS: 'update-account-cards-product-restrictions',
  UPDATE_ACCOUNT_CARDS_WHITELISTED_PRODUCT_GROUPS: 'update-account-cards-whitelisted-product-groups',
  UPDATE_ACCOUNT_GROUP_EMAIL_DOMAINS: 'update-account-group-email-domains',
  UPDATE_ACCOUNT_GROUP_LOCATIONS: 'update-account-group-locations',
  UPDATE_ACCOUNT_GROUP_PRODUCT_FOLDER_SCHEDULES: 'update-account-group-product-folder-schedules',
  UPDATE_ACCOUNT_GROUPS: 'update-account-groups',
  UPDATE_ACCOUNT_LOGIN_SCHEDULES: 'update-account-login-schedules',
  UPDATE_ACCOUNTING_ACCOUNTS: 'update-accounting-accounts',
  UPDATE_ACCOUNTING_CONFIGURATIONS: 'update-accounting-configurations',
  UPDATE_ACCOUNTING_COST_CENTERS: 'update-accounting-cost-centers',
  UPDATE_ACCOUNTING_JOURNALS: 'update-accounting-journals',
  UPDATE_ACCOUNTING_STORES: 'update-accounting-stores',
  UPDATE_ACCOUNTS: 'update-accounts',
  UPDATE_ALEX_ANDERSEN_OLUND_STORES: 'update-alex-andersen-olund-stores',
  UPDATE_BUMP_SCREENS: 'update-bump-screens',
  UPDATE_BUMP_SCREENS_CONFIGURATIONS: 'update-bump-screens-configurations',
  UPDATE_BUNDLES: 'update-bundles',
  UPDATE_CASH_BOXES: 'update-cash-boxes',
  UPDATE_CASH_COUNTS: 'update-cash-counts',
  UPDATE_CASH_TRANSFERS: 'update-cash-transfers',
  UPDATE_CITY_OF_COPENHAGEN_PAYMENT_METHODS: 'update-city-of-copenhagen-payment-methods',
  UPDATE_CITY_OF_COPENHAGEN_PRODUCTS: 'update-city-of-copenhagen-products',
  UPDATE_CITY_OF_COPENHAGEN_STORES: 'update-city-of-copenhagen-stores',
  UPDATE_CLIENTS: 'update-clients',
  UPDATE_COMPANIES: 'update-companies',
  UPDATE_COMPANY_PRODUCT_FOLDER_SCHEDULES: 'update-company-product-folder-schedules',
  UPDATE_COMPLIMENTA_CONFIGURATIONS: 'update-complimenta-configurations',
  UPDATE_COMPLIMENTA_STORES: 'update-complimenta-stores',
  UPDATE_CONNECTIONS: 'update-connections',
  UPDATE_CUSTOMER_APP_CONFIGURATIONS: 'update-customer-app-configurations',
  UPDATE_CUSTOMER_APP_MENU_CATEGORIES: 'update-customer-app-menu-categories',
  UPDATE_CUSTOMER_APP_MENU_ITEMS: 'update-customer-app-menu-items',
  UPDATE_CUSTOMER_APP_MENUS: 'update-customer-app-menus',
  UPDATE_CUSTOMER_APP_QR_CODES: 'update-customer-app-qr-codes',
  UPDATE_CUSTOMER_APP_SHOP_ORDERS: 'update-customer-app-shop-orders',
  UPDATE_CUSTOMER_APP_SHOPS: 'update-customer-app-shops',
  UPDATE_CUSTOMER_APP_TABLE_SECTIONS: 'update-customer-app-table-sections',
  UPDATE_DISCOUNT_BUTTONS: 'update-discount-buttons',
  UPDATE_ECONOMIC_ACCOUNT_GROUP_INVOICE_SCHEDULES: 'update-economic-account-group-invoice-schedules',
  UPDATE_ECONOMIC_ACCOUNT_GROUP_MAPPINGS: 'update-economic-account-group-mappings',
  UPDATE_ECONOMIC_CONFIGURATIONS: 'update-economic-configurations',
  UPDATE_ECONOMIC_MODIFIER_MAPPINGS: 'update-economic-modifier-mappings',
  UPDATE_ECONOMIC_PRODUCT_GROUP_MAPPINGS: 'update-economic-product-group-mappings',
  UPDATE_ECONOMIC_PRODUCT_MAPPINGS: 'update-economic-product-mappings',
  UPDATE_ECONOMIC_SOURCE_MAPPINGS: 'update-economic-source-mappings',
  UPDATE_ECONOMIC_STORE_MAPPINGS: 'update-economic-store-mappings',
  UPDATE_ELAVON_CONFIGURATIONS: 'update-elavon-configurations',
  UPDATE_ELAVON_STORES: 'update-elavon-stores',
  UPDATE_EMPLOYEE_STORES: 'update-employee-stores',
  UPDATE_EMPLOYEES: 'update-employees',
  UPDATE_EXTERNAL_ORDER_PROVIDERS: 'update-external-order-providers',
  UPDATE_FAVORITE_PRODUCT_FOLDERS: 'update-favorite-product-folders',
  UPDATE_FINANCE_ACCOUNTS: 'update-finance-accounts',
  UPDATE_IMAGES: 'update-images',
  UPDATE_INVOICES_CONFIGURATIONS: 'update-invoices-configurations',
  UPDATE_INVOICES_INVOICE_RECIPIENTS: 'update-invoices-invoice-recipients',
  UPDATE_INVOICING_CONFIGURATIONS: 'update-invoicing-configurations',
  UPDATE_INVOICING_INVOICE_RECIPIENTS: 'update-invoicing-invoice-recipients',
  UPDATE_ISS_MENU_PLAN_CONFIGURATIONS: 'update-iss-menu-plan-configurations',
  UPDATE_ISS_MENU_PLAN_STORES: 'update-iss-menu-plan-stores',
  UPDATE_MEETING_FIELDS: 'update-meeting-fields',
  UPDATE_MEETING_FOLDERS: 'update-meeting-folders',
  UPDATE_MEETING_MENU_ITEMS: 'update-meeting-menu-items',
  UPDATE_MEETING_MILESTONE_LINES: 'update-meeting-milestone-lines',
  UPDATE_MEETING_MILESTONES: 'update-meeting-milestones',
  UPDATE_MEETING_PRODUCTS: 'update-meeting-products',
  UPDATE_MEETING_ROOM_GROUPS: 'update-meeting-room-groups',
  UPDATE_MEETING_ROOMS: 'update-meeting-rooms',
  UPDATE_MEETING_STORES: 'update-meeting-stores',
  UPDATE_MEETINGS: 'update-meetings',
  UPDATE_MEETINGS_CONFIGURATIONS: 'update-meetings-configurations',
  UPDATE_MERCHANT_CURRENCIES: 'update-merchant-currencies',
  UPDATE_MERCHANTS: 'update-merchants',
  UPDATE_MOBILE_PAY_CONFIGURATIONS: 'update-mobile-pay-configurations',
  UPDATE_MOBILE_PAY_STORE_MAPPINGS: 'update-mobile-pay-store-mappings',
  UPDATE_MOBILE_PAY_TERMINAL_MAPPINGS: 'update-mobile-pay-terminal-mappings',
  UPDATE_MODIFIER_GROUPS: 'update-modifier-groups',
  UPDATE_MODIFIERS: 'update-modifiers',
  UPDATE_NEXUDUS_BUNDLE_MAPPINGS: 'update-nexudus-bundle-mappings',
  UPDATE_NEXUDUS_CONFIGURATIONS: 'update-nexudus-configurations',
  UPDATE_NEXUDUS_FINANCIAL_ACCOUNT_MAPPINGS: 'update-nexudus-financial-account-mappings',
  UPDATE_NEXUDUS_PRODUCT_MAPPINGS: 'update-nexudus-product-mappings',
  UPDATE_NOTICES: 'update-notices',
  UPDATE_ONBOARDING_PROFILES: 'update-onboarding-profiles',
  UPDATE_ORDER_LINE_NOTES: 'update-order-line-notes',
  UPDATE_ORDERS: 'update-orders',
  UPDATE_PACKAGES: 'update-packages',
  UPDATE_PAYMENT_ACCOUNTS: 'update-payment-accounts',
  UPDATE_PAYMENT_METHOD_ACCOUNTS: 'update-payment-method-accounts',
  UPDATE_PAYMENTS: 'update-payments',
  UPDATE_PICKUP_CONFIGURATIONS: 'update-pickup-configurations',
  UPDATE_PICKUP_STORES: 'update-pickup-stores',
  UPDATE_PLANDAY_CONFIGURATIONS: 'update-planday-configurations',
  UPDATE_PLANDAY_STORE_MAPPINGS: 'update-planday-store-mappings',
  UPDATE_PREPARATION_CONFIGURATIONS: 'update-preparation-configurations',
  UPDATE_PREPARATION_COURSES: 'update-preparation-courses',
  UPDATE_PREPARATION_ITEMS: 'update-preparation-items',
  UPDATE_PREPARATION_LOCATIONS: 'update-preparation-locations',
  UPDATE_PREPARATION_RESPONSIBILITIES: 'update-preparation-responsibilities',
  UPDATE_PREPARATION_STATIONS: 'update-preparation-stations',
  UPDATE_PRICE_GROUPS: 'update-price-groups',
  UPDATE_PRINTERS: 'update-printers',
  UPDATE_PRINTING_CONFIGURATIONS: 'update-printing-configurations',
  UPDATE_PRODUCT_FOLDER_SCHEDULES: 'update-product-folder-schedules',
  UPDATE_PRODUCT_FOLDERS: 'update-product-folders',
  UPDATE_PRODUCT_GROUPS: 'update-product-groups',
  UPDATE_PRODUCT_MAPPINGS: 'update-product-mappings',
  UPDATE_PRODUCT_RESTRICTIONS: 'update-product-restrictions',
  UPDATE_PRODUCT_VALIDATORS: 'update-product-validators',
  UPDATE_PRODUCTS: 'update-products',
  UPDATE_RECEIPTS: 'update-receipts',
  UPDATE_RESTAURANTS: 'update-restaurants',
  UPDATE_RETAIL_FOLDERS: 'update-retail-folders',
  UPDATE_RETAIL_MENU_ITEMS: 'update-retail-menu-items',
  UPDATE_RETAIL_PRODUCTS: 'update-retail-products',
  UPDATE_RETAIL_STORES: 'update-retail-stores',
  UPDATE_RETURN_ORDERS: 'update-return-orders',
  UPDATE_ROLES: 'update-roles',
  UPDATE_SCHEDULED_ACCOUNT_IMPORTS: 'update-scheduled-account-imports',
  UPDATE_SECTIONS: 'update-sections',
  UPDATE_SELF_SERVICE_BUTTONS: 'update-self-service-buttons',
  UPDATE_SELF_SERVICE_TERMINALS: 'update-self-service-terminals',
  UPDATE_SKATTEVERKET_CONFIGURATIONS: 'update-skatteverket-configurations',
  UPDATE_SKATTEVERKET_ONBOARDING_GUIDE: 'update-skatteverket-onboarding-guide',
  UPDATE_SKATTEVERKET_ONBOARDING_MAIL: 'update-skatteverket-onboarding-mail',
  UPDATE_SKATTEVERKET_REGISTERED_TERMINALS: 'update-skatteverket-registered-terminals',
  UPDATE_SPECIAL_DAYS: 'update-special-days',
  UPDATE_STOCK_ITEMS: 'update-stock-items',
  UPDATE_STOCK_LOCATION_ITEMS: 'update-stock-location-items',
  UPDATE_STOCK_LOCATIONS: 'update-stock-locations',
  UPDATE_STORE_MAPPINGS: 'update-store-mappings',
  UPDATE_STORED_REPORT_RECIPIENTS: 'update-stored-report-recipients',
  UPDATE_STORED_REPORT_SCHEDULES: 'update-stored-report-schedules',
  UPDATE_STORED_REPORTS: 'update-stored-reports',
  UPDATE_STORES: 'update-stores',
  UPDATE_SURVEY_QUESTIONS: 'update-survey-questions',
  UPDATE_SURVEY_RESPONSES: 'update-survey-responses',
  UPDATE_SURVEYS: 'update-surveys',
  UPDATE_SWISH_CONFIGURATIONS: 'update-swish-configurations',
  UPDATE_SWISH_STORES: 'update-swish-stores',
  UPDATE_TABLES: 'update-tables',
  UPDATE_TABLES_CONFIGURATIONS: 'update-tables-configurations',
  UPDATE_TAKEAWAY_MENU_ITEMS: 'update-takeaway-menu-items',
  UPDATE_TAKEAWAY_ORDER_FIELD_GROUPS: 'update-takeaway-order-field-groups',
  UPDATE_TAKEAWAY_ORDER_FIELDS: 'update-takeaway-order-fields',
  UPDATE_TAKEAWAY_STORES: 'update-takeaway-stores',
  UPDATE_TERMINAL_START_SCREENS: 'update-terminal-start-screens',
  UPDATE_TERMINAL_TYPES: 'update-terminal-types',
  UPDATE_TERMINALS: 'update-terminals',
  UPDATE_TIPPING_CONFIGURATIONS: 'update-tipping-configurations',
  UPDATE_TIPPING_RATES: 'update-tipping-rates',
  UPDATE_TIPPING_STORES: 'update-tipping-stores',
  UPDATE_TIPPING_TERMINALS: 'update-tipping-terminals',
  UPDATE_USERS: 'update-users',
  UPDATE_VAT_RATE_ACCOUNTS: 'update-vat-rate-accounts',
  UPDATE_WAITERS: 'update-waiters',
  UPDATE_WEB_STORE_ACCOUNT_FIELDS: 'update-web-store-account-fields',
  UPDATE_WEB_STORE_ACCOUNT_GROUPS: 'update-web-store-account-groups',
  UPDATE_WEB_STORE_PAGES: 'update-web-store-pages',
  UPDATE_WEB_STORES: 'update-web-stores',
} as const

export type PortalPermissionValue = (typeof PortalPermissionNames)[keyof typeof PortalPermissionNames]
