import type { RouteRecordRaw } from 'vue-router'

import { Section } from '@/router/shared/constants'

const Blank = () => import('@/pages/Blank.vue')
const MerchantCreate = () => import('@/pages/System/Merchants/MerchantsCreate.vue')
const MerchantsList = () => import('@/pages/System/Merchants/MerchantsList.vue')

const MerchantEdit = () => import('@/pages/System/Merchants/MerchantsEdit.vue')
export const routeNames = {
  MERCHANTS_LIST: 'MERCHANTS_LIST',
  MERCHANTS_EDIT_MERCHANT: 'MERCHANTS_EDIT_MERCHANT',
  MERCHANTS_CREATE_MERCHANT: 'MERCHANTS_CREATE_MERCHANT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'merchants',
    component: Blank,
    meta: {
      section: Section.ADMIN,
      global: true,
    },
    children: [
      {
        path: '',
        component: MerchantsList,
        name: routeNames.MERCHANTS_LIST,
      },
      {
        path: 'create',
        component: MerchantCreate,
        name: routeNames.MERCHANTS_CREATE_MERCHANT,
      },
      {
        path: ':merchantId',
        props: true,
        name: routeNames.MERCHANTS_EDIT_MERCHANT,
        component: MerchantEdit,
      },
    ],
  },
]

export default routes
