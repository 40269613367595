import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  WEB_CONFIGURATIONS_LIST: 'WEB_CONFIGURATIONS_LIST',
  WEB_CONFIGURATION_CREATE: 'WEB_CONFIGURATION_CREATE',
  WEB_CONFIGURATION_EDIT: 'WEB_CONFIGURATION_EDIT',
  WEB_CONFIGURATIONS_WEB_STORES_EDIT: 'WEB_CONFIGURATIONS_WEB_STORES_EDIT',
  WEB_CONFIGURATIONS_WEB_STORES_SHOW: 'WEB_CONFIGURATIONS_WEB_STORES_SHOW',
  WEB_STORE_PAGE_EDIT: 'WEB_STORE_PAGE_EDIT',
  WEB_CONFIGURATIONS_WEB_STORE_PAGES_LIST: 'WEB_CONFIGURATIONS_WEB_STORE_PAGES_LIST',
  WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_GROUPS_LIST: 'WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_GROUPS_LIST',
  WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_FIELDS_LIST: 'WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_FIELDS_LIST',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'web',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: 'configurations',
        component: () => import('@/pages/Blank.vue'),
        children: [
          {
            path: '',
            name: routeNames.WEB_CONFIGURATIONS_LIST,
            component: () => import('@/pages/Applications/Web/Configurations/List.vue'),
          },
          {
            path: 'create',
            name: routeNames.WEB_CONFIGURATION_CREATE,
            component: () => import('@/pages/Applications/Web/Configurations/Create.vue'),
          },
          {
            path: ':configurationId',
            name: routeNames.WEB_CONFIGURATION_EDIT,
            component: () => import('@/pages/Applications/Web/Configurations/Edit.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'configurations/:configurationId/web-stores/:webStoreId',
        component: () => import('@/pages/Applications/Web/WebStores/Edit/index.vue'),
        props: true,
        children: [
          {
            path: '',
            name: routeNames.WEB_CONFIGURATIONS_WEB_STORES_SHOW,
            redirect: () => ({ name: routeNames.WEB_CONFIGURATIONS_WEB_STORES_EDIT }),
          },
          {
            path: 'edit',
            name: routeNames.WEB_CONFIGURATIONS_WEB_STORES_EDIT,
            component: () => import('@/pages/Applications/Web/WebStores/Edit/Tabs/Edit.vue'),
            meta: { subsection: 'edit' },
          },
          {
            path: 'web-store-pages',
            name: routeNames.WEB_CONFIGURATIONS_WEB_STORE_PAGES_LIST,
            component: () => import('@/pages/Applications/Web/WebStores/Edit/Tabs/WebStorePageList.vue'),
            meta: { subsection: 'web-store-pages' },
          },
          {
            path: 'web-store-pages/:webStorePageId',
            name: routeNames.WEB_STORE_PAGE_EDIT,
            component: () => import('@/pages/Applications/Web/WebStores/Edit/Tabs/EditWebStorePage.vue'),
            meta: { subsection: 'web-store-pages' },
            props: true,
          },
          {
            path: 'web-store-account-groups',
            name: routeNames.WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_GROUPS_LIST,
            component: () => import('@/pages/Applications/Web/WebStores/Edit/Tabs/WebStoreAccountGroupList.vue'),
            meta: { subsection: 'web-store-account-groups' },
          },
          {
            path: 'account-fields',
            name: routeNames.WEB_CONFIGURATIONS_WEB_STORE_ACCOUNT_FIELDS_LIST,
            component: () => import('@/pages/Applications/Web/WebStores/Edit/Tabs/AccountFieldList.vue'),
            meta: { subsection: 'account-fields' },
          },
        ],
      },
    ],
  },
]

export default routes
