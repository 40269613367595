<template>
  <component
    :is="to ? AppLink : 'button'"
    class="tw-flex tw-rounded tw-text-md tw-no-underline tw-cursor-pointer tw-text-ellipsis tw-h-12 tw-w-full tw-items-center tw-px-4 tw-py-0 hover:tw-bg-gray-7 disabled:tw-bg-transparent disabled:tw-cursor-auto"
    :class="[
      {
        'tw-justify-center': !navigationExpanded,
      },
      caretDirection ? `menu-button--caret-${caretDirection}` : '',
    ]"
    :to="to"
    :title="text"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <span
      v-if="icon || alignMissingIcon"
      :class="[navigationExpanded ? (icon === 'avatar' ? 'tw-mr-2' : 'tw-mr-4') : '']"
    >
      <LuiAvatar
        v-if="icon === 'avatar'"
        size="small"
        :name="text"
      />
      <LuiIcon
        v-else-if="icon"
        :name="icon"
      />
      <span
        v-else
        class="tw-block tw-w-7"
      />
    </span>
    <span
      class="tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis"
      :class="{ 'tw-hidden': !navigationExpanded }"
    >
      {{ text }}
      <LuiIcon
        v-if="to && typeof to === 'string' && to.startsWith('http')"
        name="external-link-small"
        :height="16"
        :width="16"
        class="tw-text-muted tw-ml-0.5 tw-inline-block tw-relative tw-bottom-1"
      />
    </span>
    <LuiIcon
      v-if="hasChildren && navigationExpanded && !disabled"
      :height="24"
      :width="24"
      name="direction-right-small"
      class="tw-shrink-0 tw-transition-transform tw-ease-in-out tw-animate-150 tw-ml-auto"
      :class="[caretDirection === 'up' ? '-tw-rotate-90' : '', caretDirection === 'down' ? 'tw-rotate-90' : '']"
    />
  </component>
</template>

<script setup lang="ts">
import { LuiAvatar, type LuiIconName } from '@loomispay/loomis-ui'
import { storeToRefs } from 'pinia'
import type { RouteLocationRaw } from 'vue-router'

import AppLink from '@/components/Utilities/AppLink.vue'
import { useNavigationStore } from '@/stores/navigation'

withDefaults(
  defineProps<{
    active?: boolean
    text: string
    caretDirection?: 'up' | 'down'
    hasChildren?: boolean
    to?: RouteLocationRaw
    alignMissingIcon?: boolean
    icon?: 'avatar' | LuiIconName
    disabled?: boolean
  }>(),
  {
    active: false,
    caretDirection: undefined,
    hasChildren: false,
    to: undefined,
    alignMissingIcon: true,
    icon: undefined,
  },
)

defineEmits<{
  (event: 'click'): void
}>()

const { expanded: navigationExpanded } = storeToRefs(useNavigationStore())
</script>
