<template>
  <div class="tw-flex tw-mb-4">
    <LuiInput
      v-if="fields?.length >= 1 && fields[0].length >= 1 && fields[0][0].widget === 'text'"
      class="tw-flex-1"
      :model-value="value[fields[0][0].name]"
      type="text"
      :placeholder="fields[0][0].placeholder || $t('global.search')"
      prefix-icon="search"
      @update:model-value="
        $emit('update:value', {
          ...value,
          [fields[0][0].name]: $event,
        })
      "
      @keydown.enter="$emit('submit')"
    />
    <LuiButton
      v-if="fields?.length >= 1 && fields[0].length >= 1"
      class="ml-2 tw-text-md"
      icon="filter"
      size="large"
      emphasis="low_green"
      @click="showFilterSheet"
    >
      {{ $t('filterSheet.title') }}
      <span
        v-if="activeFilterCount"
        class="ml-1 tw-text-xs tw-font-semibold tw-py-1 tw-px-2 tw-bg-green-14 tw-rounded"
      >
        {{ activeFilterCount }}
      </span>
    </LuiButton>
    <div
      ref="el"
      class="tw-fixed tw-inset-0 tw-bg-white tw-z-50 tw-overflow-scroll tw-pb-16"
      :class="{
        'tw-hidden': !filterSheetVisible,
      }"
    >
      <div class="tw-p-4 tw-relative">
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-items-center">
            <LuiButton
              emphasis="low"
              icon="close"
              @click="hideFilterSheet"
            />
            <h3 class="tw-text-lg tw-font-semibold tw-tracking-wide tw-flex-1">
              {{ $t('filterSheet.title') }}
            </h3>
          </div>
          <LuiButton
            v-if="activeFilterCount"
            emphasis="low_green"
            @click="reset"
          >
            {{ $t('filterSheet.clearFilters.buttonLabel') }}
          </LuiButton>
        </div>
        <div
          v-for="field in filteredFields"
          :key="field.name"
        >
          <!-- CHECKBOX -->
          <div
            v-if="field.widget === 'checkbox'"
            class="tw-py-3 tw-px-0.5 tw-border-b tw-border-opacity-black-2 tw-flex tw-items-center tw-justify-between"
          >
            <div>{{ field.label }}</div>
            <LuiToggle
              :model-value="value[field.name] === 'false' ? false : !!value[field.name]"
              :disabled="disabled || field.disabled"
              @update:model-value="
                $emit('update:value', {
                  ...value,
                  [field.name]: $event,
                })
              "
            />
          </div>
          <!-- DATE -->
          <BottomSheetFilterField
            v-if="field.widget === 'date'"
            :label="field.label || field.placeholder"
          >
            <LuiDatepicker
              :model-value="value[field.name]"
              :name="field.name"
              :placeholder="field.placeholder || field.label"
              :disabled="disabled || field.disabled"
              :mode="field.mode"
              :parameters="field.parameters"
              :hide-clear-button="
                // The 'hide-clear-button' prop controls the visibility of the clear button, based on the 'nullable' status of a filter.
                // By default, filters are considered nullable (allow null values). Therefore, if 'nullable' is explicitly set to a boolean value,
                // 'hide-clear-button' is set to the inverse of 'nullable' (i.e., true if nullable is false, and vice versa).
                // If 'nullable' is not explicitly set (i.e., not a boolean), we default to showing the clear button (hide-clear-button = false).
                typeof field.nullable === 'boolean' ? !field.nullable : false
              "
              @update:model-value="$emit('update:value', { ...value, [field.name]: $event })"
            />
          </BottomSheetFilterField>
          <!-- SELECT -->
          <BottomSheetFilterField
            v-if="field.widget === 'select'"
            :label="field.label || field.placeholder"
          >
            <SelectWidget
              :value="value[field.name]"
              :name="field.name"
              :label="field.label"
              :disabled="disabled || field.disabled"
              :options="field.options"
              :nullable="field.nullable"
              :placeholder="field.placeholder"
              @update:value="$emit('update:value', { ...value, [field.name]: $event })"
            />
          </BottomSheetFilterField>
          <!-- MULTISELECT -->
          <BottomSheetFilterField
            v-if="field.widget === 'multiselect'"
            :label="field.label || field.placeholder"
          >
            <MultiSelectWidget
              :model-value="
                field.options.filter((option: any) =>
                  value[field.name]?.includes(',')
                    ? value[field.name]?.split(',').includes(option.value)
                    : value[field.name] === option.value,
                )
              "
              :name="field.name"
              :disabled="disabled || field.disabled"
              :options="field.options"
              :label="field.label"
              track-by="value"
              :placeholder="field.placeholder"
              v-bind="field.props"
              @update:model-value="
                $emit('update:value', {
                  ...value,
                  [field.name]: Array.isArray($event) ? $event.map((item: any) => item.value).join(',') : $event.value,
                })
              "
            />
          </BottomSheetFilterField>
          <!-- ADAPTIVE -->
          <BottomSheetFilterField
            v-if="field.widget === 'adaptive'"
            :label="field.label || field.placeholder"
          >
            <AdaptiveWidget
              :value="value[field.name]"
              :name="field.name"
              :label="field.label"
              :disabled="disabled || field.disabled"
              :endpoint="field.endpoint"
              :params="field.params"
              :nullable="field.nullable"
              :placeholder="field.placeholder"
              :result-label-property="(row: any) => row.attributes.name"
              @update:value="$emit('update:value', { ...value, [field.name]: $event })"
            />
          </BottomSheetFilterField>
          <!-- TEXT -->
          <BottomSheetFilterField
            v-if="field.widget === 'text' && field.name !== 'search'"
            :label="field.label || field.placeholder"
          >
            <LuiInput
              :model-value="value[field.name]"
              type="text"
              :placeholder="field.placeholder || $t('global.search')"
              :prefix="field.prefix"
              :prefix-icon="field.prefixIcon"
              :suffix="field.suffix"
              :suffix-icon="field.suffixIcon"
              :disabled="disabled || field.disabled"
              :class="field.class"
              @update:model-value="$emit('update:value', { ...value, [field.name]: $event })"
            />
          </BottomSheetFilterField>
        </div>
        <div class="tw-py-3">
          <LuiButton
            type="submit"
            full-width
            @click="submit"
          >
            {{ $t('global.filter') }}
          </LuiButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { LuiDatepicker, LuiInput, LuiToggle } from '@loomispay/loomis-ui'
import { type ComputedRef, computed, ref } from 'vue'

import type {
  PortalFilterAdaptiveField,
  PortalFilterCheckboxField,
  PortalFilterDatepickerField,
  PortalFilterInputField,
  PortalFilterMultiselectField,
  PortalFilterSelectField,
  PortalFilters,
} from '@/interfaces/PortalFilters'

import AdaptiveWidget from '@/components/FormGroups/Widgets/AdaptiveWidget.vue'
import MultiSelectWidget from '@/components/FormGroups/Widgets/MultiSelectWidget.vue'
import SelectWidget from '@/components/FormGroups/Widgets/SelectWidget.vue'
import BottomSheetFilterField from '@/components/Forms/BottomSheetFilterField.vue'
import { clearAllBodyScrollLocks, disableBodyScroll } from '@/utils/bodyScrollLock'

const props = withDefaults(
  defineProps<{
    fields: PortalFilters
    filterKeysExcludedFromActiveBadge?: string[]
    value: Record<string, any>
    disabled?: boolean
  }>(),
  {
    filterKeysExcludedFromActiveBadge: () => ['search'],
    disabled: false,
  },
)

const emit = defineEmits<{
  'update:value': [any]
  'submit': [void]
  'clear': [void]
}>()

const filterSheetVisible = ref(false)
const el = ref<HTMLElement | null>(null)

const filteredFields = computed(() =>
  props.fields.flatMap((field) =>
    field.filter((field) => field.widget !== 'button' && field.widget !== 'submit' && field.widget !== 'blank'),
  ),
) as ComputedRef<
  (
    | PortalFilterMultiselectField
    | PortalFilterSelectField
    | PortalFilterCheckboxField
    | PortalFilterInputField
    | PortalFilterDatepickerField
    | PortalFilterAdaptiveField
  )[]
>

// Active filter count excluding search and empty values
const activeFilterCount = computed(
  () =>
    Object.keys(props.value).filter(
      (key) => !props.filterKeysExcludedFromActiveBadge.includes(key) && !!props.value[key],
    ).length,
)

const showFilterSheet = () => {
  filterSheetVisible.value = true
  disableBodyScroll(el, { allowTouchMove: () => true })
}

const hideFilterSheet = () => {
  filterSheetVisible.value = false
  clearAllBodyScrollLocks()
}

const submit = () => {
  emit('submit')
  hideFilterSheet()
}

const reset = () => {
  emit('clear')
  hideFilterSheet()
}
</script>

<style lang="scss">
.button-container-fixed-bottom {
  @apply tw-fixed tw-left-0 tw-right-0 tw-bottom-0 tw-px-4 tw-pb-4 tw-w-full tw-bg-white tw-z-50;

  &:before {
    @apply tw-absolute -tw-top-4 tw-h-4 tw-right-0 tw-left-0 tw-bg-gradient-to-t tw-from-white tw-to-opacity-white-2 tw-opacity-80;
    content: '';
  }
}
</style>
