import type { RouteRecordRaw } from 'vue-router'

export const routeNames = {
  RETAIL_CONFIGURATIONS_LIST: 'RETAIL_CONFIGURATIONS_LIST',
  RETAIL_CONFIGURATION_CREATE: 'RETAIL_CONFIGURATION_CREATE',
  RETAIL_CONFIGURATION_SHOW: 'RETAIL_CONFIGURATION_SHOW',
  RETAIL_CONFIGURATIONS_RETAIL_STORES: 'RETAIL_CONFIGURATIONS_RETAIL_STORES',
  RETAIL_CONFIGURATIONS_RETAIL_STORES_MENU: 'RETAIL_CONFIGURATIONS_RETAIL_STORES_MENU',
  RETAIL_CONFIGURATIONS_RETAIL_STORE_SHOW: 'RETAIL_CONFIGURATIONS_RETAIL_STORE_SHOW',
  RETAIL_FOLDER_EDIT: 'RETAIL_FOLDER_EDIT',
  RETAIL_PRODUCT_EDIT: 'RETAIL_PRODUCT_EDIT',
  RETAIL_CONFIGURATIONS_RETAIL_STORES_MAPS_CREATE: 'RETAIL_CONFIGURATIONS_RETAIL_STORES_MAPS_CREATE',
  RETAILS_CONFIGURATIONS_RETAIL_STORES_FOLDERS: 'RETAILS_CONFIGURATIONS_RETAIL_STORES_FOLDERS',
  RETAIL_CONFIGURATIONS_RETAIL_STORES_PRODUCTS: 'RETAIL_CONFIGURATIONS_RETAIL_STORES_PRODUCTS',
  RETAIL_CONFIGURATION_RETAILS_STORES_EDIT: 'RETAIL_CONFIGURATION_RETAILS_STORES_EDIT',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'retail/configurations',
    component: () => import('@/pages/Blank.vue'),
    children: [
      {
        path: '',
        name: routeNames.RETAIL_CONFIGURATIONS_LIST,
        component: () => import('@/pages/Applications/Retail/Configurations/RetailConfigurationList.vue'),
      },
      {
        path: 'create',
        name: routeNames.RETAIL_CONFIGURATION_CREATE,
        component: () => import('@/pages/Applications/Retail/Configurations/RetailConfigurationCreate.vue'),
      },
      {
        path: ':configurationId',
        component: () => import('@/pages/Applications/Retail/Configurations/RetailConfigurationContainer.vue'),
        props: true,
        children: [
          {
            path: '',
            name: routeNames.RETAIL_CONFIGURATION_SHOW,
            redirect: () => ({ name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES }),
          },
          {
            path: 'retail-stores',
            name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES,
            component: () => import('@/pages/Applications/Retail/RetailStores/RetailStoresList.vue'),
          },
          {
            path: 'retail-stores/:retailStoreId',
            component: () => import('@/pages/Applications/Retail/RetailStores/RetailStoresContainer.vue'),
            props: true,
            children: [
              {
                path: '',
                name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORE_SHOW,
                redirect: () => ({ name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES_MENU }),
              },
              {
                path: 'maps/create',
                name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES_MAPS_CREATE,
                component: () => import('@/pages/Applications/Retail/RetailMaps/RetailMapsCreate.vue'),
                meta: { subsection: 'menu' },
              },
              {
                path: 'menu',
                name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES_MENU,
                component: () => import('@/pages/Applications/Retail/RetailStores/RetailStoreMenu.vue'),
                meta: { subsection: 'menu' },
              },
              {
                path: 'folders',
                name: routeNames.RETAILS_CONFIGURATIONS_RETAIL_STORES_FOLDERS,
                component: () => import('@/pages/Applications/Retail/RetailFolders/RetailFoldersList.vue'),
                meta: { subsection: 'folders' },
              },
              {
                path: 'folders/:retailFolderId',
                name: routeNames.RETAIL_FOLDER_EDIT,
                component: () => import('@/pages/Applications/Retail/RetailFolders/RetailFoldersEdit.vue'),
                meta: { subsection: 'folders' },
                props: true,
              },
              {
                path: 'products',
                name: routeNames.RETAIL_CONFIGURATIONS_RETAIL_STORES_PRODUCTS,
                component: () => import('@/pages/Applications/Retail/RetailProducts/RetailProductsList.vue'),
                meta: { subsection: 'products' },
              },
              {
                path: 'products/:retailProductId',
                name: routeNames.RETAIL_PRODUCT_EDIT,
                component: () => import('@/pages/Applications/Retail/RetailProducts/RetailProductsEdit.vue'),
                meta: { subsection: 'products' },
                props: true,
              },
              {
                path: 'edit',
                name: routeNames.RETAIL_CONFIGURATION_RETAILS_STORES_EDIT,
                component: () => import('@/pages/Applications/Retail/RetailStores/RetailStoresEdit.vue'),
                meta: { subsection: 'edit' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
