import type { RouteRecordRaw } from 'vue-router'

const AccountExportList = () =>
  import('@/pages/Applications/AccountCards/AccountExports/AccountCardsAccountExportsList.vue')
const AccountGroupContainer = () =>
  import('@/pages/Applications/AccountCards/AccountGroups/AccountCardsAccountGroupsContainer.vue')
const EditAccountGroup = () =>
  import('@/pages/Applications/AccountCards/AccountGroups/AccountCardsAccountGroupsEdit.vue')
const AccountGroupList = () =>
  import('@/pages/Applications/AccountCards/AccountGroups/AccountCardsAccountGroupsList.vue')
const CreateAccountImport = () =>
  import('@/pages/Applications/AccountCards/AccountImports/AccountCardsImportAccounts.vue')
const AccountLocationContainer = () =>
  import('@/pages/Applications/AccountCards/AccountLocations/AccountCardsLocationsContainer.vue')
const EditAccountLocation = () =>
  import('@/pages/Applications/AccountCards/AccountLocations/AccountCardsLocationsEdit.vue')
const AccountLocationList = () =>
  import('@/pages/Applications/AccountCards/AccountLocations/AccountCardsLocationsList.vue')
const AccountManagerContainer = () =>
  import('@/pages/Applications/AccountCards/AccountManagers/AccountCardsManagersContainer.vue')
const CreateAccountManager = () =>
  import('@/pages/Applications/AccountCards/AccountManagers/AccountCardsManagersCreate.vue')
const EditAccountManagerDriver = () =>
  import('@/pages/Applications/AccountCards/AccountManagers/AccountCardsManagersSelectDriver.vue')
const EditAccountManager = () =>
  import('@/pages/Applications/AccountCards/AccountManagers/AccountCardsManagersEdit.vue')
const AccountManagerList = () =>
  import('@/pages/Applications/AccountCards/AccountManagers/AccountCardsManagersList.vue')
const EditAccount = () => import('@/pages/Applications/AccountCards/Accounts/AccountCardsEditAccount.vue')
const AccountList = () => import('@/pages/Applications/AccountCards/Accounts/AccountCardsAccountsList.vue')
const ConfigurationContainer = () =>
  import('@/pages/Applications/AccountCards/Configurations/AccountCardsConfigurationsContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/AccountCards/Configurations/AccountCardsConfigurationCreate.vue')
const ConfigurationList = () =>
  import('@/pages/Applications/AccountCards/Configurations/AccountCardsConfigurationList.vue')
const DiscountGroupContainer = () =>
  import('@/pages/Applications/AccountCards/DiscountGroups/AccountCardsDiscountGroupsContainer.vue')
const EditDiscountGroup = () =>
  import('@/pages/Applications/AccountCards/DiscountGroups/AccountCardsDiscountGroupsEdit.vue')
const DiscountGroupList = () =>
  import('@/pages/Applications/AccountCards/DiscountGroups/AccountCardsDiscountGroupsList.vue')
const OrderLineList = () => import('@/pages/Applications/AccountCards/OrderLines/AccountCardsOrderLinesList.vue')
const OrderList = () => import('@/pages/Applications/AccountCards/Orders/AccountCardsOrdersList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  ACCOUNT_CARDS_CONFIGURATIONS_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_LIST',
  ACCOUNT_CARDS_CONFIGURATION_CREATE: 'ACCOUNT_CARDS_CONFIGURATION_CREATE',
  ACCOUNT_CARDS_CONFIGURATION_SHOW: 'ACCOUNT_CARDS_CONFIGURATION_SHOW',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_CREATE: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_CREATE',
  ACCOUNT_CARDS_ACCOUNT_MANAGER_SHOW: 'ACCOUNT_CARDS_ACCOUNT_MANAGER_SHOW',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_EDIT: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_EDIT',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_SHOW: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_SHOW',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_EDIT: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_EDIT',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATIONS_EDIT: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATIONS_EDIT',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATION_SHOW: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATION_SHOW',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_ACCOUNT_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ORDER_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_ORDER_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ORDER_LINE_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_ORDER_LINE_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATION_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_ACCOUNT_LOCATION_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_EXPORT_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_ACCOUNT_EXPORT_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUP_LIST: 'ACCOUNT_CARDS_CONFIGURATIONS_DISCOUNT_GROUP_LIST',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_SHOW: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_SHOW',
  ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT: 'ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_EDIT: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_EDIT',
  ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_IMPORT_CREATE: 'ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_IMPORT_CREATE',
  ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT_DRIVER: 'ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT_DRIVER',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'account-cards/configurations',
    meta: { localeFilename: 'account-cards' },
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.ACCOUNT_CARDS_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.ACCOUNT_CARDS_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.ACCOUNT_CARDS_CONFIGURATION_SHOW,
            redirect: () => ({ name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS }),
          },
          {
            path: 'account-managers',
            name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS,
            component: AccountManagerList,
          },
          {
            path: 'account-managers/create',
            name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_CREATE,
            component: CreateAccountManager,
          },
          {
            path: 'account-managers/:accountManagerId',
            component: AccountManagerContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGER_SHOW,
                redirect: () => ({
                  name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS,
                }),
              },
              {
                path: 'account-groups',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS,
                component: AccountGroupList,
                meta: { subsection: 'account-groups' },
              },
              {
                path: 'account-groups/:accountGroupId',
                component: AccountGroupContainer,
                props: true,
                meta: { subsection: 'account-groups' },
                children: [
                  {
                    path: '',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_SHOW,
                    redirect: () => ({
                      name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_EDIT,
                    }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_GROUPS_EDIT,
                    component: EditAccountGroup,
                  },
                ],
              },
              {
                path: 'discount-groups',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUP_LIST,
                component: DiscountGroupList,
                meta: { subsection: 'discount-groups' },
              },
              {
                path: 'discount-groups/:discountGroupId',
                component: DiscountGroupContainer,
                props: true,
                meta: { subsection: 'discount-groups' },
                children: [
                  {
                    path: '',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_SHOW,
                    redirect: () => ({
                      name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_EDIT,
                    }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_DISCOUNT_GROUPS_EDIT,
                    component: EditDiscountGroup,
                  },
                ],
              },
              {
                path: 'accounts',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LIST,
                component: AccountList,
                props: true,
                meta: { subsection: 'accounts' },
              },
              {
                path: 'accounts/:accountId',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_EDIT,
                component: EditAccount,
                props: true,
                meta: { subsection: 'accounts' },
              },
              {
                path: 'orders',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ORDER_LIST,
                component: OrderList,
                meta: { subsection: 'orders' },
              },
              {
                path: 'order-lines',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ORDER_LINE_LIST,
                component: OrderLineList,
                meta: { subsection: 'order-lines' },
              },
              {
                path: 'account-locations',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATION_LIST,
                component: AccountLocationList,
                meta: { subsection: 'account-locations' },
              },
              {
                path: 'account-locations/:accountLocationId',
                component: AccountLocationContainer,
                props: true,
                meta: { subsection: 'account-locations' },
                children: [
                  {
                    path: '',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATION_SHOW,
                    redirect: () => ({
                      name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATIONS_EDIT,
                    }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_LOCATIONS_EDIT,
                    component: EditAccountLocation,
                  },
                ],
              },
              {
                path: 'account-exports',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_EXPORT_LIST,
                component: AccountExportList,
                props: true,
                meta: { subsection: 'account-exports' },
              },
              {
                path: 'account-imports/create',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGERS_ACCOUNT_IMPORT_CREATE,
                component: CreateAccountImport,
                props: true,
                meta: { subsection: 'accounts' },
              },
              {
                path: 'edit',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT,
                component: EditAccountManager,
                meta: { subsection: 'edit' },
              },
              {
                path: 'edit-driver',
                name: routeNames.ACCOUNT_CARDS_ACCOUNT_MANAGER_EDIT_DRIVER,
                component: EditAccountManagerDriver,
                meta: { subsection: 'edit' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
