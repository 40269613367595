import type { RouteRecordRaw } from 'vue-router'

import { getCurrentDate } from '@/shared/datetimeService'

const ConfigurationContainer = () =>
  import('@/pages/Applications/Takeaway/Configurations/TakeawayConfigurationsContainer.vue')
const CreateConfiguration = () =>
  import('@/pages/Applications/Takeaway/Configurations/TakeawayConfigurationsCreate.vue')
const ConfigurationList = () => import('@/pages/Applications/Takeaway/Configurations/TakeawayConfigurationsList.vue')
const CreateDuplicatedWeekMenu = () =>
  import('@/pages/Applications/Takeaway/DuplicatedWeekMenus/TakeawayDuplicatedWeekMenusCreate.vue')
const OrderFieldGroupContainer = () =>
  import('@/pages/Applications/Takeaway/OrderFieldGroups/TakeawayOrderFieldGroupsContainer.vue')
const EditOrderFieldGroup = () =>
  import('@/pages/Applications/Takeaway/OrderFieldGroups/TakeawayOrderFieldGroupsEdit.vue')
const OrderFieldGroupList = () =>
  import('@/pages/Applications/Takeaway/OrderFieldGroups/TakeawayOrderFieldGroupsList.vue')
const OrderList = () => import('@/pages/Applications/Takeaway/Orders/TakeawayOrdersList.vue')
const TakeawayMenuItemList = () => import('@/pages/Applications/Takeaway/TakeawayMenuItems/TakeawayMenuItemsList.vue')
const TakeawayStoreContainer = () => import('@/pages/Applications/Takeaway/TakeawayStores/TakeawayStoresContainer.vue')
const EditTakeawayStore = () => import('@/pages/Applications/Takeaway/TakeawayStores/TakeawayStoresEdit.vue')
const TakeawayStoreList = () => import('@/pages/Applications/Takeaway/TakeawayStores/TakeawayStoresList.vue')
const Blank = () => import('@/pages/Blank.vue')

export const routeNames = {
  TAKEAWAY_CONFIGURATIONS_LIST: 'TAKEAWAY_CONFIGURATIONS_LIST',
  TAKEAWAY_CONFIGURATION_CREATE: 'TAKEAWAY_CONFIGURATION_CREATE',
  TAKEAWAY_CONFIGURATIONS_SHOW: 'TAKEAWAY_CONFIGURATIONS_SHOW',
  TAKEAWAY_CONFIGURATIONS_STORES: 'TAKEAWAY_CONFIGURATIONS_STORES',
  TAKEAWAY_CONFIGURATIONS_STORES_SHOW: 'TAKEAWAY_CONFIGURATIONS_STORES_SHOW',
  TAKEAWAY_CONFIGURATIONS_STORES_EDIT: 'TAKEAWAY_CONFIGURATIONS_STORES_EDIT',
  TAKEAWAY_CONFIGURATIONS_STORES_ORDERS: 'TAKEAWAY_CONFIGURATIONS_STORES_ORDERS',
  TAKEAWAY_CONFIGURATIONS_STORES_MENU_DATE: 'TAKEAWAY_CONFIGURATIONS_STORES_MENU_DATE',
  TAKEAWAY_CONFIGURATIONS_STORES_MENU_DUPLICATE: 'TAKEAWAY_CONFIGURATIONS_STORES_MENU_DUPLICATE',
  TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS: 'TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS',
  TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_EDIT: 'TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_EDIT',
  TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_SHOW: 'TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_SHOW',
}

const routes: RouteRecordRaw[] = [
  {
    path: 'takeaway/configurations',
    component: Blank,
    children: [
      {
        path: '',
        name: routeNames.TAKEAWAY_CONFIGURATIONS_LIST,
        component: ConfigurationList,
      },
      {
        path: 'create',
        name: routeNames.TAKEAWAY_CONFIGURATION_CREATE,
        component: CreateConfiguration,
      },
      {
        path: ':configurationId',
        component: ConfigurationContainer,
        props: true,
        children: [
          {
            path: '',
            name: routeNames.TAKEAWAY_CONFIGURATIONS_SHOW,
            redirect: () => ({ name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES }),
          },
          {
            path: 'stores',
            name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES,
            component: TakeawayStoreList,
          },
          {
            path: 'stores/:takeawayStoreId',
            component: TakeawayStoreContainer,
            props: true,
            children: [
              {
                path: '',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_SHOW,
                redirect: () => ({ name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDERS }),
              },
              {
                path: 'menu',
                redirect: (to) => ({
                  name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_MENU_DATE,
                  params: { ...to.params, date: getCurrentDate() },
                }),
              },
              {
                path: 'menu/:date/duplicate',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_MENU_DUPLICATE,
                component: CreateDuplicatedWeekMenu,
                props: true,
                meta: { subsection: 'menu' },
              },
              {
                path: 'menu/:date',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_MENU_DATE,
                component: TakeawayMenuItemList,
                props: true,
                meta: { subsection: 'menu' },
              },
              {
                path: 'orders',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDERS,
                component: OrderList,
                meta: { subsection: 'orders' },
              },
              {
                path: 'order-field-groups',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS,
                component: OrderFieldGroupList,
                meta: { subsection: 'order-fields' },
              },
              {
                path: 'order-field-groups/:orderFieldGroupId',
                component: OrderFieldGroupContainer,
                props: true,
                meta: { subsection: 'order-fields' },
                children: [
                  {
                    path: '',
                    name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_SHOW,
                    redirect: () => ({
                      name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_EDIT,
                    }),
                  },
                  {
                    path: 'edit',
                    name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_ORDER_FIELD_GROUPS_EDIT,
                    component: EditOrderFieldGroup,
                  },
                ],
              },
              {
                path: 'edit',
                name: routeNames.TAKEAWAY_CONFIGURATIONS_STORES_EDIT,
                component: EditTakeawayStore,
                meta: { subsection: 'edit' },
              },
            ],
          },
        ],
      },
    ],
  },
]

export default routes
