import axios, { type AxiosPromise, type Method, isAxiosError } from 'axios'
import download from 'downloadjs'

import type { BaseApiResponse } from '@/interfaces/BaseApi'
import type { ListSorting } from '@/interfaces/Frontend/BasicTable'

import { i18n } from '@/i18n'
import notify from '@/notify'
import { useAuthStore } from '@/stores/auth'
import { useEnterEmailToDownloadModalStore } from '@/stores/enter-email-to-download-modal-store'

export default {
  get<T = any>(url: string, params = {}) {
    const authStore = useAuthStore()
    return axios
      .get<T>(url, {
        params,
        headers: { Authorization: `Bearer ${authStore.token}` },
      })
      .catch(this.handleUnauthorized)
  },

  getBuffer(url: string, params = {}) {
    const authStore = useAuthStore()
    return axios
      .get<ArrayBuffer>(url, {
        params,
        responseType: 'arraybuffer',
        headers: { Authorization: `Bearer ${authStore.token}` },
      })
      .catch(this.handleUnauthorized)
  },

  post<T = any, R = BaseApiResponse<T>>(url: string, data?: any, options = {}): AxiosPromise<R> {
    const authStore = useAuthStore()
    return axios
      .post<R>(url, data, {
        headers: { Authorization: `Bearer ${authStore.token}` },
        ...options,
      })
      .catch(this.handleUnauthorized)
  },

  delete<T = any>(url: string, data?: any) {
    const authStore = useAuthStore()
    return axios
      .delete<T>(url, {
        data: data,
        headers: { Authorization: `Bearer ${authStore.token}` },
      })
      .catch(this.handleUnauthorized)
  },

  patch(url: string, data?: any) {
    const authStore = useAuthStore()
    return axios({
      method: 'patch',
      url,
      data,
      headers: { Authorization: `Bearer ${authStore.token}` },
    }).catch(this.handleUnauthorized)
  },

  put<T = any>(url: string, data?: any): AxiosPromise<T> {
    const authStore = useAuthStore()
    return axios({
      method: 'put',
      url,
      data,
      headers: { Authorization: `Bearer ${authStore.token}` },
    }).catch(this.handleUnauthorized)
  },

  options<T = any>(url: string): AxiosPromise<T> {
    const authStore = useAuthStore()
    return axios({
      method: 'options',
      url,
      headers: { Authorization: `Bearer ${authStore.token}` },
    }).catch(this.handleUnauthorized)
  },

  request(method: Method, url: string, data?: any) {
    const authStore = useAuthStore()
    return axios({
      method,
      url,
      data,
      headers: { Authorization: `Bearer ${authStore.token}` },
    }).catch(this.handleUnauthorized)
  },

  async download(
    url: string,
    params = {},
    data = {},
    headers = {},
    method: Method = 'post',
    fileName: string | undefined = undefined,
  ) {
    const authStore = useAuthStore()
    try {
      const response = await axios({
        method,
        url,
        params,
        data,
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authStore.token}`,
          ...headers,
        },
      })
      if (!fileName) {
        const match = response.headers['content-disposition'].match('filename="([^"]+)"')
        fileName = match[1] || 'Export'
      }
      download(response.data, fileName)
    } catch (error) {
      this.handleUnauthorized(error)
    }
  },

  async downloadWithExcelAcceptHeader(url: string, params = {}) {
    try {
      const authStore = useAuthStore()
      await this.download(
        url,
        params,
        {},
        {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${authStore.token}`,
        },
        'get',
      )
    } catch (error) {
      if (isAxiosError(error) && error?.response?.status === 400) {
        useEnterEmailToDownloadModalStore().showEnterEmailToDownloadModal(async (email: string) => {
          await this.requestExcelEmailExport(url, email, params)
        })
        return
      }
      throw error
    }
  },

  downloadWithZipAcceptHeader(url: string, fileName: string, params = {}) {
    const authStore = useAuthStore()
    return this.download(
      url,
      params,
      {},
      {
        accept: 'application/x-zip',
        Authorization: `Bearer ${authStore.token}`,
      },
      'get',
      fileName,
    )
  },

  requestExcelEmailExport(url: string, email: string, params = {}) {
    const authStore = useAuthStore()
    return axios
      .get(url, {
        params: {
          email,
          ...params,
        },
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          Authorization: `Bearer ${authStore.token}`,
        },
      })
      .catch(this.handleUnauthorized)
  },

  async getList<T = any>(url: string, params: any = {}, page: number = 1, items: T[] = []): Promise<T[]> {
    const response: any = await this.get<T>(url, {
      ...params,
      page,
    })
    items = items.concat(response.data.data)

    if (response.data.meta.current_page === response.data.meta.last_page) {
      return items
    }

    return this.getList(url, params, page + 1, items)
  },

  /**
   * To handle expired sessions globally, we catch 401 errors here and redirect people to the login screen.
   * We only show the "your session has expired" notification on routes other than "root", to not show it when people
   * are just browsing to the portal for the first time - or just accessing the root page already knowing they're not logged in.
   * @param error
   */
  handleUnauthorized(error: any) {
    if (error.response?.status === 401) {
      if (window.location.pathname !== '/') {
        notify.error(i18n.global.t('notification.yourSessionHasExpired'))
      }
      useAuthStore().logOut(true)
    }

    throw error
  },

  /**
   * Takes an object and wraps all of its keys in the filter[key]=value format that the api expects.
   * @param query
   * @returns {undefined}
   */
  wrapKeysInApiFilterSyntax(
    query: Record<string, string | number | string[] | number[] | undefined | null | boolean>,
  ): Record<string, string> {
    return Object.fromEntries(
      Object.entries(query)
        .filter(([, value]) => value !== '' && value !== null && value !== undefined)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return [`filter[${key}]`, value.join(',')]
          }
          if (typeof value === 'boolean') {
            return [`filter[${key}]`, value ? '1' : '0']
          }
          return [`filter[${key}]`, String(value)]
        }),
    )
  },

  /**
   * Gets the sort payload formatted for the api.
   * @returns {{sort: string}}
   */
  getSortPayload(sort: ListSorting): {
    sort: string | undefined
  } {
    return {
      sort: sort.column ? `${sort.direction === 'desc' ? '-' : ''}${sort.column}` : undefined,
    }
  },

  /**
   * Takes an object and unwraps all of its keys from the filter[key]=value format that the api works with.
   * @param query
   * @returns {{}}
   */
  unwrapApiFilterSyntax(query: Record<string, string>) {
    return Object.entries(query)
      .filter((entry) => entry[0].startsWith('filter['))
      .reduce(
        (result, entry) => {
          result[entry[0].substring(7, entry[0].length - 1)] = entry[1]

          return result
        },
        {} as Record<string, string>,
      )
  },
}
