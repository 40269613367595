<template>
  <div
    ref="button"
    class="menu-section"
    :class="{
      'menu-section--dropdown-open': isInflated && !navigationExpanded,
      'menu-section--section-active': active && !navigationExpanded,
    }"
  >
    <div ref="merchantSectionButton">
      <MenuButton
        class="menu-section__button"
        :caret-direction="isInflated ? 'down' : undefined"
        has-children
        active-class="tw-bg-gray-white tw-text-gray-black hover:tw-text-white hover:tw-bg-gray-7 focus:tw-text-gray-black focus:tw-outline-gray-8"
        icon="avatar"
        :text="text"
        :disabled="merchantCount === 1"
        @click="$emit('click')"
      />
    </div>
    <component :is="navigationExpanded ? CollapseHeightTransition : FadeTransition">
      <!-- Menu on right side of nav -->
      <template v-if="navigationExpanded">
        <div v-if="isInflated">
          <MenuMerchantSelector />
        </div>
      </template>
      <template v-else>
        <menu
          v-if="isInflated"
          ref="merchantSectionDropdown"
          class="menu-dropdown"
        >
          <MenuMerchantSelector />
        </menu>
      </template>
    </component>
  </div>
</template>

<script setup lang="ts">
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { nextTick, ref, watch } from 'vue'

import MenuButton from '@/components/Layout/Navigation/MenuButton.vue'
import MenuMerchantSelector from '@/components/Layout/Navigation/MenuMerchantSelector.vue'
import CollapseHeightTransition from '@/components/Transitions/CollapseHeightTransition.vue'
import FadeTransition from '@/components/Transitions/FadeTransition.vue'
import { useMerchantsStore } from '@/stores/merchants'
import { useNavigationStore } from '@/stores/navigation'

const { expanded: navigationExpanded } = storeToRefs(useNavigationStore())
const { merchantCount } = storeToRefs(useMerchantsStore())

defineProps<{
  text: string
  active?: boolean
  isInflated?: boolean
  disabled?: boolean
}>()

const emit = defineEmits<{
  (event: 'click'): void
  (event: 'close'): void
}>()

const merchantSectionButton = ref<HTMLElement | null>(null)
const merchantSectionDropdown = ref<HTMLElement | null>(null)

watch(merchantSectionDropdown, () => {
  nextTick(() => {
    if (merchantSectionDropdown.value) {
      createPopper(merchantSectionButton.value!, merchantSectionDropdown.value, {
        placement: 'right-start',
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 32],
            },
          },
        ],
      })
    }
  })
})

onClickOutside(
  merchantSectionDropdown,
  () => {
    emit('close')
  },
  {
    ignore: [merchantSectionButton.value],
  },
)
</script>

<style lang="scss" scoped>
@use '@/sass/variables' as *;

.menu-section {
  $self: &;
  font-size: 1rem;

  &__button {
    border: 4px solid transparent;
    transition:
      background-color 150ms ease-in-out,
      border-bottom-color 150ms ease-in-out;
  }

  &--dropdown-open {
    #{$self}__button {
      @apply tw-bg-gray-7;
    }
  }

  &--section-active {
    #{$self}__button {
      @apply tw-border-y-4 tw-border-b-gray-white tw-border-t-transparent;
    }
  }
}

.menu-dropdown {
  $self: &;
  display: block;
  position: absolute;
  z-index: 2;
  width: 220px;
  border-radius: 4px;
  left: calc($width-sidebar + 16px);
  @apply tw-shadow-1 tw-bg-gray-6;
}
</style>
